import React, { useRef, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import https from '../../helpers/https';
import { decode } from '../../services/slugs';
import { generateDate, fromatDate } from "../../services/date/date"
import ViewError from '../../services/ViewError';
import { getHeader } from '../../helpers/getHeader';

const Notes = () => {
    let initial = [{
        "note" : 0,
        "type" : "Partique",
        "semestre": "",
        "date_note" : "",
    }]
    const params = useParams<string>();
    const inputDate = useRef<any>();
    const inputNote = useRef<any>();
    const inputType = useRef<any>();
    const inputSemestre = useRef<any>()
    const [notes, setNotes] = useState([] as any);
    const [oneNote, setOneNote] = useState(initial);
    const [error, setError] = useState<String>();
    const [selectedNote, setSelectedNote] = useState(0);
    const [isSend, setIsSend] = useState(true);
    const  date_note = useRef<any>()
    const Snote = useRef<any>()
    const type = useRef<any>()
    const sem = useRef<any>()

    const fetchNote = async ()=>{
        const note = await https.get(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/eleve/${decode(params.id)}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(note){
            setNotes(note.data);
        }
    }

    const fetchOneNote = async (id:number)=>{
        const note = await https.get(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(note){
            setOneNote(note.data);
        }
    }

    const open = (e:any,ID:string,id:number) => {
        e.preventDefault()
        setSelectedNote(id);
        fetchOneNote(id).catch((e) => console.log(e))
        let modal = document.querySelector('#'+ID) as Element;
        modal?.classList.add("open");
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    useEffect(()=>{
        date_note.current.value = fromatDate(oneNote[0].date_note)
        Snote.current.value = oneNote[0].note
        type.current.value = oneNote[0].type
        sem.current.value = oneNote[0].semestre
    },[oneNote])

    useEffect(()=>{
        fetchNote()
            .catch(err => console.log(err))
    },[])

    const register = async () => {

        if((inputNote.current.value == "")||(inputDate.current.value == "")||(parseFloat(inputNote.current.value) < 0)||(parseFloat(inputNote.current.value) > 20)){
            setIsSend(false)
            setError("veuiller remplir les champs suivant: date , note( /20)")
        }else {
            setIsSend(true)
            let id_eleve  = parseInt(decode(params.id)!);
            let note = parseFloat(inputNote.current.value!);
            let data = {
                "note" : note,
                "type" : inputType.current.value,
                "date_note" : inputDate.current.value,
                'semestre': inputSemestre.current.value,
                "id_el_eleves" : id_eleve,
                "id_niv_niveau" : 1
            }
            const response = await https.post(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => {return e})

            if(response.status == 200){
                fetchNote()
                    .catch(err => console.log(err))
                inputType.current.value = "Pratique"
                inputDate.current.value = 'yyyy-mm-dd'
                inputNote.current.value = 0
            }
            else{
                setError(response.response.data)
            }
        }
    }

    const deleteNote = async (e:any,ID : string,id: number) => {
        e.preventDefault()
        if(id){
            let resp = await https.delete(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => {return e});
            if(resp.status == 200){
                fetchNote()
                    .then(() =>{
                        fermer(ID)
                    })
                    .catch((e) => console.log(e))
            }
        }
    }

    const handleNote =(e: any,id:string)=>{
        e.preventDefault()
        if((Snote.current.value != "")&&(date_note.current.value != "")&&(parseFloat(Snote.current.value) >= 0)&&(parseFloat(Snote.current.value) <= 20)){
            try{
                let data = {
                    "id_note": selectedNote,
                    "note" : Snote.current.value,
                    "type" : type.current.value,
                    "date_note" : date_note.current.value,
                    'semestre': sem.current.value,
                    "id_el_eleves" : decode(params.id),
                    "id_niv_niveau" : 1
                }
                https.put(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,data,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then((res)=>{
                    if(res.status === 200){
                        fermer(id)
                        fetchNote()
                        .catch(e => console.log(e))
                    }
                })
                .catch((e)=>{
                    console.log(e)
                })
            }
            catch(e){
                console.log(e)
            }
        }
    }

    return(
        <div  className='bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex">
                            <h5 className="mb-0 text-gray-800">Note de l'élève</h5>
                        </div>
                        <button className="btn btn-sm btn-primary" onClick={register} ><i className="fas fa-fw fa-save"></i> Envoyer</button>
                </div>
                {/* Beginn */}
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                Donn&eacute;es
                            </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                    <table className="table table-bordered table-responsive-xl" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Examen</th>
                                                <th>Note</th>
                                                <th>Trimestre</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { notes.map((el : any) => {
                                                return (
                                                    <tr key={el.id_note}>
                                                        <td>{generateDate(el.date_note)}</td>
                                                        <td>{el.type}</td>
                                                        <td>{el.note}</td>
                                                        <td style={{textTransform:"capitalize"}}>{el.semestre}</td>
                                                        <td>
                                                            <button  onClick={(e) => open(e, 'mymodal', el.id_note)} className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></button>
                                                            {
                                                                localStorage.getItem("EGMUserType") == "super_admin" && <button onClick={e => open(e,"logoutModal",el.id_note)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-trash-alt"></i></button>
                                                            }
                                                    </td>
                                                    </tr>
                                                );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h4 className="m-0 font-weight-bold text-primary">Ajout de note</h4>
                                </div>
                                <div className="card-body">
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Date</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="date" ref={inputDate} className="form-control"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Note</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" ref={inputNote} className="form-control"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Trimestre</h6>
                                <br/>
                                <div className="input-group">
                                    <select ref={inputSemestre} className="form-control">
                                        <option value="trimestre 1">Trimestre 1</option>
                                        <option value="trimestre 2">Trimestre 2</option>
                                        <option value="trimestre 3">Trimestre 3</option>
                                        <option value="trimestre 4">Trimestre 4</option>
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Type</h6>
                                <br/>
                                <div className="input-group">
                                    <select ref={inputType} className="form-control">
                                        <option value="Justesse">Justesse</option>
                                        <option value="Rythme">Rythme</option>
                                        <option value="Feeling">Feeling</option>
                                        <option value="Theorique">Théorique</option>
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br />
                                {isSend ? "" : <ViewError error={error}/>}
                            </div>
                        </div>
                    </div>
                    {/* modals */}
                    <div className="modal" id="logoutModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{
                                width: '100%',
                                marginTop:'150px',
                            }} >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                                    
                                </div>
                                <div className="modal-body" style={{
                                    padding:'20px'
                                }}>Voulez vous vraiment supprimer cette note ?</div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                    <a className="btn btn-danger btn-sm" onClick={e => deleteNote(e,"logoutModal", selectedNote)} href="">Supprimer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mymodal" className="modal">
                                        <form className="modal-content animate"
                                        action='' method="post" style={{
                                            marginTop:'70px'
                                        }}>
                                        <div className="modal-header">
                                            <h5 className="modal-title"><strong>Modifier {selectedNote}</strong></h5>
                                        </div>
                                        <div className="imgcontainer">
                                            <span onClick={() => fermer('mymodal')} className="close" title="Close Modal">
                                                &times;</span>
                                        </div>
                                        <div className="modal-body">
                                        <div className="container">
                                        <label>Date</label>
                                        <div className="row" style={{ marginTop: "5px"}}>
                                            <div className="col-lg-12">
                                            <input type="date" ref={date_note} className="form-control" name="date_note"/>
                                                </div>
                                        </div>
                                            <label style={{ marginTop: "5px"}}>Note</label>
                                        <div className="row" style={{ marginTop: "5px"}}>
                                            <div className="col-lg-12">
                                            <input type="number" ref={Snote} className="form-control" name="note"/>
                                                </div>
                                        </div>
                                        <label style={{ marginTop: "5px"}}>Trimestre</label>
                                        <div className="row" style={{ marginTop: "5px"}}>
                                            <div className="col-lg-12">
                                            <select ref={sem} className="form-control" name="note">
                                                    <option value="trimestre 1">Trimestre 1</option>
                                                    <option value="trimestre 2">Trimestre 2</option>
                                                    <option value="trimestre 3">Trimestre 3</option>
                                                    <option value="trimestre 4">Trimestre 4</option>
                                            </select>
                                            </div>
                                        </div>
                                        
                                        <label style={{ marginTop: "5px"}}>Type</label>
                                        <select style={{ marginTop: "5px"}}  ref={type}  className="form-control" name="type">
                                            <option value="Justesse">Justesse</option>
                                            <option value="Rythme">Rythme</option>
                                            <option value="Feeling">Feeling</option>
                                            <option value="Theorique">Theorique</option>
                                        </select>
                                        </div>
                                        </div>
                                        <div className="modal-footer">
                                        <button onClick={(e)=>handleNote(e,'mymodal')} className="btn btn-sm btn-primary" type="submit"><i className="fas  fa-save"></i> Enregistrer</button>
                                        </div>
                                        </form>
                                </div>
                    {/* end modals */}
                </div>
            </div>
        </div>
    );
}
export default Notes;