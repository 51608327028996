import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

import './styleNotFound.css'

function NotFound() {
    const [mouseY, setMouseY] = React.useState(0)
    const [mouseX, setMouseX] = React.useState(0)
    
   
    


    React.useEffect(() => {
        window.addEventListener("mousemove",(e)=> {
            handleCardMove(e)
        })
        return () => window.removeEventListener("mousemove", handleCardMove);
    }, [mouseY,mouseX]);

   
    const handleCardMove = ( event:any ) =>{
        const pageX = window.innerWidth ;
        const pageY = window.innerHeight;
        const y = event.pageY
        const x = event.pageX / -pageX
        const yAxis = (pageY/2-y)/pageY*300; 
        const xAxis = -x * 100 - 100;
        const elemnt  = document.querySelector('.box__ghost-eyes') as HTMLElement
        if(elemnt) elemnt.style.transform = 'translate('+ xAxis +'%,-'+ yAxis +'%)' 
        setMouseY(y);
        setMouseX(x);
    };

    return (
        <div className="box">
            <div className="box__ghost">
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                <div className="symbol"></div>
                
                <div className="box__ghost-container">
                <div className="box__ghost-eyes">
                    <div className="box__eye-left"></div>
                    <div className="box__eye-right"></div>
                </div>
                <div className="box__ghost-bottom">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
                <div className="box__ghost-shadow"></div>
            </div>
            
            <div className="box__description">
                <div className="box__description-container">
                <div className="box__description-title">Whoops!</div>
                <div className="box__description-text">Il semble que nous n'ayons pas trouvé la page que vous recherchez</div>
                </div>
                <Link to="/admin" target="_blank" className="box__button">Retourner</Link>
            </div>
            
            </div>
    );
    }

export default NotFound;