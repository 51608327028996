import React from 'react';
import { generateDate } from "../../services/date/date";

const ReportList = ({reportData}:any) => {

    
    return(
        <div>
            <div className="row">
                <div className="col-md-12 text-center mt-4">
                    <h3 className="text-grey-700">Liste des derniers rapports</h3>
                </div>
            </div>
            <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    Rapports envoyés
                                </div>
                                <div className="widget-box">
                                    <div className="widget-content nopadding">
                                        <table className="table table-bordered table-responsive-xl table-responsive" width="100%">
                                            <thead>
                                                <tr style={{ textAlign:'justify'}}>
                                                    <th>Type</th>
                                                    <th>Cours</th>
                                                    <th>Nouvelles Inscriptions</th>
                                                    <th>Total des inscrits</th>
                                                    <th className='text-capitalize'>élèves quittés</th>
                                                    <th className='text-capitalize'>élèves actifs</th>
                                                    <th>En règle sur les frais</th>
                                                    <th>Récouvrement</th>
                                                    <th>Fréquentation</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    reportData.map((el:any, idx:number) => {
                                                        return(
                                                            <tr key={idx}>
                                                                <td>{el.type_rapport}</td>
                                                                <td>{el.type_cours}</td>
                                                                <td>{el.nouv_inscrit}</td>
                                                                <td>{el.total_inscrit}</td>
                                                                <td>{el.quitter}</td>
                                                                <td>{el.eleve_actif}</td>
                                                                <td>{el.eleve_en_regle}</td>
                                                                <td>{el.pourcentage_recouv}</td>
                                                                <td>{el.frequentation}</td>
                                                                <td>{generateDate(el.date_envoie)}</td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
    );
}
export default ReportList