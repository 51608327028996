import React, { useEffect, useState } from "react";
import "../../styles/fromTop.css";
import { Link, useParams } from "react-router-dom";
import https from "../helpers/https";
import { getCookie} from "../services/cookies/dataCookies";
import profil from "../img/undraw_profile.svg";
import { decode, encode } from "../services/slugs";
import { generateDate } from "../services/date/date";
import { getHeader } from "../helpers/getHeader";

const ViewProf = () => {
  const initialAdmin = {
    url_image: profil,
    nom_resp: "",
    prenom_resp: "",
    email_resp: "",
    adresse_resp: "",
    telephone_resp: 0,
  };
  const initialState = {
    date_deb: Date.now(),
    date_fin: Date.now(),
    time_deb: undefined as any,
    time_fin: undefined as any,
    motifs: "",
  };
  const [absences, setAbsences] = useState([] as any);
  const [absence, setAbsence] = useState(initialState);
  const params = useParams();
  const [resp, setprofile] = useState([initialAdmin] as any);
  const [delectedAbs, setDelectedAbs] = useState(0);
  const [index, setIndex] = useState(1);
  const [index2, setIndex2] = useState(0);

  const fetchProfil = async () => {
    const profile = await https.get(
      `/responsables/info/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${decode(params.id_resp)}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (profile) {
      setprofile(profile.data);
      
      console.log(profile.data)
    }
  };
  const fetchAbsence = async () => {
    const abs = await https.get(
      `/absences_resp/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/responsable/${decode(params.id_resp)}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (abs) {
      setAbsences(abs.data);
    }
  };

  useEffect(() => {
    fetchProfil().catch((e) => console.log(e));
    fetchAbsence().catch((e) => console.log(e));
  }, []);

  const handleChange = (e: any) => {
    setAbsence({
      ...absence,
      [e.target.name]: e.target.value,
    });
  };

  const handleAbsence = (e: any, id: string) => {
    e.preventDefault();
    if(absence.motifs != ""){
      https
        .post(`/absences_resp/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
          ...absence,
          id_resp_responsable: parseInt(decode(params.id_resp)),
          id_centre_centres: localStorage.getItem("EGMUserCentre")
        },{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((res) => {
          if (res.status === 200) {
            fermer(id);
            fetchAbsence().catch((e) => console.log(e));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deletedAbs = async (e: any, ID: string, id: number) => {
    e.preventDefault();
    if (id) {
      let resp = await https
        .delete(`/absences_resp/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .catch((e) => {
          return e;
        });
      if (resp.status == 200) {
        fetchAbsence()
          .then(() => {
            fermer(ID);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const open = (e: any, ID: string, id: number) => {
    e.preventDefault();
    let modal = document.querySelector("#" + ID) as Element;
    modal?.classList.add("open");
    setDelectedAbs(id);
  };

  const fermer = (id: string) => {
    let modal = document.querySelector("#" + id) as Element;
    modal?.classList.remove("open");
  };
  const prev = () => {
    if (index > 2) {
      setIndex2(index - 10);
      setIndex(index - 1);
    } else {
      setIndex(1);
      setIndex2(0);
    }
  };
  const next = () => {
    if (index < Math.round(absences.length / 10)) {
      setIndex(index + 1);
      setIndex2(index + 10);
    } else {
      setIndex(1);
      setIndex2(0);
    }
  };

  const current = (index: any) => {
    setIndex(index);
    setIndex2(index + 10);
  };

  const disabled = (status: boolean) => {
    if (status) {
      if (index > Math.round(absences.length / 10)) return " disabled";
    } else {
      if (index <= 1) return " disabled";
    }
    return "";
  };

  const active = (indexnew: any, i: any) => {
    const active = index - i === indexnew ? " active" : "";
    return active;
  };
  return (
    <div
      className="bodybar mt-0 pt-4"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <div className="container">
        <div className="main-body">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={
                        resp[0]?.url_image == null ? profil : resp[0]?.url_image
                      }
                      alt="Admin"
                      className="p-1 bg-primary"
                      style={{height:'150px' ,width: '150px', borderRadius:'50%'}} 
                    />
                    <div className="mt-3">
                      <h4>Je suis {resp[0]?.prenom_resp}</h4>
                      <p className="text-secondary mb-1">
                        Proffesseur
                      </p>
                      <p className="text-muted font-size-sm">De L'EGM</p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0 font-weight-bold text-warning">
                        <i className="fab fa-google fa-fw text-danger"></i>
                        mail
                      </h6>
                      <span className="text-secondary">
                        {resp[0]?.email_resp}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <br />
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Nom</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.nom_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Prénom</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.prenom_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.telephone_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Adresse</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.adresse_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Centre</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.lieu_centre}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Fonction</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0]?.nom_dis}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Date d'entré</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={generateDate(resp[0]?.resp_createdAt)}
                      />
                    </div>
                  </div>
                  <Link to={'/super-admin/modify-prof/'+ params.id_resp } className="btn btn-sm btn-primary m-2">Modify <i className="fas fa-fw fa-pencil-alt"></i></Link>
                  <Link to={localStorage.getItem("EGMUserType")=="super_admin" ? "/super-admin/liste-des-professeurs" :  "/admin/liste-des-professeurs"}>
                    <button className="btn btn-success float-right btn-sm">
                      <i className="fa fa-reply fa-fw text-light"></i> Retour
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
            {/* Begin */}
        <div className="row">
          <div className="col-lg-12 mb-5 mt-5">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Listes des absences{" "}
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={(e) =>
                      open(e, "mymodal", parseInt(decode(params.id)!))
                    }
                  >
                    <i className="fas fa-fw fa-plus"></i> Ajouter
                  </button>
                </h6>
              </div>
              <div className="widget-box">
                <div className="widget-content nopadding">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Heure début</th>
                        <th>Heure fin</th>
                        <th>Motifs</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {absences
                        .filter((value: any, i: any) => {
                          return value && i >= index2 && i < index * 10;
                        })
                        .map((el: any) => (
                          <tr key={el.id_abs_resp}>
                            <td>{generateDate(el.date_deb)}</td>
                            <td>{generateDate(el.date_fin)}</td>
                            <td>{el.time_deb}</td>
                            <td>{el.time_fin}</td>
                            <td>{el.motifs}</td>
                            <td>
                              <button
                                onClick={(e) =>
                                  open(e, "logoutModal", el.id_abs_resp)
                                }
                                className="btn btn-sm btn-danger"
                              >
                                <i className="fas fa-fw fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {absences.length > 10 && (
                  <ul className="pagination pagination-md d-flex justify-content-center">
                    <li
                      className={"page-item" + disabled(false)}
                      style={{ cursor: index <= 1 ? "no-drop" : "pointer" }}
                    >
                      <span className="page-link" onClick={prev}>
                        Précedent
                      </span>
                    </li>
                    {index > 1 && (
                      <li className="page-item" style={{ cursor: "no-drop" }}>
                        <span className="page-link">...</span>
                      </li>
                    )}
                    <li
                      className={"page-item" + active(index, 0)}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="page-link"
                        onClick={() => current(index)}
                      >
                        {index}
                      </span>
                    </li>
                    {index + 1 <= Math.round(absences.length / 10) && (
                      <li
                        className={"page-item" + active(index, 1)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => current(index + 1)}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )}
                    {index + 2 <= Math.round(absences.length / 10) && (
                      <li
                        className={"page-item" + active(index, 2)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => current(index + 2)}
                        >
                          {index + 2}
                        </span>
                      </li>
                    )}
                    {Math.round(absences.length / 10) > 2 &&
                      index < Math.round(absences.length / 10) && (
                        <li className="page-item" style={{ cursor: "no-drop" }}>
                          <span className="page-link">...</span>
                        </li>
                      )}
                    <li
                      className={"page-item" + disabled(true)}
                      style={{
                        cursor:
                          index >= Math.round(absences.length / 10)
                            ? "no-drop"
                            : "pointer",
                      }}
                    >
                      <span className="page-link" onClick={next}>
                        Suivant
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end */}
          {/* modals */}
          <div className="modal" id="logoutModal">
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{
                width: "100%",
                marginTop: "150px",
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Attention!
                </h5>
              </div>
              <div
                className="modal-body"
                style={{
                  padding: "20px",
                }}
              >
                Voulez vous vraiment supprimer cette absence ?{" "}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary btn-sm"
                  type="button"
                  onClick={() => fermer("logoutModal")}
                  data-dismiss="modal"
                >
                  Anuller
                </button>
                <a
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deletedAbs(e, "logoutModal", delectedAbs)}
                  href=""
                >
                  Supprimer
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="mymodal" className="modal">
          <form
            className="modal-content animate"
            action=""
            method="post"
            style={{
              marginTop: "70px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Absences</strong>
              </h5>
            </div>
            <div className="imgcontainer">
              <span
                onClick={() => fermer("mymodal")}
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="container">
                <label>De</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-6">
                    <input
                      type="date"
                      onChange={handleChange}
                      value={absence.date_deb}
                      className="form-control"
                      name="date_deb"
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="time"
                      onChange={handleChange}
                      value={absence.time_deb}
                      className="form-control"
                      name="time_deb"
                    />
                  </div>
                </div>
                <label style={{ marginTop: "5px" }}>A</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-6">
                    <input
                      type="date"
                      onChange={handleChange}
                      value={absence.date_fin}
                      className="form-control"
                      name="date_fin"
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="time"
                      onChange={handleChange}
                      value={absence.time_fin}
                      className="form-control"
                      name="time_fin"
                    />
                  </div>
                </div>

                <label style={{ marginTop: "5px" }}>Motif</label>
                <textarea
                  rows={4}
                  style={{ marginTop: "5px" }}
                  onChange={handleChange}
                  value={absence.motifs}
                  className="form-control"
                  name="motifs"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => handleAbsence(e, "mymodal")}
                className="btn btn-sm btn-primary"
                type="submit"
              >
                <i className="fas  fa-save"></i> Enregistrer
              </button>
            </div>
          </form>
        </div>
            {/* end */}
        </div>
      </div>
    </div>
  );
};

export default ViewProf;
