import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHeader } from '../../helpers/getHeader';
import https from '../../helpers/https';

const ChooseAdminCenter = ({ setFilter }: any) => {
    const [allCentre, setallCentre] = useState([] as any);
    const [centre, setCentre] = useState ('Tout')

    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data);
        }
    }

    useEffect(()=>{
        fetchCentre()
            .catch(error => console.log(error));
    },[])
    return(
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex">
                            <h5 className="mb-0 text-gray-800">Centre : {centre}</h5>
                        </div>
                        <div>
                            <select className="form-control"  onChange={e => {

                                setFilter(e.target.value)
                                if(e.target.value !== '*'){
                                    https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${encodeURI(e.target.value)}`,{
                                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                                    })
                                    .then((res)=>setCentre(res.data[0].nom_centre))
                                    .catch((e) => console.log(e))
                                }else{
                                    setCentre('Tout')
                                }
                                }}>
                                <option value="*">Tout</option>
                                {allCentre.map((element:any,index:any) => {
                                    return(
                                        <option  key={index.toString()} id={element.nom_centre} value={element.id_centre}>{element.nom_centre}</option>
                                    );
                                })}
                            </select>
                        </div>
            </div>
    );
}
export default ChooseAdminCenter;