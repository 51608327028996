import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ChooseReports = () => {
    const centreId = useParams<string>();
    return (
        <div className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
                <div className="d-sm-flex align-items-center justify-content-between">
                        <h1 className="h3 mb-0 text-gray-800">Type de rapport</h1>
                </div>
               <div className="row mt-4">
                        <Link to={"/super-admin/rapport-hebdomadaire/"+centreId.centreId} className="col-xl-6 col-md-6" >
                            <div className="card border-left-primary shadow h-100 py-2 overlay"id="about">
                                <div className="card-body ">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                rapport</div>
                                                
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">Hebdomadaire</div>
                                        </div>
                                         
                                        <div className="col-auto">
                                        
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/super-admin/rapport-mensuel/centreId"+centreId.centreId} className="col-xl-6 col-md-6" >
                            <div className="card border-left-primary shadow h-100 py-2 overlay"id="about">
                                <div className="card-body ">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                rapport</div>
                                                
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">Mensuel</div>
                                        </div>
                                         
                                        <div className="col-auto">
                                        
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </Link>
                </div>
                {/* end of container fluid */}
                <div className="row mt-3">
                    <div className="col-lg-3">
                        <Link to="/super-admin/rapports" className="btn btn-sm btn-success "><i className="fas fa-fw fa-reply"></i> Retour</Link>
                    </div>
                </div>
        </div>
    );
}
export default ChooseReports;