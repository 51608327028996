import React, { useEffect, useState } from "react";
import https from "../../../helpers/https";
import { getHeader } from "../../../helpers/getHeader";

const SelectCenter = ({ setCenter }: any) => {
  const [allCentre, setallCentre] = useState([] as any);
  const fetchCentre = async () => {
    const centers = await https
      .get(
        `/centres/${localStorage.getItem("EGMUserId")}/${localStorage.getItem(
          "EGMUserType"
        )}`,
        {
          headers: getHeader(localStorage.getItem("EGMUserToken"), "json"),
        }
      )
      .catch((err) => console.log(err));
    if (centers) {
      setallCentre(centers.data);
    }
  };
  useEffect(() => {
    fetchCentre();
  }, []);

  return (
    <>
      <div className="d-flex">
        <h5
          className="mb-0 text-gray-800"
          style={{ textTransform: "capitalize" }}
        >
          Centre
        </h5>
      </div>
      <div className="px-3">
        <select
          className="form-control"
          onChange={(e) => setCenter(e.target.value)}
        >
          <option value="tout">Tout</option>
          {allCentre.map((centre: any, idx: number) => (
            <option value={centre.nom_centre} key={idx}>
              {centre.nom_centre}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
export default SelectCenter;
