import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import https from '../helpers/https';
import ChooseCenter from '../superAdmin/studentsComponents/chooseCenter';
import { encode } from '../services/slugs';
import { getHeader } from '../helpers/getHeader';
import { generateDate } from '../services/date/date';
const QuitStudents = () => {
    const [allStudents, setAllStudents] = useState([] as any);
    const [centerFilter, setCenterFilter] = useState('*');
    const [eleve,setEleve] = React.useState({} as any)
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [auth_type, setAuthType] = useState(localStorage.getItem('EGMUserType'));
    const [auth_centre, setAuthCentre] = useState<any>();
    const [isLoading, setIsLoading] = useState(false)

    const fetchCentre = async ()=>{
        try{
            await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then((res :any) => {
                    res.data.map((el:any)=>{
                        if(el.id_centre == localStorage.getItem('EGMUserCentre')) 
                            setAuthCentre(el.nom_centre)
                    })
                })
        }
        catch(e){
            console.log(e)
        }
    }
    const fetchStudents = async (filter: string)=>{
        setIsLoading(true)
        if(auth_type == "super_admin"){
            if(filter == '*'){
                try{
                    const s = await https.get(`/eleves/where_status/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/0/all`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(s){
                        setIsLoading(false)
                        setAllStudents(s.data);
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
            else{
                try{
                    const s = await https.get(`/eleves/where_status/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/0/${encodeURI(filter)}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(s){
                        setIsLoading(false)
                        setAllStudents(s.data);
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
        }
        else{
            try{
                const s = await https.get(`/eleves/where_status/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/0/${auth_centre}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(s){
                    setIsLoading(false)
                    setAllStudents(s.data);
                }
            }
            catch(e){
                console.log(e)
            }
        }
    }

    const prev  = () =>{
        if(index > 2){
            setIndex2(index -10)
            setIndex(index - 1)
        }else{
            setIndex(1)
            setIndex2(0)
        }
        
    }
    const next = () =>{
        if( index < Math.round(allStudents.length/10)){
            setIndex(index + 1)
            setIndex2(index  + 10)
        }else{
            setIndex(1)
            setIndex2(0)
        }
    }


    const current = (index: any) =>{
        setIndex(index)
        setIndex2(index  + 10)
    }

    const disabled = (status: boolean) =>{
        if(status){
            if(index >= Math.round(allStudents.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }
    useEffect(() => {
        if(auth_type == "super_admin") fetchStudents(centerFilter);
    },[centerFilter])

    useEffect(() => {
        if(auth_type == "admin") fetchStudents('*');
    },[auth_centre])

    useEffect(()=> {
        fetchCentre()
            .catch(error => console.log(error));
    },[])

    const open = (id:string,eleve:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setEleve(eleve)
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }
    const deleteStudent = (e:any,id:string) =>{
        e.preventDefault()
        if (eleve && eleve.id_el) {
            https.delete(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${eleve.id_el}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then((res) =>{
                if(res.status === 200){
                    fetchStudents('*')
                        .then(() =>{fermer(id)})
                        .catch((e:any)=>console.log(e))
                }
            })
            .catch((e)=>{
                console.log(e)
            })
        }
    }

    const handleRestore = (e: any, id:string) => {
        e.preventDefault()
        let data = {
            "id_el":eleve
        }
        try{
            https.put(`/eleves/restore/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${eleve}`, {},{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then((res) =>{
                if(res.status === 200){
                    fermer(id)
                    fetchStudents('*')
                        .catch((e:any)=>console.log(e))
                }
            })
            .catch((e)=>{
                console.log(e)
            })
        }
        catch(e){
            console.log(e)
        }
    }

    return (
    <div className='bodybar mt-0 pt-4' style={{
        height:window.innerHeight-70
    }}>
        <div className="container-fluid">
            { auth_type == "super_admin" ? <ChooseCenter setFilter = { setCenterFilter }/> : "" }
             {/* Begin */}
             <div className="row">
                    <div className="col-lg-12 mb-5">
                        <div className="card shadow mt-0">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Listes des &eacute;l&egrave;ve qui ont quitt&eacute;</h6>
                               </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                    <table className="table table-bordered table-responsive-xl  table-striped" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Motifs</th>
                                                <th>Depuis</th>
                                                <th>Téléphone</th>
                                                <th>Cours</th>
                                                <th>Centre</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Chargement...
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                allStudents.filter((value:any,i:any) => {
                                                    return value && i >= index2 && i < (index * 10)
                                                }).map((el:any)=>(
                                                    <tr key={el.id_el}>
                                                        <td style={{ textTransform:'capitalize'}}>{el.nom}</td>
                                                        <td style={{ textTransform:'capitalize'}}>{el.prenom}</td>
                                                        <td>{el.motifs && el.motifs!=='' ?  el.motifs :  "Aucun"}</td>
                                                        <td>{generateDate(el.date_abondant)}</td>
                                                        <td>{el.telephone && el.telephone!=='null' ?  el.telephone :  "Aucun"}</td>
                                                        <td>{el.type_cycle.slice(0,15)}</td>
                                                        <td>{el.nom_centre}</td>
                                                        { auth_type == "super_admin" ? 
                                                            <td className='mt-0 pt-0 pb-0 d-flex'>
                                                                <Link to={"/super-admin/a-propos/"+encode(el.id_el)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                                                                <button onClick={() =>open('restoreModal',el.id_el)}  className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-arrow-up"></i></button>
                                                                {localStorage.getItem('EGMUserType') === 'super_admin'&& el.type_cycle != "academique" &&<button onClick={() =>open('logoutModal',el)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-trash-alt"></i></button>}
                                                            </td>
                                                            :
                                                            <td className='mt-0 pt-0 pb-0 d-flex'>
                                                                <Link to={"/admin/a-propos/"+encode(el.id_el)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                                                                <button onClick={() =>open('restoreModal',el.id_el)}  className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-arrow-up"></i></button>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <div className="modal" id="logoutModal">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content" style={{
                                                width: '100%',
                                                marginTop:'150px',
                                            }} >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                                                    
                                                </div>
                                                <div className="modal-body" style={{
                                                    padding:'20px'
                                                }}>Voulez vous vraiment supprimer cet élève ?</div>
                                                <div className="modal-footer">
                                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                                    <a className="btn btn-danger btn-sm" href="" onClick={(e) => deleteStudent(e,"logoutModal")}>Supprimer</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal" id="restoreModal">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content" style={{
                                                width: '100%',
                                                marginTop:'150px',
                                            }} >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Réstorer ?</h5>
                                                    
                                                </div>
                                                <div className="modal-body" style={{
                                                    padding:'20px'
                                                }}>Voulez vous vraiment réstorer cet élève ?</div>
                                                <div className="modal-footer">
                                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('restoreModal')}   data-dismiss="modal">Anuller</button>
                                                    <a className="btn btn-success btn-sm" href="" onClick={(e) => handleRestore(e,"restoreModal")}>Réstorer</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {allStudents.length > 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index)}>{index}</span></li>
                                {index + 1 <= Math.round(allStudents.length/10) && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1)}>{index + 1}</span></li>}
                                { index + 2 <= Math.round(allStudents.length/10) && <li className={"page-item"+active(index,2)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index+2)}>{index + 2}</span></li>}
                                {Math.round(allStudents.length/10) > 2 && index < Math.round(allStudents.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(allStudents.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                            </ul>}
                           
                        </div>
                       
                    </div>
                    
            </div>
            {/* end */}
        </div>
        </div>
    );
}
export default QuitStudents;