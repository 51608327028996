import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SelectCenter from "../SelectCenter/SelectCenter";

const MonthSelector = ({ month, setMonth, setCenter }: any) => {
  const handleChange = (e: any) => {
    e.preventDefault();
    setMonth(e.target.value);
  };

  return (
    <div className="row pl-3">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <div className="d-flex">
          <h5
            className="mb-0 text-gray-800"
            style={{ textTransform: "capitalize" }}
          >
            Mois
          </h5>
        </div>
        <div className="px-3">
          <select
            className="form-control"
            value={month}
            onChange={(e) => handleChange(e)}
          >
            <option value="0">Janvier</option>
            <option value="1">Février</option>
            <option value="2">Mars</option>
            <option value="3">Avril</option>
            <option value="4">Mai</option>
            <option value="5">Juin</option>
            <option value="6">Juillet</option>
            <option value="7">Août</option>
            <option value="8">Septembre</option>
            <option value="9">Octobre</option>
            <option value="10">Novembre</option>
            <option value="11">Décembre</option>
          </select>
        </div>
        {localStorage.getItem("EGMUserType") == "super_admin" && (
          <SelectCenter setCenter={setCenter} />
        )}
      </div>
    </div>
  );
};
export default MonthSelector;
