import React, { useEffect,useState, useRef  } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getHeader } from "../helpers/getHeader";
import https from "../helpers/https";
import { fromatDate, generateDate } from "../services/date/date";
import { decode } from "../services/slugs";

const Inventaires = () => {

  const [allCentre, setallCentre] = useState([] as any);
  const [allinventaires, setAllinventaires] = useState([] as any);
  const [inventaire, setinventaire] = React.useState({} as any);
  const centreId = useParams();
  const [selectInv, setSelectInv] = useState<any>()
  const designation = useRef<any>()
  const numero = useRef<any>()
  const etat = useRef<any>()
  const nombres = useRef<any>()
  const aquis_date = useRef<any>()
  const caract = useRef<any>()
  const [url_img, setUrl_img] = useState<any>()
  const prix = useRef<any>()
  let state = [{
        "designation": "",
        "numero": 0,
        "etat": "",
        "nombres": 0,
        "prix": 0,
        "aquis_date": "",
        "caract": "",
        "img": "",

  }]
  const [oneInventaire, setOneInv] = useState<any>(state)

 

 

  const fetchinventaires = async () => {
    const s = await https.get(
      `/inventaires/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${decode(centreId.id_centre)}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (s) {
      setAllinventaires(s.data);
    }
  };

  const fetchOneInventaires = async (id : number) => {
    const s = await https.get(
      `/inventaires/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (s) {
      setOneInv(s.data);
    }
  };

  

  const open = (id: string, inventaire: any) => {
    let modal = document.querySelector("#" + id) as Element;
    modal?.classList.add("open");
    setinventaire(inventaire);
    fetchOneInventaires(inventaire.id_inv)
  };
  

  useEffect(()=>{
    designation.current.value = oneInventaire[0].designation
    numero.current.value = oneInventaire[0].numero
    etat.current.value = oneInventaire[0].etat
    nombres.current.value = oneInventaire[0].nombres
    prix.current.value = oneInventaire[0].prix
    aquis_date.current.value =fromatDate(oneInventaire[0].date_aquis)
    caract.current.value = oneInventaire[0].caracteristique
    setUrl_img(oneInventaire[0].inv_image)
},[oneInventaire])

  const fermer = (id: string) => {
    let modal = document.querySelector("#" + id) as Element;
    modal?.classList.remove("open");
  };
  const deleteInv = (e: any, id: string) => {
    e.preventDefault();
    if (inventaire && inventaire.id_inv) {
      https
        .delete(`/inventaires/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${inventaire.id_inv}`,{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
      })
        .then((res) => {
          if (res.status === 200) {
            fetchinventaires()
              .then(() => {
                fermer(id);
              })
              .catch((e: any) => console.log(e));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const modifyInv = (e:any,id:string) => {
    e.preventDefault()
    let data = {
        "designation" :  designation.current.value,
        "numero" : numero.current.value,
        "etat": etat.current.value,
        "nombres": nombres.current.value,
        "prix": prix.current.value,
        "id_centre_centres": oneInventaire[0].id_centre_centres,
        "id_inv" : inventaire.id_inv
        
    }
    
    try{
        https.put(`inventaires/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then(()=>{
          fetchinventaires()
            .catch(error => console.log(error));
            fermer(id)
        })
        .catch((e)=> console.log(e))
    }
    catch(e){
        console.log(e)
    }
}

  useEffect(() => {
    fetchinventaires().catch((error) => console.log(error));
  }, []);

  return (
    <div
      className="bodybar mt-0 pt-4"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <div className="container-fluid">
        {/* Begin */}
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="card shadow mt-0">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Listes des inventaires
                </h6>
              </div>
              <div className="widget-box">
                <div className="widget-content nopadding">
                  <table
                    className="table table-bordered table-responsive-xl  table-striped"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Désignation</th>
                        <th>Numéro de série</th>
                        <th>Date d'acquisition</th>
                        <th>Etat</th>
                        <th>Nombres</th>
                        <th>Prix</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allinventaires.map((inv: any) => (
                        <tr key={inv.id_inv}>
                          <td>{inv.designation}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.numero}
                          </td>
                          <td>
                            {generateDate(inv.date_aquis)}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.etat}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.nombres}
                          </td>
                          <td>{inv.prix}</td>
                            <td className="mt-0 pt-0 pb-0 d-flex border-none">
                              {localStorage.getItem("EGMUserType") === "super_admin" && (
                               <button onClick={() =>open('mymodal',inv)} className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-eye"></i></button>
                              )}
                              {localStorage.getItem("EGMUserType") === "super_admin" && (
                                <button
                                  onClick={() => open("logoutModal", inv)}
                                  className="btn btn-sm btn-danger m-2"
                                >
                                  <i className="fas fa-fw fa-trash-alt"></i>
                                </button>
                              )}
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="modal" id="logoutModal">
                    <div className="modal-dialog" role="document">
                      <div
                        className="modal-content"
                        style={{
                          width: "100%",
                          marginTop: "150px",
                        }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Attention!
                          </h5>
                        </div>
                        <div
                          className="modal-body"
                          style={{
                            padding: "20px",
                          }}
                        >
                          Voulez vous vraiment supprimer cet inventaire ?
                        </div>
                        <div className="modal-footer">
                          <button
                            className="btn btn-secondary btn-sm"
                            type="button"
                            onClick={() => fermer("logoutModal")}
                            data-dismiss="modal"
                          >
                            Anuller
                          </button>
                          <a
                            className="btn btn-danger btn-sm"
                            href=""
                            onClick={(e) => deleteInv(e, "logoutModal")}
                          >
                            Supprimer
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mymodal" className="modal">
                    <form
                      className="modal-content animate"
                      action=""
                      method="post"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <strong>Modifier l'inventaire numéro {inventaire.id_inv > 10 ?inventaire.id_inv : '0'+ inventaire.id_inv}</strong>
                        </h5>
                      </div>
                      <div className="imgcontainer">
                        <span
                          onClick={() => fermer("mymodal")}
                          className="close"
                          title="Close Modal"
                        >
                          &times;
                        </span>
                      </div>
                      <div className="modal-body">
                        <div className="container">
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-lg-6">
                              <label>Designation</label>
                              <input
                                type="text"
                                ref={designation}
                                className="form-control"
                                name="designation"
                              />
                            </div>
                            <div className="col-lg-6">
                              <label>Numero</label>
                              <input
                                type="number"
                                ref={numero}
                                className="form-control"
                                name="numero"
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-lg-6">
                              <label style={{ marginTop: "5px" }}>
                                Etat
                              </label>
                              <input
                                type="text"
                                ref={etat}
                                className="form-control"
                                name="etat"
                              />
                            </div>
                            <div className="col-lg-6">
                              <label style={{ marginTop: "5px" }}>
                                Nombre
                              </label>
                              <input
                                type="number"
                                ref={nombres}
                                className="form-control"
                                name="nombres"
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-lg-6">
                                <label style={{ marginTop: "5px" }}>
                                  Caractéristique
                                </label>
                                <input
                                  type="text"
                                  ref={caract}
                                  className="form-control"
                                  name="etat"
                                />
                              </div>
                            <div className="col-lg-6">
                                <label style={{ marginTop: "5px" }}>
                                  Date d'acquisition
                                </label>
                                <input
                                  type="date"
                                  ref={aquis_date}
                                  className="form-control"
                                  name="etat"
                                />
                              </div>
                          </div>
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-lg-12">
                              <label style={{ marginTop: "5px" }}>
                                Prix
                              </label>
                              <input
                                type="number"
                                ref={prix}
                                className="form-control"
                                name="prix"
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-lg-12">
                              <label style={{ marginTop: "5px" }}>
                                Image
                              </label> <br />
                              <img src={url_img} alt="" width={'30%'}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          onClick={(e) => modifyInv(e, "mymodal")}
                          className="btn btn-sm btn-primary"
                          type="submit"
                        >
                          <i className="fas fa-save"></i> Modifier
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
      </div>
    </div>
  );
};
export default Inventaires;
