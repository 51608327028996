import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../styles/fromTop.css';
import { getHeader } from '../helpers/getHeader';
import https from '../helpers/https';
import imageEmail from '../img/eaml-0.gif'
import ViewError from '../services/ViewError';


function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Email = () => {
    const route = useLocation()
    const [type, setType] = useState('eleve')
    const [listEmail, setListEmail] = useState('')
    const [centre, setCentre] = useState("")
    const [ok, setOk] = useState(false)
    const [isSend, setIsSend] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingCopie, setLoadingCopie] = useState(false)
    const [subject, setSubject] = useState("")
    const [error, setError] = useState('')
    const [errorCopie, setErrorCopie] = useState('')
    const [isCopie, setIsCopie] = useState(false)
    const [message, setMessage] = useState("")
    const auth_centre = parseInt(localStorage.getItem("EGMUserCentre")!);
    const emailUser = localStorage.getItem("EGMUserEmail")
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [resultat, setResultat] = useState('')
    const [separateur, setSeparateur] = useState(',')
    const [outputType, setOutputType] = useState('email')

    const fetchCentre = async () => {
        try {
            const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if (centers && centers.data) {
                for await (const el of centers.data) {
                    if (el.id_centre == auth_centre) {
                        setCentre(el.nom_centre)
                        setOk(true)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        function handleWindowResize() {
            setIsCopie(false)
        }

        window.addEventListener('auxclick', handleWindowResize);

        return () => {
            window.removeEventListener('auxclick', handleWindowResize);
        };
    }, []);

    const sendEmail = async () => {
        return https.post(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/sendemail`,
            { subject: subject, message: message, emailUser: emailUser, listEmail: listEmail.toString() }, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
    }

    const fetchAll = () =>{
        if (centre && separateur && outputType && type) {
            if (type === 'eleve') {
                fetchEleve()
            } else {
                fetchResponsable()
            }
        }
    }

    useEffect(() => {
        if (!ok) {
            fetchCentre()
        }
    }, [ok])

    const fetchEleve = async () => {
        try {
            let response;
            if (route.pathname === '/super-admin/email') {
                response = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                });
            } else if (route.pathname === '/admin/email') {
                response = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${encodeURI(centre)}`, {
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                });
            }
            if (response && response.data) {
                let data = []
                
                if (outputType === "email") {
                    data = response.data.filter((el: any) => el.email && parseInt(el.status)).map((v: any) => v.email)
                } else {
                    data = response.data.filter((el: any) => el.telephone && parseInt(el.status)).map((v: any) => v.telephone)
                }
                if (data.length) {
                    if (separateur === ',') {
                        setResultat(data.toString())
                    } else {
                        setResultat(data.toString().replaceAll(',', ';'))
                    }
                } else {
                    setResultat('')
                }
            }else {
                setResultat('')
            }
        } catch (error) {
            console.log(error)
            setResultat('')
        }
    }

    const fetchResponsable = async () => {
        try {
            let response
            if (route.pathname === '/super-admin/email') {
                response = await https.post(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/allResponsable`,{}, {
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                });
            } else if (route.pathname === '/admin/email') {
                if (type === "super_admin") {
                    response = await https.post(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/allResponsable`,{}, {
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    });
                } else {
                    response = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${type}/${auth_centre}`, {
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    });
                }
            }

            if (response && response.data) {
                let data = []
                switch (type) {
                    case "professeur":
                        data = response.data.filter((el: any) => el && el.type_resp === "professeur")
                        break;
                    case "super_admin":
                        data = response.data.filter((el: any) => el && el.type_resp === "super_admin")
                        break;
                    case "admin":
                        data = response.data.filter((el: any) => el && el.type_resp === "admin")
                        break;
                }
                if (data.length) {
                    if (outputType === "email") {
                        data = data.filter((el: any) => el.email_resp).map((v: any) => v.email_resp)
                    } else {
                        data = data.filter((el: any) => el.telephone_resp).map((v: any) => v.telephone_resp)
                    }
                    if (data.length) {
                        if (separateur === ',') {
                            setResultat(data.toString())
                        } else {
                            setResultat(data.toString().replaceAll(',', ';'))
                        }
                    } else {
                        setResultat('')
                    }
                }else {
                    setResultat('')
                }
            }
        } catch (error) {
            console.log(error)
            setResultat('')
        }
    }
    const handleCopie = (e: any) => {
        e.preventDefault()
        setLoadingCopie(true)
        if (resultat) {
            navigator.clipboard.writeText(resultat)
                .then(() => {
                    setIsCopie(true)
                    setErrorCopie('')
                    setLoadingCopie(false)
                })
                .catch(() => {
                    setErrorCopie('Une erreur a été produite lors de la copie, veuillez réessayer s\'il vous plait !!!')
                    setLoadingCopie(false)
                })
        } else {
            setErrorCopie("Veuillez remplir le champs 'resultat' !!!")
            setLoadingCopie(false)
        }
    }

    useEffect(() => {
        fetchAll()
    }, [type, separateur, outputType])

    const HandleSendEmail = () => {
        setLoading(true)
        if (listEmail && subject && message && emailUser) {
            sendEmail()
                .then((res) => {
                    setIsSend(true)
                    setLoading(false)
                    setError('')
                })
                .catch((e) => {
                    setIsSend(false)
                    setLoading(false)
                    setError("Une erreur  a été produite, veuillez réessayer !!!")
                })
        } else {
            setIsSend(false)
            setLoading(false)
            setError("Veuillez remplir les champs: Emails, Objet, Messages")
        }
    }

    return (
        <div className='bodybar mt-0 pt-4' style={{
            height: window.innerHeight - 70
        }}>
            {/* container fluid */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mt-4 mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h3 className="m-0 font-weight-bold text-gray-800">Listes des emails et numéro mobiles</h3>
                            </div>
                            <div className="card-body col-xl-12 col-lg-12 col-md-12 p-0 pl-lg-4">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 pl-md-4 pr-md-4 pl-sm-4 pr-sm-4">
                                        <div className="row pt-4 pb-4">
                                            {error && <div className="col-xl-12 col-lg-12 "><ViewError error={error} /></div>}
                                            {isSend && <div className="alert alert-success ml-md-3" role="alert">
                                                L'email a éte envoyé avec succès !!!
                                            </div>}
                                            <div className="col-xl-12 col-lg-12">
                                                <h6 className="m-0 font-weight-bold text-primary">Emails</h6>
                                                <div className="input-group mt-2">
                                                    <textarea rows={3} value={listEmail} onChange={(e) => setListEmail(e.target.value)} className="form-control" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Objet</h6>
                                                <div className="input-group mt-2">
                                                    <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Messages</h6>
                                                <div className="input-group mt-2">
                                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control rounded-0" id="exampleFormControlTextarea1" rows={5} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <button type="button" onClick={HandleSendEmail} className="btn btn-primary">{!loading ? "Envoyer" : "En cours d'envoie..."}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 pl-md-4 pr-md-4 pl-sm-4 pr-sm-4">
                                        <div className="row pt-4 pb-4">
                                            {errorCopie && <div className="col-xl-12 col-lg-12 "><ViewError error={errorCopie} /></div>}
                                            {isCopie && <div className="alert alert-success ml-md-3" role="alert">
                                                Le résultat est bien copié dans le presse-papier !!!
                                            </div>}
                                            <div className="col-xl-12 col-lg-12">
                                                <h6 className="m-0 font-weight-bold text-primary">Personne </h6>
                                                <div className="input-group mt-2">
                                                    <select className="form-control" value={type} onChange={(v: any) => setType(v.target.value)}>
                                                        <option value="eleve">Elèves</option>
                                                        <option value="professeur">Professeurs</option>
                                                        {route.pathname === '/admin/email' && <option value="super_admin">Directeur Géneral</option>}
                                                        {route.pathname === '/super-admin/email' && <option value="admin">Admins</option>}
                                                    </select>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-fw fa-user-alt"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Séparateur</h6>
                                                <div className="input-group mt-2">
                                                    <select className="form-control" value={separateur} onChange={(v: any) => setSeparateur(v.target.value)}>
                                                        <option value=",">Virgule (,)</option>
                                                        <option value=";">Point virgule (;)</option>
                                                    </select>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-fw fa-user-alt"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Types</h6>
                                                <div className="input-group mt-2">
                                                    <select className="form-control" value={outputType} onChange={(v: any) => setOutputType(v.target.value)}>
                                                        <option value="email">Email</option>
                                                        <option value="mobile">Numéro mobile</option>
                                                    </select>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-fw fa-user-alt"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Résultats</h6>
                                                <div className="input-group mt-2">
                                                    <textarea value={resultat}
                                                        onChange={(v: any) => setResultat(v.target.value)} className="form-control rounded-0" id="exampleFormControlTextarea1" rows={4} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 mt-3">
                                                <button type="button" disabled={resultat ? false : true} onClick={handleCopie}
                                                    className="btn btn-success">{!loadingCopie ? "Copier" : "En cours de copie..."}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of here */}
            </div>
        </div>

    );
}

export default Email;