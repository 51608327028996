import axios from 'axios';

async function postData  (URL:string,credentials:any)  {
        return await axios.post(URL, credentials,{
            headers: { 
                'Authorization': '',
                'Content-Type':'multipart/form-data'
            }
        })
        .then(response => 
                 response
        )
        .catch(error => {
            return error.response
        })
}


export {
    postData,
}