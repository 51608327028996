import React, { useState, useEffect, useRef } from 'react';
import https from '../helpers/https';
import ViewError from '../services/ViewError';
import { generateDate } from "../services/date/date"
import { getHeader } from '../helpers/getHeader';

const Recipe = () => {
    let jour = useRef<any>(),
    date_rec = useRef<any>(),
    designation = useRef<any>(),	
    recettes = useRef<any>(),	
    depenses = useRef<any>(),	
    observation = useRef<any>(),
    frequentation = useRef<any>(0)
    const [recipes, setRecipes] = useState([] as any)
    const [error, setError ] = useState<String>();
    const [isSend, setIsSend] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)

    const fetchRecipe = async () => {
        setIsLoading(true)
        await https.get(`/recettes/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${localStorage.getItem("EGMUserCentre")}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then((res)=>{
                    setIsLoading(false)
                    setRecipes(res.data)
                })
                .catch(e => console.log(e))
    }

    const sendRecipe = async (e:any, id:string) => {
        e.preventDefault()

        if((date_rec.current.value == "")||(designation.current.value == "")||(recettes.current.value == NaN)||(depenses.current.value == NaN)||(recettes.current.value < 0)||(depenses.current.value < 0)){
            setError("Veuiller remplir les champs suivant(jours, recette, date, depenses, designation)")
            fermer(id)
        }
        else{
            let data = {
                "jour": jour.current.value,
                "date_rec": date_rec.current.value,
                "designation": designation.current.value,	
                "recettes": parseInt(recettes.current.value),
                "depenses": parseInt(depenses.current.value),	
                "observation": observation.current.value,	
                "frequentation": parseInt(frequentation.current.value),
                "id_centre_centres": parseInt(localStorage.getItem("EGMUserCentre")!)
            }
            console.log(data)
            await https.post(`/recettes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then(()=>{
                    fermer(id)
                    setIsSend(true)
                    jour.current.value = ""
                    date_rec.current.value = "dd-mm-yyyy"
                    designation.current.value = ""	
                    recettes.current.value = null
                    depenses.current.value = null	
                    observation.current.value = ""
                    fetchRecipe()
                    .catch((e)=> console.log(e))
                })
                .catch(e => {return e})
        }
    }

    useEffect(()=>{
        fetchRecipe()
            .catch((e)=> console.log(e))
    },[])

    const open = (e:any,ID:string) => {
        e.preventDefault()
        let modal = document.querySelector('#'+ID) as Element;
        modal?.classList.add("open");
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    return (
        <div  className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex">
                            <h5 className="mb-0 text-gray-800">Recettes</h5>
                        </div>
                </div>
                {/* Beginn */}
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Jours</h6>
                                 <br/>
                                <div className="input-group">
                                    <select className="form-control" name="jour" ref={jour} >
                                        <option value="Lundi">Lundi</option>
                                        <option value="Mardi">Mardi</option>
                                        <option value="Mercredi">Mercredi</option>
                                        <option value="Jeudi">Jeudi</option>
                                        <option value="Vendredi">Vendredi</option>
                                        <option value="Samedi">Samedi</option>
                                        <option value="Dimanche">Dimanche</option>
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Date</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="date" className="form-control" name="date_rec" ref={date_rec} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Désignation</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="designation" ref={designation} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Fréquentation</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" className="form-control" name="designation" ref={frequentation} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                {isSend ? '' : <ViewError error={error}/> }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Recette</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" className="form-control" name="recettes" ref={recettes} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Dépenses</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" className="form-control" name="depenses" ref={depenses} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br />
                                <h6 className="m-0 font-weight-bold text-primary">Observations</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="observation" ref={observation} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <button className="btn btn-sm btn-success" onClick={(e)=> open(e, 'logoutModal')}><i className="fas fa-fw fa-save"></i> Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                Liste des dernierès recettes
                            </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                    <table className="table table-bordered table-responsive-xl" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Jour</th>
                                                <th>Date</th>
                                                <th>Désignation</th>
                                                <th>Recettes</th>
                                                <th>Dépenses</th>
                                                <th>Fréquentation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Chargement...
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                recipes.map((el:any, idx:number)=>{
                                                    return(
                                                        <tr key={idx}>
                                                            <td>{el.jour}</td>
                                                            <td>{generateDate(el.date_rec)}</td>
                                                            <td>{el.designation}</td>
                                                            <td>{el.recettes}</td>
                                                            <td>{el.depenses}</td>
                                                            <td>{el.frequentation || 0}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* modals */}
                 <div className="modal" id="logoutModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{
                            width: '100%',
                            marginTop:'150px',
                        }} >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Envoyer </h5>
                                
                            </div>
                            <div className="modal-body" style={{
                                padding:'20px'
                            }}>Voulez vous envoyer le recette ?</div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                <a className="btn btn-success btn-sm" href="" onClick={(e:any) => sendRecipe(e, 'logoutModal')}>Envoyer</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
export default Recipe;