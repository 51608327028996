import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import https from '../../helpers/https';
import { encode } from '../../services/slugs';
import ChooseAdminCenter from '../studentsComponents/chooseAdminCenter';
import Profil from '../Profil'
import { getHeader } from '../../helpers/getHeader';
import { generateDate } from '../../services/date/date';
interface props{
    el: any,
    center: any,
    handleOpen(e : any) : void
    handleOpenProfil(e : any) : void
}
const TableData = ({el,center, handleOpen,handleOpenProfil}:props) => {
    let center_name;
    const nav = useNavigate();
    center.forEach((element : any) => {
        if (element.id_centre == el.id_centre_centres)
            center_name = element.nom_centre;
    });
    const split = (text:string | number) =>{
        if(text){
            let t  = String(text)
            if(t.length > 9){
                return t.slice(0,9) + '...'
            }else{
                return t
            }
        }
    }
    return(
        <tr style={{ textTransform:'capitalize'}}>
            <td>{el.nom_resp && el.nom_resp!=='null' ?  split(el.nom_resp) :  "Aucun"}</td>
            <td>{el.prenom_resp && el.prenom_resp!=='null' ?  split(el.prenom_resp) :  "Aucun"}</td>
            <td>{el.telephone_resp && el.telephone_resp!=='null' ?  split(el.telephone_resp) :  "Aucun"}</td>
            <td>{el.adresse_resp && el.adresse_resp!=='null' ?  split(el.adresse_resp) :  "Aucun"}</td>
            <td>{el.email_resp && el.email_resp!=='null' ?  split(el.email_resp) :  "Aucun"}</td>
            <td>{center_name && center_name !=='null' ?  split(center_name) :  "Aucun"}</td>
            <td>{el.resp_createdAt!='null' ? generateDate(el.resp_createdAt) :  "Aucun"}</td>
            <td className='pt-0 pb-0 d-flex'>
                <button onClick={e => handleOpenProfil(e)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></button>
                <Link to={'/super-admin/modify-admin/'+ encode(el.id_resp) } className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></Link>
                <button onClick={e => handleOpen(e)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-trash-alt"></i></button>
            </td>
        </tr>
    );
}

const AdminList = () => {
    const [profs, setProfs] = useState([] as any);
    const [allCentre, setallCentre] = useState([] as any);
    const [centerFilter, setCenterFilter] = useState('*');
    const [id_resp,setId_resp] = React.useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data);
        }
    }
    async function deleteProf(e: any,ID: string,id : number)   {
        e.preventDefault()
        if(id){
            let resp = await https.delete(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => {return e});
            if(resp.status == 200){
                fetchProf('*')
                    .then(() =>{
                        fermer(ID)
                    })
                    .catch((e) => console.log(e))
            }
        }
    }
    const fetchProf = async (filter: string)=>{
        setIsLoading(true)
        if(filter == '*'){
            const professor = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/admin`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(professor){
                setIsLoading(false)
                setProfs(professor.data);
            }
        }
        else{
            const professor = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/admin/${encodeURI(filter)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(professor){
                setIsLoading(false)
                setProfs(professor.data);
            }

        }
    }

    useEffect(() => {
        fetchProf(centerFilter)
            .catch((e : any)=> console.log(e));
    },[centerFilter])

    useEffect(()=>{
        fetchProf('*')
            .catch(error => console.log(error));
        fetchCentre()
            .catch(error => console.log(error));
    },[])

    const open = (e:any,ID:string,id:number) => {
        e.preventDefault()
        let modal = document.querySelector('#'+ID)as Element;
        modal?.classList.add("open")
        setId_resp(id)
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    return (
        <div  className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
            <ChooseAdminCenter setFilter = { setCenterFilter }/>
            {/* Begin */}
            <div className="row">
                <div className="col-lg-12 mb-5"> 
                    <div className="card shadow mt-1">
                        <div className="card-header d-flex justify-content-between py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Listes des Admins</h6>
                            <Link to="/super-admin/ajouter-admin" className="btn btn-sm btn-primary "><i className="fas fa-fw fa-plus"></i></Link>
                        </div>
                        <div className="widget-box">
                            <div className="widget-content nopadding">
                                <table className="table table-bordered table-responsive-xl  table-striped" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Prénom</th>
                                            <th>Téléphone</th>
                                            <th>Adresse</th>
                                            <th>E-mail</th>
                                            <th>Centre</th>
                                            <th>Date d'entré</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {
                                                isLoading && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Chargement...
                                                    </td>
                                                </tr>
                                            }
                                         { profs.map((element:any,index:any)=><TableData key={index.toString()} el={element} center={allCentre} handleOpen={(e:any) => open(e,"logoutModal",element.id_resp)} handleOpenProfil={(e:any) => open(e,"profil",element.id_resp)}/>) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal" id="logoutModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{
                                width: '100%',
                                marginTop:'150px',
                            }} >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                                    
                                </div>
                                <div className="modal-body" style={{
                                    padding:'20px'
                                }}>Voulez vous vraiment supprimer cet admin ?</div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                    <a className="btn btn-danger btn-sm" href="" onClick={(e) => deleteProf(e,"logoutModal",id_resp)}>Supprimer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal" id="profil">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content p-0" style={{width:'100vh',background:'transparent'}}>
                               <Profil id={id_resp} handleClose={(e) =>fermer('profil')}/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        {/* end */}
    </div>
    );
}
export default AdminList;