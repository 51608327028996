import React, { useEffect, useState, useRef } from "react";
import "../../../styles/style.css";
import profil from "../../img/profil.png";
import logo from "../../img/logo.jpg";

import sary from "../../img/d5.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";
import https from "../../helpers/https";
import { decode, encode } from "../../services/slugs";
import { generateDate, fromatDate } from "../../services/date/date";
import { getHeader } from "../../helpers/getHeader";

const ViewStudent = () => {
  const [trimestre_now, setTrimestre_now] = useState("1")
  const [Eleve, setEleve] = useState([] as any);
  const [absences, setAbsences] = useState([] as any);
  const params = useParams();
  const nav = useNavigate();
  const [delectedAbs, setDelectedAbs] = useState(0);
  const [index, setIndex] = useState(1);
  const [index2, setIndex2] = useState(0);
  const [auth_type, setAuthType] = useState(localStorage.getItem("EGMUserType"));
  const date = useRef<any>();
  const somme_paye = useRef<any>();
  const trimestre = useRef<any>();
  const [isModifEco, setIsModifEco] = useState(false);
  const dateModif = useRef<any>();
  const somme_payeModif = useRef<any>();
  const trimestreModif = useRef<any>();
  const [ecolage, setEcolage] = useState([] as any);
  const [aEncaisser, setAEncaisser] = useState<number>();
  const [totalApaye, setTotalApaye] = useState<number>();
  const [sommeRest, setSommeRest] = useState<number>(0);
  const motif_quitter = useRef<any>();
  const date_quitter = useRef<any>();
  const [isOk, setISOk] = useState<boolean>(false);

  const initialState = {
    date_deb: Date.now(),
    date_fin: Date.now(),
    time_deb: undefined as any,
    time_fin: undefined as any,
    motifs: "",
  };
  
  const printDiv = () =>{
    
    let date = document.getElementById("date");
    if (date) date.style.fontSize = "13px";
    let montant = document.getElementById("montant");
    if (montant) montant.style.fontSize = "13px";
    let enc = document.getElementById("encaiss");
    let img = document.getElementById("img");
    let paye = document.getElementById("paye");
    let rest = document.getElementById("reste");
    if (enc) enc.style.background = "#e74a3b";
    if (enc) enc.style.fontSize = "13px";
    if (img) img.style.width = "40";
    if (img) img.style.height = "40";
    if (paye) paye.style.background = "#1cc88a";
    if (paye) paye.style.fontSize = "13px";
    if (rest) rest.style.background = "#f6c23e";
    if (rest) rest.style.fontSize = "13px";
    let a  = document.getElementById("echo")?.innerHTML  as any;
    let b = window.open('','','left=100,height=1000,width=1200');
    b?.document.write('<html>');
    b?.document.write('<body>');
    b?.document.write(a);
    b?.document.write('</body> </html>');
    b?.document.close();
    b?.print();
  }

  const [absence, setAbsence] = React.useState(initialState);

  const getStudent = async () => {
    if (!decode(params.id)) {
      nav("/super-admin");
    } else {
      let response = await https.get(
        `/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_eleve_with_discipline/${decode(params.id)}`,{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
      }
      );
      if (response) {
        setEleve(response.data);
        switch(trimestre_now) {
          case '1' : 
            setAEncaisser(response.data[0].ecolage_total);
            break;
          case '2' : 
            setAEncaisser(response.data[0].ecolage_t2);
            break;
          case '3' : 
            setAEncaisser(response.data[0].ecolage_t3);
            break;
          case '4' : 
            setAEncaisser(response.data[0].ecolage_t4);
            break;
        }
        
      }
    }
  };

  const fetchAbsence = async () => {
    const abs = await https.get(
      `/absences/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/eleve/${decode(params.id)}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (abs) {
      setAbsences(abs.data);
    }
  };

  const fetchEcolage = async () => {
    const eco = await https.get(
      `/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/eleve/${decode(params.id)}/${trimestre_now}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (eco) {
      setEcolage(eco.data);
      let total = 0;
      eco.data.map((el: any) => {
        total += el.somme_paye;
      });
      setTotalApaye(total);
    }
  };

  const print = () => {
    window.print();
    
  };
    

  useEffect(() => {
    getStudent().catch((e) => console.log(e));
    fetchAbsence().catch((e) => console.log(e));
    fetchEcolage().catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setSommeRest(aEncaisser! - totalApaye!);
  }, [aEncaisser, totalApaye]);

  const open = (e: any, ID: string, id: number) => {
    e.preventDefault();
    let modal = document.querySelector("#" + ID) as Element;
    modal?.classList.add("open");
    setDelectedAbs(id);
  };

  const fermer = (id: string) => {
    let modal = document.querySelector("#" + id) as Element;
    modal?.classList.remove("open");
  };

  const deletedAbs = async (e: any, ID: string, id: number) => {
    e.preventDefault();
    if (id) {
      let resp = await https
        .delete(`/absences/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .catch((e) => {
          return e;
        });
      if (resp.status == 200) {
        fetchAbsence()
          .then(() => {
            fermer(ID);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const deleteEco = async (e: any, ID: string, id: number) => {
    e.preventDefault();
    if (id) {
      let resp = await https
        .delete(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .catch((e) => {
          return e;
        });
      if (resp.status == 200) {
        fetchEcolage()
          .then(() => {
            fermer(ID);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const handleChange = (e: any) => {
    setAbsence({
      ...absence,
      [e.target.name]: e.target.value,
    });
  };

  const handleAbsence = (e: any, id: string) => {
    e.preventDefault();
    if(absence.motifs != ""){
      https
        .post(`/absences/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
          ...absence,
          id_el_eleves: parseInt(decode(params.id)),
          id_niv_niveau: 1,
        },{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((res) => {
          if (res.status === 200) {
            fermer(id);
            fetchAbsence().catch((e) => console.log(e));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const registerPaiment = (e: any, id: string) => {
    e.preventDefault();
    if (sommeRest > 0) {
      if (
        date.current.value != "" &&
        somme_paye.current.value != "" &&
        somme_paye.current.value > 0
      ) {
        setISOk(false);
        let data = {
          date_paiement: new Date(date.current.value),
          somme_paye: parseInt(somme_paye.current.value),
          trimestre: parseInt(trimestre.current.value),
          id_el_eleves: parseInt(decode(params.id)),
          id_niv_niveau: 1,
        };
        https
          .post(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
          })
          .then((res) => {
            if (res.status === 200) {
              fermer(id);
              fetchEcolage().catch((e) => console.log(e));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setISOk(true);
    }
  };

  useEffect(() => {
    if(isModifEco){
      https
          .get(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${delectedAbs}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
          })
          .then((res) => {
            if (res.status === 200) {
              dateModif.current.value = fromatDate(res.data[0].date_paiement)
              trimestreModif.current.value = res.data[0].trimestre
              somme_payeModif.current.value = res.data[0].somme_paye
            }
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },[isModifEco])

  const handleModifEco = (e:any, modalId:string) => {
    e.preventDefault()
    let data = {
      date_paiement: dateModif.current.value,
      somme_paye: somme_payeModif.current.value,
      trimestre: trimestreModif.current.value,
      id_eco: delectedAbs
    }
    if (
      dateModif.current.value != "" &&
      somme_payeModif.current.value != "" &&
      somme_payeModif.current.value > 0 &&
      somme_payeModif.current.value <= aEncaisser!
    ) {
        https
          .put(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
          })
          .then((res) => {
            if (res.status === 200) {
              fetchEcolage().catch((e) => console.log(e));
              fermer(modalId)
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
  }

  useEffect(() => {
    getStudent().catch((e) => console.log(e))
    fetchEcolage().catch((e) => console.log(e))
  }, [trimestre_now])

  const prev = () => {
    if (index > 2) {
      setIndex2(index - 10);
      setIndex(index - 1);
    } else {
      setIndex(1);
      setIndex2(0);
    }
  };
  const next = () => {
    if (index < Math.round(absences.length / 10)) {
      setIndex(index + 1);
      setIndex2(index + 10);
    } else {
      setIndex(1);
      setIndex2(0);
    }
  };

  const current = (index: any) => {
    setIndex(index);
    setIndex2(index + 10);
  };

  const disabled = (status: boolean) => {
    if (status) {
      if (index > Math.round(absences.length / 10)) return " disabled";
    } else {
      if (index <= 1) return " disabled";
    }
    return "";
  };

  const registerQuitter = (e: any, id: string) => {
    e.preventDefault();

    try {
      https
        .post(`/eleves/quitter/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
          id_el: parseInt(decode(params.id)),
          motifs: motif_quitter.current.value,
          date_abondant: new Date(date_quitter.current.value).toISOString().slice(0, 19).replace('T', ' ')
        },{
          headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((res) => {
          if (res.status === 200) {
            if (localStorage.getItem("EGMUserType") === "super_admin") {
              nav(`/super-admin/liste-des-eleves-quitter`);
            } else if (localStorage.getItem("EGMUserType") === "admin") {
              nav(`/admin/liste-des-eleves-quitter`);
            }
            fermer(id);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const active = (indexnew: any, i: any) => {
    const active = index - i === indexnew ? " active" : "";
    return active;
  };

 
  return (
    //   <!-- Begin Page Content -->
    <div
      className="bodybar mt-0 pt-4"
      
    >
      <div className="container-fluid viewStudent ">
        {/* <!-- Page Heading --> */}
        <div className="row align-items-center  mb-4">
          <div className="col-md-9">
            <h1 className="h3 mb-0 ">A propos</h1>
          </div>
          <div className="col-md-3">
            <select id="" className="form-control" onChange={(e) => setTrimestre_now(e.target.value)}>
              <option value="1">Trimestre 1</option>
              <option value="2">Trimestre 2</option>
              <option value="3">Trimestre 3</option>
              <option value="4">Trimestre 4</option>
            </select>
          </div>
        </div>
        {/* begin */}
        <div className="row">
          {Eleve.map((el: any, index: any) => {
            return (
              <div className="col-xl-6 col-lg-6" key={index.toString()}>
                <div className="card shadow mb-4 pb-2">
                  <div className="card-header">
                    <div className="row p-2">
                      <div className="col-lg-6">
                        <h6 className="m-0 font-weight-bold text-primary">
                          {el.nom}
                        </h6>
                      </div>
                      <div className="col-lg-6"  style={{ textAlign: 'end'}}>
                        <a href="#" id="df2" onClick={print}>
                          <b>Imprimer</b>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body overlay ">
                    <table
                      className="gh "
                      id="for"
                      style={{
                        border: "5px solid #000",
                      }}
                    >
                    <tbody>
                      <tr>
                        <td>
                          <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src={logo}
                                  width="60px"
                                  height="60px"
                                  className="bu"
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-dark tf by"
                                  style={{
                                    background: "#4e73df",
                                    border: "1px solid #4e73df",
                                  }}
                                ></button>
                                <h4 className="tf">
                                  <strong>EGM Conservatoir de Music</strong>
                                </h4>
                                <h6 className="tr font-weight-bold">
                                  CARTE D'ETUDIANT
                                </h6>
                                <h6 className="jr">{el.annee_scolaire}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 style={{ textTransform: "capitalize" }}>
                                  {el.nom + " " + el.prenom} <br/>  ( {el.nom_centre} )
                                </h4>
                                <p>N° matricule: {el.matricule}</p>
                                <p>Né(e) le: {generateDate(el.date_nais)}</p>
                                <p>N CIN: {el.cin}</p>
                                <p style={{ textTransform: "capitalize" }}>
                                  Cours: {el.type_cycle == "cours" ? "Cours Accélérer" : el.type_cycle}
                                </p>
                                <p>Cycle: {el.cycle}</p>
                                <p>Ann&eacute;e: {el.annee}</p>
                                <p>Nationalité: {el.nationalite}</p>
                                <p>Tel: {el.telephone}</p>
                                <p>Instrument choisi: {el.nom_dis}</p>
                                <p>Email: {el.email}</p>
                              </td>
                              <td>
                                <br />
                                <br />
                                <br />
                                <img
                                  src={el.url_image || profil}
                                  style={{ borderRadius: "50%" }}
                                  width="140px"
                                  height="140px"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>
                                  <b>Dr Aintso RANAIVO</b>
                                </h6>
                                <br />
                              </td>
                              <td>
                                <h6>
                                  <b>Le Titulaire</b>
                                </h6>
                                <br /> 
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                    </table>
                    <br />
                    <div className="row">
                      <div className="col-lg-12">
                        {
                          localStorage.getItem("EGMUserType") == "super_admin" &&
                          <Link
                            to={"/modifier/eleves/"+ encode(el.id_el)}
                            className="btn  btn-sm btn-success"
                          >
                            <i className="fas fa-fw fa-pencil-alt"></i> Modifier
                          </Link>
                        }
                        <button
                          className="btn btn-sm btn-primary ml-3"
                          onClick={(e) =>
                            open(
                              e,
                              "paimentModal",
                              parseInt(decode(params.id)!)
                            )
                          }
                        >
                          <i className="fas fa-fw fa-bookmark"></i> Paiement
                        </button>
                        <Link
                          to={
                            auth_type == "super_admin"
                              ? "/super-admin/note-eleve/" + encode(el.id_el)
                              : "/admin/note-eleve/" + encode(el.id_el)
                          }
                          className="btn btn-sm btn-warning ml-3"
                        >
                          <i className="fas fa-fw fa-bookmark"></i> Notes
                        </Link>
                        <button
                          className="btn btn-sm btn-danger ml-3"
                          onClick={(e) =>
                            open(
                              e,
                              "quitterModal",
                              parseInt(decode(params.id)!)
                            )
                          }
                        >
                          <i className="fas fa-fw fa-bookmark"></i> Quitter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col-xl-6 col-lg-6">
            <div className="card shadow mb-2">

              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Fiche d'écolage
                </h6>
               <a href="#"  onClick={printDiv}>
                        <b>Imprimer</b>
                      </a> 
              </div>
    
                    <div className="card-body"  id="echo">

                      <table className="table "  style={{
                              border: "4px solid #000",
                            }} >
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={logo}
                              width="60px"
                              height="60px"
                              className="bu"
                              id="img"
                            />
                            <br />
                            <br />
                          </td>
                          <td className="text-start">
                            <h4 className="tj">
                              <strong>EGM Conservatoir de Music</strong>
                            </h4>
                            <h5 className="tr"> <strong>FICHE D'ECOLAGE</strong> Trimestre {trimestre_now}</h5>
                            <br />
                          </td>
                          <td ></td>
                        </tr>
                        <tr>
                          <td >
                          <h4 className="float-right"
                              style={{ color: "black"}}
                            >
                            <pre>  <b>PAIEMENT</b></pre>  
                            </h4>
                          </td>
                          <td ></td>
                        
                        </tr>
                        <tr>
                          <td align="center" id="date"><b>Date</b></td>
                          <td align="center"id="montant"><b>Montant</b></td>
                          
                        </tr>
                        {ecolage.map((el: any, idx: any) => {
                          return (
                            <tr key={idx}>
                              <td align="center" ><h6>{generateDate(el.date_paiement)}</h6></td>
                              <td align="center" ><h6>{el.somme_paye}Ar</h6></td>
                            </tr>
                          );
                        })}
                        <tr >
                          <td>
                            <h4 className="float-right"
                              style={{ color: "black", }}
                            >
                              <pre>  <b>RESUME</b></pre>  
                            </h4>
                          </td>
                          <td ></td>
                          
                        </tr>
                        <tr>
                          <td align="center">
                            <p>
                              <label
                                className="label  bg-danger p-1"
                                style={{
                                  borderRadius: "5px",
                                }}
                                id='encaiss'
                              >
                                A encaisser
                              </label>
                            </p>
                          </td>
                          <td align="center">
                            <p>
                              <label className="label mt-0"><h6>{aEncaisser}Ar</h6></label>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <p>
                              <label
                                className="label bg-success p-1"
                                style={{
                                  borderRadius: "5px",
                                }}
                                id='paye'
                              >
                                Somme Payée
                              </label>
                            </p>
                          </td>
                          <td align="center">
                            <p>
                              <label className="label mt-0"><h6>{totalApaye}Ar</h6></label>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <p>
                              <label
                                className="label bg-warning p-1"
                                style={{
                                  borderRadius: "5px",
                                }}
                                id='reste'
                              >
                                Somme restante
                              </label>
                            </p>
                          </td>
                          <td align="center">
                            <p>
                              <label className="label mt-0"><h6>{sommeRest}Ar</h6></label>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
            </div>
          </div>
        </div>
        {/* end */}
        {Eleve.map((el: any, index: any) => 
          <div className="row" key={index}>
              <div className="col-lg-12 mt-4">
                <h6 className="m-0 font-weight-bold text-primary">
                    Information Supplémentaire
                </h6>          
              </div>
              <div className="col-md-12 mt-3">
                <p>Date d'inscription: {generateDate(el.date_incription)} </p>
                <p>Date du premier cours: {generateDate(el.date_cours)} </p>
              </div>
          </div>
        )}
        {/* Begin */}
        {
          auth_type=="super_admin" &&
          <div className="row">
            <div className="col-lg-12 mb-5 mt-4">
              <div className="card shadow">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Liste des écolages
                  </h6>
                </div>
                <div className="widget-box">
                  <div className="widget-content nopadding">
                    <table className="table table-bordered table-responsive-xl table-striped">
                      <thead>
                        <tr>
                          <th>Date de paiement</th>
                          <th>Somme payer</th>
                          <th>Trimestre</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ecolage
                          .map((el: any) => (
                            <tr key={el.id_abs}>
                              <td>{generateDate(el.date_paiement)}</td>
                              <td>{el.somme_paye}</td>
                              <td>{el.trimestre}</td>
                              <td>
                                <button
                                  onClick={(e) =>{
                                      setIsModifEco(true)
                                      open(e, "paimentModifModal", el.id_eco)
                                    }
                                  }
                                  className="btn m-2 btn-sm btn-success"
                                >
                                  <i className="fas fa-fw fa-pencil-alt"></i>
                                </button>
                                <button
                                  onClick={(e) =>
                                    open(e, "deleteEcoModal", el.id_eco)
                                  }
                                  className="btn m-2 btn-sm btn-danger"
                                >
                                  <i className="fas fa-fw fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* end */}
        {/* Begin */}
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Liste des absences{" "}
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={(e) =>
                      open(e, "mymodal", parseInt(decode(params.id)!))
                    }
                  >
                    <i className="fas fa-fw fa-plus"></i> Ajouter
                  </button>
                </h6>
              </div>
              <div className="widget-box">
                <div className="widget-content nopadding">
                  <table className="table table-bordered table-responsive-xl table-striped">
                    <thead>
                      <tr>
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Heure début</th>
                        <th>Heure fin</th>
                        <th>Motifs</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {absences
                        .filter((value: any, i: any) => {
                          return value && i >= index2 && i < index * 10;
                        })
                        .map((el: any) => (
                          <tr key={el.id_abs}>
                            <td>{generateDate(el.date_deb)}</td>
                            <td>{generateDate(el.date_fin)}</td>
                            <td>{el.time_deb}</td>
                            <td>{el.time_fin}</td>
                            <td>{el.motifs}</td>
                            <td>
                              <button
                                onClick={(e) =>
                                  open(e, "logoutModal", el.id_abs)
                                }
                                className="btn btn-sm btn-danger"
                              >
                                <i className="fas fa-fw fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {absences.length > 10 && (
                  <ul className="pagination pagination-md d-flex justify-content-center">
                    <li
                      className={"page-item" + disabled(false)}
                      style={{ cursor: index <= 1 ? "no-drop" : "pointer" }}
                    >
                      <span className="page-link" onClick={prev}>
                        Précedent
                      </span>
                    </li>
                    {index > 1 && (
                      <li className="page-item" style={{ cursor: "no-drop" }}>
                        <span className="page-link">...</span>
                      </li>
                    )}
                    <li
                      className={"page-item" + active(index, 0)}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="page-link"
                        onClick={() => current(index)}
                      >
                        {index}
                      </span>
                    </li>
                    {index + 1 <= Math.round(absences.length / 10) && (
                      <li
                        className={"page-item" + active(index, 1)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => current(index + 1)}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )}
                    {index + 2 <= Math.round(absences.length / 10) && (
                      <li
                        className={"page-item" + active(index, 2)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => current(index + 2)}
                        >
                          {index + 2}
                        </span>
                      </li>
                    )}
                    {Math.round(absences.length / 10) > 2 &&
                      index < Math.round(absences.length / 10) && (
                        <li className="page-item" style={{ cursor: "no-drop" }}>
                          <span className="page-link">...</span>
                        </li>
                      )}
                    <li
                      className={"page-item" + disabled(true)}
                      style={{
                        cursor:
                          index >= Math.round(absences.length / 10)
                            ? "no-drop"
                            : "pointer",
                      }}
                    >
                      <span className="page-link" onClick={next}>
                        Suivant
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end */}
        {/* modals */}
        <div className="modal" id="logoutModal">
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{
                width: "100%",
                marginTop: "150px",
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Attention!
                </h5>
              </div>
              <div
                className="modal-body"
                style={{
                  padding: "20px",
                }}
              >
                Voulez vous vraiment supprimer cette absence ?{" "} 
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary btn-sm"
                  type="button"
                  onClick={() => fermer("logoutModal")}
                  data-dismiss="modal"
                >
                  Anuller
                </button>
                <a
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deletedAbs(e, "logoutModal", delectedAbs)}
                  href=""
                >
                  Supprimer
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="deleteEcoModal">
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{
                width: "100%",
                marginTop: "150px",
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Attention!
                </h5>
              </div>
              <div
                className="modal-body"
                style={{
                  padding: "20px",
                }}
              >
                Voulez vous vraiment supprimer cette écolage ?{" "}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary btn-sm"
                  type="button"
                  onClick={() => fermer("deleteEcoModal")}
                  data-dismiss="modal"
                >
                  Anuller
                </button>
                <a
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deleteEco(e, "deleteEcoModal", delectedAbs)}
                  href=""
                >
                  Supprimer
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="mymodal" className="modal">
          <form
            className="modal-content animate"
            action=""
            method="post"
            style={{
              marginTop: "70px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Absences</strong>
              </h5>
            </div>
            <div className="imgcontainer">
              <span
                onClick={() => fermer("mymodal")}
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="container">
                <label>De</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-6">
                    <input
                      type="date"
                      onChange={handleChange}
                      value={absence.date_deb}
                      className="form-control"
                      name="date_deb"
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="time"
                      onChange={handleChange}
                      value={absence.time_deb}
                      className="form-control"
                      name="time_deb"
                    />
                  </div>
                </div>
                <label style={{ marginTop: "5px" }}>A</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-6">
                    <input
                      type="date"
                      onChange={handleChange}
                      value={absence.date_fin}
                      className="form-control"
                      name="date_fin"
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="time"
                      onChange={handleChange}
                      value={absence.time_fin}
                      className="form-control"
                      name="time_fin"
                    />
                  </div>
                </div>

                <label style={{ marginTop: "5px" }}>Motif</label>
                <textarea
                  rows={4}
                  style={{ marginTop: "5px" }}
                  onChange={handleChange}
                  value={absence.motifs}
                  className="form-control"
                  name="motifs"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => handleAbsence(e, "mymodal")}
                className="btn btn-sm btn-primary"
                type="submit"
              >
                <i className="fas  fa-save"></i> Enregistrer
              </button>
            </div>
          </form>
        </div>

        <div id="paimentModal" className="modal">
          <form
            className="modal-content animate"
            action=""
            method="post"
            style={{
              marginTop: "70px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Paiement</strong>
              </h5>
            </div>
            <div className="imgcontainer">
              <span
                onClick={() => {
                  fermer("paimentModal")
                  setISOk(false)
                }}
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="container">
                <label>Date de paiement</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <input
                      type="date"
                      ref={date}
                      className="form-control"
                      name="date_paiement"
                    />
                  </div>
                </div>

                <label className="mt-3">Trimestre</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <select id="" className="form-control" ref={trimestre}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>

                <label className="mt-3">Somme à payer</label>
                <input
                  type="number"
                  style={{ marginTop: "5px" }}
                  ref={somme_paye}
                  className="form-control"
                  name="somme_paye"
                />
                <br />
                {isOk &&
                  <div className="alert alert-success" role="alert">
                    Cet élève a déjà payé tout ses frais de scolarité.
                  </div>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => registerPaiment(e, "paimentModal")}
                className="btn btn-sm btn-primary"
                type="submit"
              >
                <i className="fas  fa-save"></i> Enregistrer
              </button>
            </div>
          </form>
        </div>

        {/* begin */}
        <div id="paimentModifModal" className="modal">
          <form
            className="modal-content animate"
            action=""
            method="post"
            style={{
              marginTop: "70px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Modifier Paiement</strong>
              </h5>
            </div>
            <div className="imgcontainer">
              <span
                onClick={() => {
                  setIsModifEco(false)
                  setISOk(false)
                  fermer("paimentModifModal")
                }}
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="container">
                <label>Date de paiement</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <input
                      type="date"
                      ref={dateModif}
                      className="form-control"
                      name="date_paiement"
                    />
                  </div>
                </div>

                <label className="mt-3">Trimestre</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <select id="" className="form-control" ref={trimestreModif}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>

                <label className="mt-3">Somme à payer</label>
                <input
                  type="number"
                  style={{ marginTop: "5px" }}
                  ref={somme_payeModif}
                  className="form-control"
                  name="somme_paye"
                />
                <br />
                {isOk &&
                  <div className="alert alert-success" role="alert">
                    Cet élève a déjà payé tout ses frais de scolarité.
                  </div>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => {
                  setIsModifEco(false)
                  handleModifEco(e, "paimentModifModal")
                }}
                className="btn btn-sm btn-success"
              >
                <i className="fas  fa-save"></i> Modifier
              </button>
            </div>
          </form>
        </div>
        {/* end */}

        <div id="quitterModal" className="modal">
          <form
            className="modal-content animate"
            action=""
            method="post"
            style={{
              marginTop: "70px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Cet élève a quitté le centre ?</strong>
              </h5>
            </div>
            <div className="imgcontainer">
              <span
                onClick={() => fermer("quitterModal")}
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="container">
                <label>Date</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <input
                      type="date"
                      style={{ marginTop: "5px" }}
                      ref={date_quitter}
                      className="form-control"
                      name="motifs"
                    />
                  </div>
                </div>
                <label className="mt-3">Motifs</label>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                    <textarea
                      rows={4}
                      style={{ marginTop: "5px" }}
                      ref={motif_quitter}
                      className="form-control"
                      name="motifs"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => registerQuitter(e, "quitterModal")}
                className="btn btn-sm btn-danger"
                type="submit"
              >
                <i className="fas  fa-save"></i> Valider
              </button>
            </div>
          </form>
        </div>
        {/* end */}
      </div>
    </div>
  );
};

export default ViewStudent;
