import React, { useContext, useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useLocation,
} from "react-router-dom";
import Login from "../views/Login";
// Sidebar for the two of admin
import SuperSideBar from "../views/superAdmin/SideBar";
import AdminSideBar from "../views/Admin/SideBar";
// views for super-admin
import SuperDashboard from "../views/superAdmin/Dashboard";
import Profile from "../views/superAdmin/Profile";
import ViewStudent from "../views/superAdmin/studentsComponents/ViewStudent";
import Students from "../views/superAdmin/Students";
import Disciplines from "../views/superAdmin/Disciplines";
import Rapports from "../views/superAdmin/Rapports";
import WeeklyReports from "../views/superAdmin/reportsComponents/weeklyReports";
import MonthlyReports from "../views/superAdmin/reportsComponents/monthlyReports";
import ChooseReports from "../views/superAdmin/reportsComponents/chooseReports";
import Recipe from "../views/superAdmin/Recipe";
import AllRecipe from "../views/superAdmin/recipeComponents/allRecipe";
import AddProffesor from "../views/superAdmin/supevisorComponents/addProffesor";
import AdminList from "../views/superAdmin/supevisorComponents/adminList";
import AddAdmin from "../views/superAdmin/supevisorComponents/addAdmin";
// views for admin
import AdminDashboard from "../views/Admin/Dashboard";
import AdminProfiles from "../views/Admin/Profiles";
import AdminStudents from "../views/Admin/Students";
import AdminReports from "../views/Admin/Reports";
import AdminRecipe from "../views/Admin/Recipe";
// global views
import Inscription from "../views/Global/Inscription";
import PrivateRoute from "./Protected";
import UserContext from "../views/services/UserContext";
import Professors from "../views/superAdmin/Professors";
import Note from "../views/superAdmin/studentsComponents/Notes";
import MarkAverage from "../views/superAdmin/studentsComponents/MarkAverage";
import QuitStudents from "../views/Global/quitStudents";
import ViewProf from "../views/Global/viewProf";

import NotFound from "../views/NotFound";
import Statistique from "../views/superAdmin/Statistique";
import StatistiqueAdmin from "../views/Admin/Statistique";
import Inventaires from "../views/superAdmin/Inventaires";
import Tout from "../views/superAdmin/Tout";
import InventairesAdmin from "../views/Admin/Inventaires";

import Email from "../views/Admin/Email"
import CreateSuperAdmin from "../views/CreateSuperAdmin";
import FollowPayment from "../views/Global/FollowPayment/FollowPayment";

const Router = () => {
  const [currentUser, setCurrentUser] = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* <Route path="/create/t/5438649489486709/super-admin/1065616784738242561" element={<CreateSuperAdmin />} /> */}
      <Route
        path="/super-admin"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin"
          element={<SuperSideBar view={<SuperDashboard />} />}
        />
      </Route>
      <Route
        path="/super-admin/suivi-ecolage"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/suivi-ecolage"
          element={<SuperSideBar view={<FollowPayment />} />}
        />
      </Route>
      <Route
        path="/super-admin/Profile"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/Profile"
          element={<SuperSideBar view={<Profile />} />}
        />
      </Route>
      <Route
        path="/super-admin/statistique"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/statistique"
          element={<SuperSideBar view={<Statistique />} />}
        />
      </Route>
      <Route
        path="/super-admin/inventaires"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/inventaires"
          element={<SuperSideBar view={<Inventaires />} />}
        />
      </Route>

      <Route
        path="/super-admin/inventaire-centre/:id_centre"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/inventaire-centre/:id_centre"
          element={<SuperSideBar view={<Tout/>} />}
        />
      </Route>
      <Route
        path="/super-admin/inscription"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/inscription"
          element={<SuperSideBar view={<Inscription />} />}
        />
      </Route>
      <Route
        path="/modifier/eleves/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/modifier/eleves/:id"
          element={<SuperSideBar view={<Inscription />} />}
        />
      </Route>
      <Route
        path="/super-admin/a-propos/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/a-propos/:id"
          element={<SuperSideBar view={<ViewStudent />} />}
        />
      </Route>
      <Route
        path="/super-admin/liste-des-eleves"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/liste-des-eleves"
          element={<SuperSideBar view={<Students />} />}
        />
      </Route>
      <Route
        path="/super-admin/liste-des-eleves-quitter"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/liste-des-eleves-quitter"
          element={<SuperSideBar view={<QuitStudents />} />}
        />
      </Route>
      <Route
        path="/super-admin/liste-des-professeurs"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/liste-des-professeurs"
          element={<SuperSideBar view={<Professors />} />}
        />
      </Route>
      <Route
        path="/super-admin/voir-prof/:id_resp"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/voir-prof/:id_resp"
          element={<SuperSideBar view={<ViewProf />} />}
        />
      </Route>
      <Route
        path="/super-admin/liste-des-disciplines"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/liste-des-disciplines"
          element={<SuperSideBar view={<Disciplines />} />}
        />
      </Route>
      <Route
        path="/super-admin/rapports"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/rapports"
          element={<SuperSideBar view={<Rapports />} />}
        />
      </Route>
      <Route
        path="/super-admin/type-rapport/:centreId"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/type-rapport/:centreId"
          element={<SuperSideBar view={<ChooseReports />} />}
        />
      </Route>
      <Route
        path="/super-admin/rapport-hebdomadaire/:centreId"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/rapport-hebdomadaire/:centreId"
          element={<SuperSideBar view={<WeeklyReports />} />}
        />
      </Route>
      <Route
        path="/super-admin/rapport-mensuel/:centreId"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/rapport-mensuel/:centreId"
          element={<SuperSideBar view={<MonthlyReports />} />}
        />
      </Route>
      <Route
        path="/super-admin/recette"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/recette"
          element={<SuperSideBar view={<Recipe />} />}
        />
      </Route>
      <Route
        path="/super-admin/voir-recette/:idRecipe"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/voir-recette/:idRecipe"
          element={<SuperSideBar view={<AllRecipe />} />}
        />
      </Route>
      <Route
        path="/super-admin/ajout-prof"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/ajout-prof"
          element={<SuperSideBar view={<AddProffesor />} />}
        />
      </Route>
      <Route
        path="/super-admin/modify-prof/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/modify-prof/:id"
          element={<SuperSideBar view={<AddProffesor />} />}
        />
      </Route>
      <Route
        path="/super-admin/modify-admin/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/modify-admin/:id"
          element={<SuperSideBar view={<AddAdmin />} />}
        />
      </Route>
      <Route
        path="/super-admin/note-eleve/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/note-eleve/:id"
          element={<SuperSideBar view={<Note />} />}
        />
      </Route>
      <Route
        path="/super-admin/moyenne"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/moyenne"
          element={<SuperSideBar view={<MarkAverage />} />}
        />
      </Route>
      <Route
        path="/super-admin/liste-admin"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/liste-admin"
          element={<SuperSideBar view={<AdminList />} />}
        />
      </Route>
      <Route
        path="/super-admin/ajouter-admin"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/ajouter-admin"
          element={<SuperSideBar view={<AddAdmin />} />}
        />
      </Route>
      <Route
        path="/super-admin/email"
        element={
          <PrivateRoute
            isUser={currentUser === "super_admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/super-admin/email"
          element={<SuperSideBar view={<Email />} />}
        />
      </Route>
      {/* Admin */}
      <Route
        path="/admin/suivi-ecolage"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/suivi-ecolage"
          element={<AdminSideBar view={<FollowPayment />} />}
        />
      </Route>
      <Route
        path="/admin"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin"
          element={<AdminSideBar view={<AdminDashboard />} />}
        />
      </Route>
      <Route
        path="/admin/ajout-prof"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/ajout-prof"
          element={<AdminSideBar view={<AddProffesor />} />}
        />
      </Route>
      <Route
        path="/admin/statistique"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/statistique"
          element={<AdminSideBar view={<StatistiqueAdmin />} />}
        />
      </Route>
      <Route
        path="/admin/inventaires"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/inventaires"
          element={<AdminSideBar view={<InventairesAdmin />} />}
        />
      </Route>
      <Route
        path="/admin/profiles"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/profiles"
          element={<AdminSideBar view={<AdminProfiles />} />}
        />
      </Route>

      <Route
        path="/admin/inscription"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/inscription"
          element={<AdminSideBar view={<Inscription />} />}
        />
      </Route>
      <Route
        path="/admin/a-propos/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/a-propos/:id"
          element={<AdminSideBar view={<ViewStudent />} />}
        />
      </Route>
      <Route
        path="/admin/liste-des-eleves"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/liste-des-eleves"
          element={<AdminSideBar view={<AdminStudents />} />}
        />
      </Route>
      <Route
        path="/admin/liste-des-professeurs"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/liste-des-professeurs"
          element={<AdminSideBar view={<Professors />} />}
        />
      </Route>
      <Route
        path="/admin/voir-prof/:id_resp"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/voir-prof/:id_resp"
          element={<AdminSideBar view={<ViewProf />} />}
        />
      </Route>
      <Route
        path="/admin/rapports"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/rapports"
          element={<AdminSideBar view={<AdminReports />} />}
        />
      </Route>
      <Route
        path="/admin/recette"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/recette"
          element={<AdminSideBar view={<AdminRecipe />} />}
        />
      </Route>
      <Route
        path="/admin/liste-des-eleves-quitter"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/liste-des-eleves-quitter"
          element={<AdminSideBar view={<QuitStudents />} />}
        />
      </Route>
      <Route
        path="/admin/note-eleve/:id"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/note-eleve/:id"
          element={<AdminSideBar view={<Note />} />}
        />
      </Route>
      
      <Route
        path="/admin/moyenne"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/moyenne"
          element={<AdminSideBar view={<MarkAverage />} />}
        />
      </Route>

      <Route
        path="/admin/email"
        element={
          <PrivateRoute
            isUser={currentUser === "admin" ? true : false}
            path="/"
          />
        }
      >
        <Route
          path="/admin/email"
          element={<AdminSideBar view={<Email />} />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Router;
