import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { decode } from '../../services/slugs';
import https from '../../helpers/https';
import ReportList from './reportList';
import { getHeader } from '../../helpers/getHeader';

const WeeklyReports = () => {
    const centreId = useParams<string>();
    const [reportList, setReportList] = useState([] as any);
    const [search, setSearch] = useState<any>("");

    const fetchRapport = async (search:string)=>{
        if(search == ""){
            const report = await https.get(`/rapports/type_centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/Hebdomadaire/${decode(centreId.centreId)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(report){
                setReportList(report.data)
            }
        }
        else{
            const report = await https.get(`/rapports/type_centres/date/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/Hebdomadaire/${decode(centreId.centreId)}/${search}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(report){
                // console.log(report)
                setReportList(report.data)
            }
        }
    }

    React.useEffect(()=>{
        fetchRapport("").catch((e:any)=> console.log(e))
    },[])

    React.useEffect(()=>{
        fetchRapport(search).catch((e:any)=> console.log(e))
    },[search])

    return (
        <div className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Rapport hebdomadaire</h1>
                        <Link to={`/super-admin/type-rapport/${centreId.centreId}`} className="btn btn-sm btn-success "><i className="fas fa-fw fa-reply"></i> Retour</Link>
                </div>
               <div className="row">
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col-md-8 mt-2">
                                        <p> Recherche </p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="date"  onChange={(e:any)=> setSearch(e.target.value)} className="form-control"/>
                                    </div>
                                </div>
                            </div>
                           <ReportList data={reportList} seter={setReportList} type={"Hebdomadaire"}/>
                        </div>
                    </div>
            </div>
        </div>
    );
}
export default WeeklyReports;