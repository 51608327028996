import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getHeader } from '../helpers/getHeader';
import https from '../helpers/https';
import { encode } from '../services/slugs';

const Rapports = () => {
    const [allCentre, setallCentre] = useState([] as any)
    const [isLoading, setIsLoading] = useState(true)
    
    
    const fetchCentre = async ()=>{
        try {
            const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(centers){
                    setallCentre(centers.data);
                    setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
        
    }
    useEffect(()=>{
        fetchCentre()
            .catch(error => console.log(error));
    },[])
    return (
        <div  className='container-fluid bodybar mt-0 pt-0' style={{
            height:window.innerHeight-70
        }}>

                {
                    isLoading && <div className="alert text-center alert-loading mb-4 mt-5" role="alert">
                        Chargement...
                    </div>
                }
               <div className="row mt-4">
                        {
                            allCentre.map((element:any,index:any)=>(
                                <Link key={index.toString()} to={`/super-admin/type-rapport/${encode(element.id_centre)}`} className="col-xl-6 col-md-6 mb-4" >
                                    <div className="card border-left-primary shadow h-100 py-2 overlay"id="about">
                                        <div className="card-body ">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        rapports
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{element.nom_centre}</div>
                                                </div>
                                                
                                                <div className="col-auto">
                                                    <i className="fas fa-users fa-2x text-gray-300"></i>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                </div>
                {/* end of container fluid */}
        </div>
    );
}
export default Rapports;