import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import profil from "../img/undraw_profile.svg";
import logo from "../img/logo.jpg";
import egm from "../img/egm.jpg";
import https from '../helpers/https';
import profile from "../img/undraw_profile.svg";
import { getHeader } from "../helpers/getHeader";

interface props {
  view: any;
}
  
const SideBar = ({ view }: props) => {

  const initialAdmin = {
    url_image: profile,
    nom_resp: "",
    prenom_resp: "",
    email_resp: "",
    adresse_resp: "",
    telephone_resp: 0,
}
  const [resp, setprofile] = useState([initialAdmin] as any);
  const fetchProfil = async() =>{
    const profile = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/resp/${localStorage.getItem('EGMUserId')}`,{
      headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
  })
    if(profile){
      setprofile(profile.data);
  }
  }
  useEffect(() =>{
    fetchProfil()
    .catch((e) => console.log(e))
    
  },[])
  const [height, setHeigth] = useState(0);
  const route = useLocation();
  const nav = useNavigate();
  const [userEmail, setUserEmail] = useState<string | null>(localStorage.getItem("EGMUserEmail"));
  const [userName, setUserName] = useState<string | null>(localStorage.getItem("EGMUserName"));

  function Disconnect() {
    localStorage.clear();
    nav("/");
  }
  const setActive = (path: string) => {
    if (route.pathname === path) {
      return " active";
    } else {
      return "";
    }
  };
  useEffect(() => {
    if (document && typeof document !== "undefined") {
      const rect = document?.querySelector("#navtop") as HTMLElement;
      const height = rect?.offsetHeight as unknown as number;
      setHeigth(height + 50);
    }
  });

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          {/*Siderbar */}
          <ul
            className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
            style={{
              height: window.innerHeight,
            }}
            id="accordionSidebar"
          >
            <Link
              to="/super-admin"
              className="sidebar-brand d-flex align-items-center justify-content-center"
            >
              <div className="sidebar-brand-img rotate-n-10">
                <img className="th" src={logo} />
              </div>
              <div className="sidebar-brand-text mx-3">EGM Super-Admin</div>
            </Link>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
              <Link to="/super-admin" className="nav-link">
                <i className="fas fa-fw fa-desktop"></i>
                <span>EGM-GESTION-ECOLE</span>
              </Link>
            </li>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading text-white">Menu</div>
            <ul className="navbar-nav bodybar">
              <li className={"nav-item" + setActive("/super-admin")}>
                <Link to="/super-admin" className="nav-link">
                  <i className="fas fa-fw fa-qrcode"></i>
                  <span>Tableau de bord</span>
                </Link>
              </li>
              <li
                className={"nav-item" + setActive("/super-admin/statistique")}
              >
                <Link to="/super-admin/statistique" className="nav-link">
                  <i className="fas fa-fw fa-qrcode"></i>
                  <span>Statistique</span>
                </Link>
              </li>
              <li
                className={"nav-item" + setActive("/super-admin/inventaires")}
              >
                <Link to="/super-admin/inventaires" className="nav-link">
                  <i className="fas fa-fw fa-qrcode"></i>
                  <span>Inventaires</span>
                </Link>
              </li>
              <li
                className={"nav-item" + setActive("/super-admin/inscription")}
              >
                <Link to="/super-admin/inscription" className="nav-link">
                  <i className="fas fa-fw fa-pencil-alt"></i>
                  <span>Inscription</span>
                </Link>
              </li>
              <li
                className={"nav-item" + setActive("/super-admin/suivi-ecolage")}
              >
                <Link to="/super-admin/suivi-ecolage" className="nav-link">
                  <i className="fas fa-fw fa-eye"></i>
                  <span>Suivi écolage</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item" + setActive("/super-admin/liste-des-eleves")
                }
              >
                <Link to="/super-admin/liste-des-eleves" className="nav-link">
                  <i className="fas fa-fw fa-users"></i>
                  <span>Elèves</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item" + setActive("/super-admin/liste-des-professeurs")
                }
              >
                <Link
                  to="/super-admin/liste-des-professeurs"
                  className="nav-link"
                >
                  <i className="fas fa-fw fa-user"></i>
                  <span>Professeurs</span>
                </Link>
              </li>
              <li
                className={"nav-item" + setActive("/super-admin/liste-admin")}
              >
                <Link to="/super-admin/liste-admin" className="nav-link">
                  <i className="fas fa-fw fa-user"></i>
                  <span>Liste des admins</span>
                </Link>
              </li>
              <li
                className={
                  "nav-item" + setActive("/super-admin/liste-des-disciplines")
                }
              >
                <Link
                  to="/super-admin/liste-des-disciplines"
                  className="nav-link"
                >
                  <i className="fas fa-fw fa-pencil-alt"></i>
                  <span>Disciplines</span>
                </Link>
              </li>
              <li className={"nav-item" + setActive("/super-admin/moyenne")}>
                <Link to="/super-admin/moyenne" className="nav-link">
                  <i className="fas fa-fw fa-pencil-alt"></i>
                  <span>Moyenne des &eacute;l&egrave;ves</span>
                </Link>
              </li>
              <li className={"nav-item" + setActive("/super-admin/rapports")}>
                <Link to="/super-admin/rapports" className="nav-link">
                  <i className="fas fa-fw fa-flag"></i>
                  <span>Rapports</span>
                </Link>
              </li>
              <li className={"nav-item" + setActive("/super-admin/recette")}>
                <Link to="/super-admin/recette" className="nav-link">
                  <i className="fas fa-fw fa-folder"></i>
                  <span>Recettes</span>
                </Link>
              </li>
              <li className={"nav-item" + setActive("/super-admin/liste-des-eleves-quitter")}>
                <Link to="/super-admin/liste-des-eleves-quitter" className="nav-link">
                  <i className="fas fa-fw fa-user"></i>
                  <span>Elèves quitt&eacute;s</span>
                </Link>
              </li>
              <li className={"nav-item"+setActive('/super-admin/email')}>
                <Link to="/super-admin/email" className="nav-link">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>E-mail global</span>
                </Link>
              </li>
            </ul>

            <hr className="sidebar-divider d-none d-md-block" />
          </ul>
          {/*End of sidebar */}
          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              {/* <!-- Topbar --> */}
              <nav
                className="navbar navbar-expand navbar-light bg-white topbar mb-0 static-top shadow"
                id="navtop"
              >
                {/* <!-- Sidebar Toggle (Topbar) --> */}
                {/* <!-- Topbar Search --> */}
                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-0 my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    <div className="input-group-append">
                      <img src={egm} width="85px" height="auto" />
                    </div>
                  </div>
                </form>
                <ul className="navbar-nav ml-auto">
                  {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                  <li className="nav-item dropdown no-arrow mx-1 mt-4">
                    <p
                      className="text-gray-700"
                      style={{
                        textTransform: !userEmail ? "capitalize" : "lowercase",
                      }}
                    >
                      {userEmail && userEmail !== "null" ? userEmail : userName}
                    </p>
                  </li>

                  <div className="topbar-divider d-none d-sm-block"></div>
                  <li
                    className="nav-item dropdown no-arrow"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small"></span>
                      <img
                        className="img-profile rounded-circle"
                        src={resp[0].url_image == null ? profile :resp[0].url_image }
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown"
                    >
                      <p className="p-2 text-gray-900">{userName}</p>
                      <div className="dropdown-divider"></div>
                      <p>
                        <Link
                          to="/super-admin/Profile"
                          className="dropdown-item"
                        >
                          <i className="fas fa-fw fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </Link>
                      </p>
                      <p
                        className="dropdown-item"
                        onClick={Disconnect}
                        data-toggle="modal"
                        data-target="#logoutModal"
                      >
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Deconnecter
                      </p>
                    </div>
                  </li>
                </ul>
              </nav>
              {/* Body */}
              <main
                style={{
                  height: window.innerHeight - height,
                }}
              >
                {view}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
