import React, { useEffect } from 'react';
import { generateDate } from "../../services/date/date";
import { useParams } from 'react-router-dom';
import { decode } from '../../services/slugs';
import https from '../../helpers/https';
import { getCookie } from '../../services/cookies/dataCookies';
import { getHeader } from '../../helpers/getHeader';

interface props {
    data: any,
    seter: any,
    type : string
}
const ReportList = ({data, seter, type}:props) => {
    const centreId = useParams<string>();
    const [rId, setRid] = React.useState<number>(0)
    const [reportData, setReportData] = React.useState<any>([{
        "nouv_inscrit": 0,
        "total_inscrit": 0,
        "eleve_actif": 0,
        "eleve_en_regle": 0,
        "pourcentage_recouv": 0,
        "frequentation": 0,
        "quitter": 0,
        "date_envoie": 0,
    }])
    const nouvInsc = React.useRef<any>()
    const totalInscrit= React.useRef<any>()
    const eleveActif= React.useRef<any>()
    const eleveEnregle= React.useRef<any>()
    const pourcentageRecouv= React.useRef<any>()
    const frequentation= React.useRef<any>()
    const quitter= React.useRef<any>()
    const dateEnvoie= React.useRef<any>()


    const fetchReport = async (reportId : number)=>{
        const rep = await https.get(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${reportId}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(rep){
            setReportData(rep.data);
        }
    }

    const open = (id:string,reportId:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setRid(reportId)
        fetchReport(reportId)
            .then(()=>  {
                // console.log(reportData)
            })
            .catch((e:any)=> console.log(e))
    }

    useEffect(()=>{
        nouvInsc.current.value =reportData[0].nouv_inscrit
        totalInscrit.current.value = reportData[0].total_inscrit
        eleveActif.current.value = reportData[0].eleve_actif
        eleveEnregle.current.value =reportData[0].eleve_en_regle
        pourcentageRecouv.current.value = reportData[0].pourcentage_recouv
        frequentation.current.value = reportData[0].frequentation
        quitter.current.value = reportData[0].quitter
    },[reportData])

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    const deleteReport = async (e :any, id :any) => {
        e.preventDefault()
        try{
            const report = await https.delete(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${rId}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(report){
                fetchRapport().catch((e:any)=> console.log(e))
                fermer(id)
            }
        }
        catch(e:any){
            console.log(e)
        }
    }

    const fetchRapport = async ()=>{
        try{
            const report = await https.get(`/rapports/type_centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${type}/${decode(centreId.centreId)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(report){
                seter(report.data)
            }
        }
        catch(e:any){
            console.log(e)
        }
    }

    const modifyReport = async (e:any, id:string) => {
        e.preventDefault()
        data = {
            "id_rapport": rId,
            "type_rapport": reportData[0].type_rapport,
            "id_centre_centres": localStorage.getItem("EGMUserCentre"),
            "type_cours": reportData[0].type_cours,
            "nouv_inscrit": nouvInsc.current.value,
            "total_inscrit": totalInscrit.current.value,
            "eleve_actif": eleveActif.current.value,
            "eleve_en_regle": eleveEnregle.current.value,
            "pourcentage_recouv": pourcentageRecouv.current.value,
            "frequentation": frequentation.current.value,
            "quitter": quitter.current.value,
            "date_envoie": reportData[0].date_envoie,
        }
        const report = await https.put(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(report){
            fetchRapport().catch((e:any)=> console.log(e))
            fermer(id)
        }

    }
    return(
        <div className="widget-box">
            <div className="widget-content nopadding">
                <table className="table table-bordered table-responsive-xl table-responsive" width="100%">
                    <thead>
                        <tr>
                            <th>Formation</th>
                            <th>Nouvelles Inscriptions</th>
                            <th>Total des inscrits</th>
                            <th>Nombre d'&eacute;lèves quitt&Eacute;s</th>
                            <th>Nombre d'élèves actifs</th>
                            <th>Elèves en r&egrave;gle sur les frais</th>
                            <th>Pourcentage de recouvrement</th>
                            <th>Fr&eacute;quentation</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((el:any, idx:number) => {
                                return(
                                    <tr key={idx}>
                                        <td style={{textTransform:"capitalize"}}>{el.type_cours}</td>
                                        <td>{el.nouv_inscrit}</td>
                                        <td>{el.total_inscrit}</td>
                                        <td>{el.quitter}</td>
                                        <td>{el.eleve_actif}</td>
                                        <td>{el.eleve_en_regle}</td>
                                        <td>{el.pourcentage_recouv}</td>
                                        <td>{el.frequentation}</td>
                                        <td>{generateDate(el.date_envoie)}</td>
                                        <td className='pt-0 pb-0 d-flex'>
                                            <button  onClick={e => open('modifierModal',el.id_rapport)}  className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></button>
                                            <button onClick={e => open('logoutModal',el.id_rapport)} className="btn btn-sm m-2 btn-danger"><i className="fas fa-fw fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* Modals */}
            <div className="modal" id="logoutModal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{
                        width: '100%',
                        marginTop:'150px',
                    }} >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                            
                        </div>
                        <div className="modal-body" style={{
                            padding:'20px'
                        }}>Voulez vous vraiment supprimer cet rapport ?</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                            <a className="btn btn-danger btn-sm" href="" onClick={(e) => deleteReport(e,"logoutModal")}>Supprimer</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modifierModal" className="modal">
                <form className="modal-content animate"
                action='' method="post" style={{
                    marginTop:'70px'
                }}>
                <div className="modal-header">
                    <h5 className="modal-title"><strong>Modifier rapport </strong></h5>
                </div>
                <div className="imgcontainer">
                    <span onClick={() => fermer('modifierModal')} className="close" title="Close Modal">
                        &times;</span>
                </div>
                <div className="modal-body">
                <div className="container">
                <div className="row" style={{ marginTop: "5px"}}>
                    <div className="col-lg-6">
                <label>Nouvelles Inscriptions</label>
                    <input type="number" ref={nouvInsc}  className="form-control" name="nouv_inscrit"/>
                        </div>
                        <div className="col-lg-6">
                <label>Total des inscrits</label>
                    <input type="number" ref={totalInscrit} className="form-control" name="total_inscrit"/>
                        </div>
                </div>
                <div className="row" style={{ marginTop: "5px"}}>
                    <div className="col-lg-6">
                <label>Nombre d'eleves actifs</label>
                    <input type="number" ref={eleveActif} className="form-control" name="eleve_actif"/>
                        </div>
                        <div className="col-lg-6">
                <label>Eleves en règle sur les frais</label>
                    <input type="number" ref={eleveEnregle} className="form-control" name="eleve_en_regle"/>
                        </div>
                </div>
                <div className="row" style={{ marginTop: "5px"}}>
                    <div className="col-lg-6">
                <label>Pourcentage de recouvrement	</label>
                    <input type="number" ref={pourcentageRecouv}  className="form-control" name="pourcentage_recouv"/>
                        </div>
                        <div className="col-lg-6">
                <label>Fréquentation</label>
                    <input type="number" ref={frequentation}  className="form-control" name="frequentation"/>
                        </div>
                </div>
                <div className="row" style={{ marginTop: "5px"}}>
                    <div className="col-lg-6">
                <label>Nombre d'éleves quitt&eacute;</label>
                    <input type="number" ref={quitter} className="form-control" name="quitter"/>
                        </div>
                        <div className="col-lg-6">
                <label>Date</label>
                    <input type="text" readOnly value={generateDate(reportData[0].date_envoie)}  className="form-control" name="date_envoie"/>
                        </div>
                </div>
                
                    </div>
                </div>
                <div className="modal-footer">
                <button onClick={(e)=>modifyReport(e,'modifierModal')} className="btn btn-sm btn-primary" type="submit"><i className="fas  fa-save"></i> Enregistrer</button>
                </div>
                </form>
        </div>
            </div>
    );
}
export default ReportList;