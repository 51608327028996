import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
  } from 'chart.js';

import { Doughnut, Pie,Line, Bar } from 'react-chartjs-2';
import ChooseCenter from './studentsComponents/chooseCenter';
import https from '../helpers/https';
import { getHeader } from '../helpers/getHeader';

ChartJS.register(
    ArcElement, 
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export default function Statistique() {

    const [centerFilter, setCenterFilter] = useState('*');
    const [allCentre, setallCentre] = useState([] as any);
    const [allDisciplines, setallDisciplines] = useState([] as any);
    const [colors, setColors] = useState([] as any);
    const [colorsDis, setColorsDis] = useState([] as any);
    const [allStudents,setAllStudents] = useState([] as any)
    const [studentsCount,setStudentsCount] = useState([] as any)
    const [disciplineCount,setDisciplineCount] = useState([] as any)
    const [statusData, setStatusData] = useState([] as any)
    const [isLoading, setIsLoading] = useState(false)
    
    const fetchCentre = async ()=>{
        setIsLoading(true)
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data.map((el:any)=>el.nom_centre));
            setColors(centers.data.map(()=>getRandomColor()))
            let tab = []
            
            if(centerFilter=== '*'){
                for (const el of centers.data) {
                    const result = await countStudents(el.nom_centre)
                    tab.push(result.nb)
                }
                if(tab.length === centers.data.length){
                    setStudentsCount([...tab])
                }
            }
        }
    }

    const fetchDisciplines = async ()=>{
        setIsLoading(true)
        const Disciplines = await https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(Disciplines){
            setallDisciplines(Disciplines.data)
            let tab = []
            for (const el of Disciplines.data) {
                let result
                if(centerFilter === '*'){
                    result = await countStudentsByDis(el.id_dis)
                    if(result){
                        tab.push(result.nb)
                    }
                }else{
                    result = await countStudentsByCentreDis(centerFilter,el.id_dis)
                    if(result){
                        tab.push(result.nb)
                    }
                }
            }
            if(tab.length === Disciplines.data.length){
                setDisciplineCount([...tab])
                setIsLoading(false)
            }
        }
    }

    const countStudents = async (centre : string)=>{
        try{
            const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/count_by_centre/${encodeURI(centre)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => console.log(e))
            if(res){
                return res.data[0]
            }
        }
        catch(e) {
            console.log(e)
        }
    }
    const countStudentsByCentreDis = async (centre : string, id_dis: string | number)=>{
        try{
            const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/count_by_centre_discipline/${encodeURI(centre)}/${id_dis}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => console.log(e))
            if(res){
                return res.data[0]
            }
        }
        catch(e) {
            console.log(e)
        }
    }
    
    const countStudentsByDis = async (id : number)=>{
        try{
            const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/count_by_discipline/${id}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => console.log(e))
            if(res){
                return res.data[0]
            }
        }
        catch(e) {
            console.log(e)
        }
    }

    const fetchAllStudents = async ()=>{
        try{
            const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => console.log(e))
            if(res && res.data){
                setAllStudents(res.data)
            }
        }
        catch(e) {
            console.log(e)
        }
    }

    const fetchStudentsByStatus = async(centre : string) =>{
        try{
            let data = [] as any
            const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/status/${encodeURI(centre)}/${1}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => console.log(e))
            if(res && res.data[0]){
                data.push(res.data[0].nb)
                const res2 = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/status/${encodeURI(centre)}/${0}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                }).catch(e => console.log(e))
                if(res2 && res2.data[0]){
                    data.push(res2.data[0].nb)
                }
                if(data.length === 2){
                    setStatusData(data)
                }
            }
        }
        catch(e) {
            console.log(e)
        }
    }

    const fectColorsDiscipline = async()=>{
        const Disciplines = await https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(Disciplines){
            setColorsDis(Disciplines.data.map(()=>getRandomColor()))
        }
    }
    const getRandomColor = () =>{
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    useEffect(()=>{
        fetchCentre()
            .catch((e)=>console.log(e))
        fectColorsDiscipline()
            .catch((e)=>console.log(e))
        fetchAllStudents()
            .catch((e)=>console.log(e))
    },[])
    useEffect(()=>{
        fetchDisciplines()
            .catch((e) => console.log(e))

        if(centerFilter !== '*' && centerFilter){
            fetchStudentsByStatus(centerFilter)
                .catch((e) => console.log(e))
        }
    },[centerFilter])

    const ans = (date: any) =>{
        let d = new Date(date)
        return { j: d.getDate(), m: d.getMonth(), a: d.getFullYear() }
    }
    
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Août','Semptembre','Octobre','Novembre','Decembre'];

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Statistique des élèves inscrits par mois',
          },
        },
    };

    const capitalize = (word:string) =>{
        return word
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase());
    }

    
    

    const students_by_discipline = {
        labels: allDisciplines.map((item: any)=>capitalize(item.nom_dis)),
        datasets: [
          {
            label: '',
            data: disciplineCount,
            backgroundColor: colorsDis,
            borderColor: colorsDis,
            borderWidth: 1,
          },
        ],
    };
    

    const data_eleve_by_centre = {
        labels: allCentre,
        datasets: [
          {
            label: '',
            data: studentsCount,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };
    const data_actif_inactif_by_centre = {
        labels: ["Actifs", "Inactifs"],
        datasets: [
          {
            label: '',
            data: statusData,
            backgroundColor: ["blue", "red"],
            borderColor: ["blue", "red"],
            borderWidth: 1,
          },
        ],
    };
    
    
    const generateStats = (mois: any,centre: any) =>{
        let annee = new Date(Date.now()).getFullYear()
        let len = 0
        if(allStudents.length > 0 && centre !== "*"){
            let tab = allStudents.filter((el: any) =>ans(el.date_incription).m === mois && ans(el.date_incription).a === annee && el.nom_centre === centre) 
            len =  tab.length
        }
        return len 
    }

    const data_3 = {
        labels:months,
        datasets:allCentre?.map((element:any,index:any)=>({
            label: element,
            data: months.map((el: any,index2: any)=> generateStats(index2,element)),
            backgroundColor: colors[index]
        }))
    }

    const data_4 = {
        labels:months,
        datasets: [
          {
            label: 'Nombre d\'elèves',
            data: months.map((el: any, index: any) =>generateStats(index,centerFilter)),
            backgroundColor: 'green',
          },
        ],
    }
    return(
        <div className='bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
            <div className="container-fluid">
                <ChooseCenter setFilter = { setCenterFilter }/>
                
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="card  shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Statistiqiue des élèves par discipline</h6>
                        </div>
                        <div className="card-body">
                            <div className="">
                                {
                                    isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                                        Chargement...
                                    </div>
                                }
                                <Doughnut data={students_by_discipline} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                        {centerFilter === '*' &&<h6 className="m-0 font-weight-bold text-primary">Statistique des élèves inscrits par  centre</h6>}
                        {centerFilter !== '*' &&<h6 className="m-0 font-weight-bold text-primary">Statistique des élèves actifs et inactifs</h6>}
                        </div>
                        <div className="card-body">
                            <div className="">
                                {
                                    isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                                        Chargement...
                                    </div>
                                }
                                {centerFilter === '*' &&<Pie data={data_eleve_by_centre} />}
                                {centerFilter !== '*' &&<Pie data={data_actif_inactif_by_centre} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Statistique des élèves inscrits mensuels</h6>
                        </div>
                        <div className="card-body">
                            <div className="">
                                {
                                    isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                                        Chargement...
                                    </div>
                                }
                                {centerFilter === '*' && <Bar options={options} data={data_3} />}
                                {centerFilter !== '*' && <Bar options={options} data={data_4} />}
                            </div>
                        </div>
                    </div>
                </div>
                            
            </div>
        </div>
    </div>
)}











