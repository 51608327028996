import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ViewError from '../../services/ViewError';
import https from '../../helpers/https';
import httpsFiles from '../../helpers/httpsFiles';
import { decode } from '../../services/slugs';
import { isEmailValid } from '../../services/regex';
import { getHeader } from '../../helpers/getHeader';
import { fromatDate } from '../../services/date/date';

const Options = ({el}:any)=> {
    return( <option value={el.id_centre} style={{textTransform: "capitalize"}}>{el.nom_centre}</option> );
}
const DisciplinesOptions = ({el}:any)=> {
    return( <option value={el.id_dis} style={{textTransform: "capitalize"}}>{el.nom_dis}</option> );
}

const AddProffesor = () => {
    const [name, setName] = useState<String | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [url_image, setUrl_image] = useState<any>(null);
    const [url_image2, setUrl_image2] = useState<any>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [discipline, setDiscipline] = useState<number | null>(1);
    const [lastName, setLastName] = useState<String | null>(null);
    const [adress, setAdress] = useState<String | null>(null);
    const [centre, setCentre] = useState<number | null>(1);
    const [dateEntre, setDateEntre] = useState<any>(null);
    const [allCentre, setallCentre] = useState([] as any);
    const [allDiscipline, setallDiscipline] = useState([] as any);
    const [error, setError] = useState<String>();
    const [prof, setProf] = useState([] as any);
    const prof_id = useParams<string>();

    const navigation = useNavigate()
    const fetchCentre = async ()=>{
            const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(centers){
                setallCentre(centers.data);
            }
        }
    const fetdisciplines = async ()=>{
            const disciplines = await  https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(disciplines){
                setallDiscipline(disciplines.data);
            }
        }
    const fetchProf = async (id: any)=>{
        const profs = await  https.get(`/responsables/info/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(profs){
            setProf(profs.data);
        }
    }
    useEffect(()=>{
        if(localStorage.getItem('EGMUserType') !== 'super_admin' && decode(prof_id?.id)){
            navigation("/admin/liste-des-eleves")
        }
        fetchCentre()
            .catch(error => console.log(error));
        fetdisciplines()
            .catch(error => console.log(error));
        if(decode(prof_id.id)){
            fetchProf(decode(prof_id.id))
            .catch(error => console.log(error));
        }
        
    },[])

    useEffect(()=>{
        if(prof.length){
            setName(prof[0].nom_resp);
            setEmail(prof[0].email_resp) ;
            setPhone(prof[0].telephone_resp)
            setDiscipline(prof[0].id_dis_disciplines)
            setLastName(prof[0].prenom_resp)
            setAdress(prof[0].adresse_resp)
            setCentre(prof[0].id_centre_centres)
            setDateEntre(fromatDate(prof[0].resp_createdAt))
            if(prof[0]?.url_image){
                setUrl_image2(prof[0]?.url_image)
                let element = document.querySelector('.custom-file-label') as HTMLElement;
                element?.classList.add("selected")
                element.innerHTML = String(prof[0]?.url_image?.split('/images/')[1])
            }
            if(!prof[0]?.id_dis){
                navigation("/super-admin/liste-des-professeurs")
            }  
        }
    },[prof])

    const register = async () => {

        if(( name == null)||(lastName == null)||(phone == null)||( adress == null)||(parseInt(phone) < 0)||(phone.length > 10)||(phone.length < 10)||(dateEntre=="")) {
            setError("Veuillez remplir respectivement les champs suivants: nom, prenom, adresse, téléphone(longueur 10) ")
        }
        else {
            let data: { [key: string]: any } = {
                "nom_resp" : name,
                "prenom_resp" : lastName,
                "email_resp" : email || null,
                "adresse_resp" : adress,
                "telephone_resp" : phone,
                "type_resp" : 'professeur',
                "id_centre_centres" : centre,
                "id_dis_disciplines" : discipline,
                "resp_createdAt": new Date(dateEntre).toISOString().slice(0, 19).replace('T', ' '),
            }

            let formData = new FormData();

            for(let i in data){
                formData.append(`${i}`,data[i])
            }
            if(url_image){
                formData.append(`url_image`,url_image)
            }
            
            let response: any ;
            
            if(prof.length && decode(prof_id.id)){
                formData.append('id_resp',decode(prof_id.id))
                formData.append(`id_dis_disciplines`,prof[0].id_dis_disciplines)
                formData.append(`id_discipline_responsable`,prof[0].id_discipline_responsable)

                response = await httpsFiles.put(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, formData,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'file'),
                }).catch(e => {return e})
            }else{
                response = await httpsFiles.post(`/responsables/create_resp/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, formData,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'file'),
                }).catch(e => {return e})
            }
            if(response.status == 200){
                if(localStorage.getItem('EGMUserType') === 'super_admin'){
                    navigation(`/super-admin/liste-des-professeurs`)
                }else if(localStorage.getItem('EGMUserType') === 'admin'){
                    navigation(`/admin/liste-des-professeurs`)
                }
            }
            else{
                setError(response.response.data)
            }
        }
    } 

    return(
        <div  className='bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
        {/* <!-- Begin Page Content --> */}
        <div className="container-fluid">
            {/* <!-- Page Heading --> */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{decode(prof_id.id) ? "Modifier" : "Ajout"} Professeur</h1>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                <div className="card shadow mb-4" style={{ paddingBottom:'33px'}}> 
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Nom</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="text"   className="form-control" value={name as string} onChange={e => setName(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Prénom</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="text" value={lastName as string}  className="form-control" onChange={e => setLastName(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div><br/>
                                <h6 className="m-0 font-weight-bold text-primary">Email</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="email" value={email as string} className="form-control"  onChange={e => setEmail(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Téléphone</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" value={phone as any} readOnly={Boolean(prof[0]?.id_dis)}   className="form-control" onChange={e => setPhone(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Fonction</h6>
                                <br/>
                                <div className="input-group">
                                    <select className="form-control" value={discipline as any} style={{textTransform: "capitalize"}} onChange={e => setDiscipline(parseInt(e.target.value))}>
                                         { allDiscipline.map((element:any,index:any)=><DisciplinesOptions key={index.toString()} el={element}/>) }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        </div>
                </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Date d'entré</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="date" value={dateEntre}  className="form-control" onChange={e => setDateEntre(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div><br/>
                                <h6 className="m-0 font-weight-bold text-primary">Image</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="file"  accept="image/*"  
                                            lang="fr-BE"
                                            onChange={(e:any)=>{
                                                setUrl_image(e.target.files[0])
                                                setUrl_image2(URL.createObjectURL(e.target.files[0]))
                                                var fileName = e.target.value.split("\\").pop();
                                                let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                element?.classList.add("selected")
                                                element.innerHTML = String(fileName)
                                        }}
                                        className="custom-file-input form-control" id="customFile3"/>
                                        <label className="custom-file-label" htmlFor="#customFile3">Choisir l'image</label>
                                    </div>
                                    {url_image2 && (
                                        <div>
                                            <div className="" style={{
                                                position:'absolute',
                                                left:'190px'

                                            }}>
                                                <span onClick={() =>{
                                                    setUrl_image2('')
                                                    setUrl_image(null)
                                                    let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                    element?.classList.remove("selected")
                                                    element.innerHTML = "Choisir l'image"

                                                }}      className="close" style={{ color:'red'}} title="Supprimer">
                                                    &times;</span>
                                            </div>
                                            <img className='mt-1' width={150} height={150} src={url_image2} alt=""  />
                                        </div>
                                    )}
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Adresse</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="text"  className="form-control" value={adress as any} onChange={e => setAdress(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Centre</h6>
                                <br/>
                                <div className="input-group">
                                    <select name="" value={centre as any} style={{textTransform: "capitalize"}} id="" onChange={e => setCentre(parseInt(e.target.value))} className="form-control">
                                        { allCentre.map((element:any,index:any)=><Options key={index.toString()} el={element}/>) }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <ViewError error={error} />
                                <table>   
                                    <tbody>
                                        <tr>
                                            <td className="col-lg-2" align="center"><Link to="/super-admin" className="btn btn-sm btn-success "><i className="fas fa-fw fa-reply"></i> Retour</Link></td>
                                            <td className="col-lg-2" align="center"><button className="btn btn-sm btn-primary" onClick={register}><i className="fas fa-fw fa-save"></i> {decode(prof_id.id) ? "Valider" : "Enregistrer"}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                                 </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    );
}
export default AddProffesor;