import React, {useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getHeader } from '../helpers/getHeader';
import https from '../helpers/https';
import ViewError from '../services/ViewError';

interface Props{
    nom_dis:string;
    id_dis:number;
    handleOpen(e: any):void;
    getForModif: any;
}
const AllDiscipline = ({nom_dis, id_dis, handleOpen, getForModif} : Props) => {
   
    return(
        <div className="col-xl-3 col-md-6 mb-4 " >
            <div className="card border-left-primary shadow h-100 py-2 overlay"id="about">
                <div className="card-body ">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                <h4><b>{nom_dis}</b></h4>
                            </div>    
                        </div>
                        <div className="col-auto">
                            <button onClick={(e) =>{ handleOpen(e); getForModif(id_dis) }} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-pencil-alt"></i></button>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

const Disciplines = () => { 
    const [discipline, setDis] = useState([] as any);
    const inputDisName  = useRef<any>();
    const [error, setError] = useState<String>();
    const [id_dis,setId_dis] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDis, setSelectedDis] = useState<any>()
    const dis_name = useRef<any>()

    const fetchDiscipine = async ()=>{
        const disc = await https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(disc){
            setDis(disc.data);
            setIsLoading(false)
        }
    }

    const open = (id:string,id_dis:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setId_dis(id_dis)
        
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
        
    }
    useEffect(()=>{
        fetchDiscipine().catch(err => console.log(err))
    },[])
    
    const modifDis =  (id: string)  => {
        let data = {
            nom_dis: dis_name.current.value,
            id_dis: selectedDis.id_dis
        }
        https.put(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((resp:any)=>{
            if(resp.status == 200){
                fetchDiscipine()
                .then(()=>{
                    fermer(id)
                })
                .catch(err => console.log(err))
            }
        })
        .catch(e => {console.log(e)});
    }

    const register = async () => {
        if(!inputDisName.current.value){
            setError("Veuillez entrer le nom de discipline !!!")
            return;
        }
        let data = {
            "nom_dis" : inputDisName.current.value
        }
        const response = await https.post(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => {return e})

        if(response.status == 200){
            inputDisName.current.value = "";
            setError("")
            fetchDiscipine().catch(err => console.log(err))
        }
        else{
            setError(response?.response?.data)
            console.log(response)
        }
    }

    const getForModif = async (id_dis:number) => {
        await https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id_dis}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((resp:any)=>{
            dis_name.current.value = resp.data[0].nom_dis
            setSelectedDis(resp.data[0])
        })
        .catch(e => {console.log(e)});
    }

    return (
        <div  className='container-fluid bodybar mt-0 pt-4 pb-4' style={{
            height:window.innerHeight-70
        }}>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Disciplines </h1>
                </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                                    Chargement...
                                </div>
                            }   
                            
                        </div>
                    </div>
                    <div className="row">
                        { discipline.map((element:any,index:any)=> <AllDiscipline key={index.toString()} nom_dis={element.nom_dis} id_dis={element.id_dis} handleOpen={(e:any) => open('logoutModal2',element.id_dis)} getForModif={getForModif} />) }
                    </div>
                    <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mt-5">    
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h4 className="m-0 font-weight-bold text-primary">Ajout discipline</h4>
                                </div>             
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Nom</h6>
                                 <br/>
                                <div className="input-group">
                                    <input type="text" onChange={(e)=>{
                                        if(e.target.value){
                                            setError("")
                                        }else{
                                            setError("Veuillez entrer le nom de discipline !!!")
                                        }
                                        
                                    }} required  ref={inputDisName} placeholder='Tapez ici le nom de discipline'  className="form-control" />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                {error && <ViewError error={error} />}
                                <table>   
                                    <tbody>
                                        <tr>
                                            <td className="col-lg-2"><button className="btn btn-sm btn-primary" onClick={register}><i className="fas fa-fw fa-save"></i> Enregistrer</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                                 </div>
                        </div>
                    </div>
                    <div className="modal" id="logoutModal2">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{
                                width: '100%',
                                marginTop:'150px',
                            }} >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modifier</h5>
                                    
                                </div>
                                <div className="modal-body" style={{
                                    padding:'20px'
                                }}>
                                    <label>Nom</label>
                                    <div className="row" style={{ marginTop: "5px" }}>
                                    <div className="col-lg-12">
                                        <input
                                        type="text"
                                        ref={dis_name}
                                        className="form-control"
                                        name="date_paiement"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal2')}   data-dismiss="modal">Anuller</button>
                                    <button className="btn btn-success btn-sm"  onClick={(e) =>{
                                        e.preventDefault()
                                        modifDis("logoutModal2")
                                    }}>Modifier</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}
export default Disciplines;