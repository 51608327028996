import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHeader } from '../../helpers/getHeader';
import https from '../../helpers/https';


const ChooseCoursType = ({ setCoursFilter }: any) => {
    const [coursType, setCoursType] = useState ('tout')

    const handleChange = (e:any) => {
        e.preventDefault()
        setCoursType(e.target.value)
        setCoursFilter(e.target.value)
    }

    return(
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex">
                            <h5 className="mb-0 text-gray-800" style={{textTransform: 'capitalize'}}>Cycle : {coursType}</h5>
                        </div>
                        <div>
                            <select className="form-control" onChange={(e) => handleChange(e)}>
                                <option value="tout">Tout</option>
                                <option value="academique">Académique</option>
                                <option value="cours">Cours</option>
                                <option value="diplomant">Diplomant</option>
                            </select>
                        </div>
            </div>
    );
}
export default ChooseCoursType;