import React from 'react';

const CheckPaid = ({data , id_el }:any) => {
    if(parseFloat(data) <= 0){
        return(
            <ul id={"ele"+id_el} className="social-links list-inline">
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} ></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el}  ></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} ></a></li>
            </ul>
        );
    }
    else if(parseFloat(data) <= 33.4) {
        return(
            <ul id={"ele"+id_el} className="social-links list-inline">
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#48ec71"}}></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el}  ></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} ></a></li>
            </ul>
        );
    }
    else if(parseFloat(data) < 100){
        return(
            <ul id={"ele"+id_el} className="social-links list-inline">
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#48ec71"}}></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#33aa50"}}></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} ></a></li>
            </ul>
        );
    }
    else{
        return(
            <ul id={"ele"+id_el} className="social-links list-inline">
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#48ec71"}} ></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#33aa50"}} ></a></li>
                <li className="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" className="tooltips" href={"#"+"ele"+id_el} style={{backgroundColor:"#227537"}}></a></li>
            </ul>
        );
    }
}
export default CheckPaid;