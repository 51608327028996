const cle = 'egl_lakile_slug_20annee_bablabla_yes_code_yes_interdit_to_fetch_code_secured_egm_fect_balabla'
const encode =( str: any ) =>{
    return  window.btoa(cle + str)
}
  
const decode = ( str: any ) =>{
    try {
        return window.atob(str).split(cle)[1]
    } catch (error) {
        return ''
    }
    
}
export { encode , decode}
  
