import React, { useEffect, useState } from "react";
import "../../styles/fromTop.css";
import { Link } from "react-router-dom";
import https from "../helpers/https";
import profil from "../img/undraw_profile.svg";
import { getHeader } from "../helpers/getHeader";

const Profile = () => {
  const initialAdmin = {
    url_image: profil,
    nom_resp: "",
    prenom_resp: "",
    email_resp: "",
    adresse_resp: "",
    telephone_resp: 0,
  };

  const [resp, setprofile] = useState([initialAdmin] as any);
  const fetchProfil = async () => {
    const profile = await https.get(
      `/responsables/info/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${localStorage.getItem("EGMUserId")}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    }
    );
    if (profile) {
      setprofile(profile.data);
    }
  };
  useEffect(() => {
    console.log(resp);
  }, [resp]);
  useEffect(() => {
    fetchProfil().catch((e) => console.log(e));
  }, []);
  return (
    <div
      className="bodybar mt-0 pt-4"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <div className="container">
        <div className="main-body">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={
                        resp[0].url_image == null ? profil : resp[0].url_image
                      }
                      alt="Admin"
                      className="rounded-circle p-1 bg-primary"
                      width="200"
                    />
                    <div className="mt-3">
                      <h4>Je suis {resp[0].prenom_resp}</h4>
                      <p className="text-secondary mb-1">
                        Super-Administrateur
                      </p>
                      <p className="text-muted font-size-sm">De L'EGM</p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0 font-weight-bold text-warning">
                        <i className="fab fa-google fa-fw text-danger"></i>
                        mail
                      </h6>
                      <span className="text-secondary">
                        {resp[0].email_resp}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <br />
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Nom</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0].nom_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Prénom</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0].prenom_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0].telephone_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Adresse</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0].adresse_resp}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Responsable</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={resp[0].type_resp}
                      />
                    </div>
                  </div>
                  <Link to="/super-admin">
                    <button className="btn btn-success float-right btn-sm">
                      <i className="fa fa-reply fa-fw text-light"></i> Retour
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
