import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/fromTop.css';
import profil from "../img/profil.png";
import https from '../helpers/https';
import { encode } from '../services/slugs';
import CheckPaid from '../Global/checkPaid';
import { getHeader } from '../helpers/getHeader';

const Dashboard = () => {
    const [id_el, setId_el] = useState(0)
    const [eleves, setEleves] = useState([] as any)
    const [eleve, setEleve] = useState({} as any)
    const [allCentre, setAllCentre] = useState([] as any);
    const [nbEleves, setNbEleves] = useState([] as any)
    const [nbEleveCours, setNbEleveCours] = useState(0)
    const [nbEleveAcad, setNbEleveAcad] = useState(0)
    const [nbEleveDipl, setNbEleveDipl] = useState(0)
    const [nbProf, setNbProf] = useState(0)
    const [nbEleveQuitter, setNbEleveQuitter] = useState(0)
    const [finance, setFinance] = useState(0)
    const [look, setLook] = useState([] as any);
    const [trimestre_now, setTrimestre_now] = useState("1")
    const [isLoading, setIsLoading] = useState(false)
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [limit, setLimit] = useState(12)
    const [tmpTab, setTmpTab] = useState([] as any);
    const [nbrStudentNotPaid, setNbrStudentNotPaid] = useState(0)
    const navigation = useNavigate()

    const fetchStudentNotPaid = async () => {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        const URL =`/ecolages/follow-payment/${month}/${year}/tout/${localStorage.getItem(
        "EGMUserId"
        )}/${localStorage.getItem("EGMUserType")}`;

        await https
            .get(URL, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), "json"),
            })
            .then((response) => {
            setNbrStudentNotPaid(response.data.length);
            })
            .catch((e) => console.log(e));
    };

    const prev = () => {
        if (index > 2) {
            setIndex(index - 1)
            setIndex2((index - 1) * 12)
            setLimit(index * 12)
        } else {
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }
    const next = () => {
        if (index <= Math.round(eleves.length / 12)) {
            setIndex(index + 1)
            setIndex2(index * 12)
            setLimit((index + 1) * 12)
        } else {
            setIndex(1)
            setIndex2(0)
            setLimit(12)
        }
    }


    const current = (index: any, value: any) => {
        if (parseInt(String(eleves.length / 12).split('.')[1][0]) < 5) {
            setIndex(index)
            setIndex2((index - value) * 12)
            setLimit((index) * 12)
        } else {
            setIndex(index)
            setIndex2(index * 12)
            setLimit((index + 1) * 12)
        }
    }

    const disabled = (status: boolean) => {
        if (status) {
            if (index >= Math.round(eleves.length / 12))
                return " disabled"
        } else {
            if (index <= 1)
                return " disabled"
        }
        return ""
    }


    const active = (indexnew: any, i: any) => {
        const active = (index - i) === indexnew ? " active" : ""
        return active
    }

    const dynamiqueTab = (index2: any, limit: any, tab: any) => {
        let tmp = []
        for (let i = index2; i <= limit; i++) {
            if (i >= 0 && i < tab.length) {
                const element = tab[i];
                tmp.push(element)
            }
        }
        return tmp
    }

    const [q, setQ] = useState("")

    useEffect(() => {
        if (limit) {
            let result = dynamiqueTab(index2, limit, eleves)
            setTmpTab([...result])
        }
    }, [limit, index, index2, eleves, q])


    

    const search = (q: string) => {
        setIsLoading(true)
        if (!q) {
            fetchCentre()
                .catch(error => console.log(error));
        } else {
            https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/search/${encodeURI(q)}`, {
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
                .then(async (res) => {
                    let tmp = [] as any;
                    for await (const eleve of res.data.filter((el: any) => el.status == 1)) {
                        let total = await https.get(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_total/${eleve.id_el}/${trimestre_now}`, {
                            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                        }).catch(e => console.log(e))
                        if (total) {
                            switch (trimestre_now) {
                                case '1':
                                    tmp.push(total.data.total * 100 / eleve.ecolage_total)
                                    break
                                case '2':
                                    tmp.push(total.data.total * 100 / eleve.ecolage_t2)
                                    break;
                                case '3':
                                    tmp.push(total.data.total * 100 / eleve.ecolage_t3)
                                    break;
                                case '4':
                                    tmp.push(total.data.total * 100 / eleve.ecolage_t4)
                                    break;
                            }
                        }
                    }
                    setLook(tmp)
                    setIsLoading(false)
                    setEleves(res.data)
                })
                .catch((e) => console.log(e))
        }

    }
    const initialState = {
        date_deb: Date.now(),
        date_fin: Date.now(),
        time_deb: undefined as any,
        time_fin: undefined as any,
        motifs: ""
    }
    const [absence, setAbsence] = React.useState(initialState)

    const fetchCentre = async () => {
        setIsLoading(true)
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (centers) {
            setAllCentre(centers.data);
            fetchData(centers.data)
                .catch((e) => { console.log(e) })
        }
    }

    const fetchData = async (centres: any) => {
        let centre = "";
        let auth_centre = parseInt(localStorage.getItem("EGMUserCentre")!);
        if (centres.length) {
            for await (const el of centres) {
                if (el.id_centre == auth_centre)
                    centre = el.nom_centre
            }
        }
        if (centre) {
            let response = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => { console.log(e) });

            if (response && response.data) {
                let tmp = [] as any;
                for await (const eleve of response.data.filter((el: any) => el.status == 1)?.filter((element: any, index: any) => index < 8)) {
                    let total = await https.get(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_total/${eleve.id_el}/${trimestre_now}`, {
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    }).catch(e => console.log(e))
                    if (total) {
                        switch (trimestre_now) {
                            case '1':
                                tmp.push(total.data.total * 100 / eleve.ecolage_total)
                                break
                            case '2':
                                tmp.push(total.data.total * 100 / eleve.ecolage_t2)
                                break;
                            case '3':
                                tmp.push(total.data.total * 100 / eleve.ecolage_t3)
                                break;
                            case '4':
                                tmp.push(total.data.total * 100 / eleve.ecolage_t4)
                                break;
                        }

                    }
                }
                setLook(tmp)
                setIsLoading(false)
                setEleves(response.data)
            }

        }
    }

    const fetchStats = async () => {
        let response = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => { console.log(e) },);
        let response2 = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/professeur`, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => { console.log(e) });
        let resp = await https.get(`/ecolages/finance_ecolage/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/finance_ecolage`, {
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => console.log(e))
        if (response) {
            let quit = 0
            let actif = 0
            let cours = 0
            let accad = 0
            let dipl = 0
            for await (const el of response.data) {
                // console.log(el.status)
                if (el.status == 0) quit++
                if (el.status == 1) actif++
                if((el.status==1)&&(el.type_cycle=="cours")) cours++
                if((el.status==1)&&(el.type_cycle=="academique")) accad++
                if((el.status==1)&&(el.type_cycle=="diplomant")) dipl++
            }
            setNbEleveQuitter(quit)
            setNbEleves(actif)
            setNbEleveCours(cours)
            setNbEleveAcad(accad)
            setNbEleveDipl(dipl)
        }
        if (response2) {
            setNbProf(response2.data.length)
        }
        if (resp) {
            let total = 0;
            for await (const eco of resp.data) {
                total += eco.somme_paye
            }
            setFinance(total)
        }

    }
    useEffect(() => {
        fetchCentre()
            .catch(error => console.log(error));
        fetchStats()
            .catch(error => console.log(error));
        fetchStudentNotPaid()
    }, [])

    const open = (id: string, eleve: any) => {
        let modal = document.querySelector('#' + id) as Element;
        modal?.classList.add("open")
        setEleve(eleve)

    }

    useEffect(() => {
        if (!q) {
            fetchCentre()
                .catch(error => console.log(error));
        }
        else {
            search(q)
        }
    }, [trimestre_now])

    const handleChange = (e: any) => {
        setAbsence({
            ...absence,
            [e.target.name]: e.target.value
        })
    }
    const fermer = (id: string) => {
        let modal = document.querySelector('#' + id) as Element;
        modal?.classList.remove("open")

    }

    const handleAbsence = (e: any, id: string) => {
        e.preventDefault()
        if (eleve && eleve.id_el) {
            https.post(`/absences/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
                ...absence,
                id_el_eleves: eleve.id_el,
                id_niv_niveau: eleve.id_niv_niveau
            }, {
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
                .then((res) => {
                    if (res.status === 200) {
                        fermer(id)
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }
    const deleteStudent = (e: any, id: string) => {
        e.preventDefault()
        if (eleve && eleve.id_el) {
            https.delete(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${eleve.id_el}`, {
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
                .then((res) => {
                    if (res.status === 200) {
                        fetchCentre()
                            .then(() => { fermer(id) })
                            .catch((e: any) => console.log(e))
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }
    return (
        <div className='bodybar mt-0 pt-4' style={{
            height: window.innerHeight - 70
        }}>
            {/* container fluid */}
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Page d'accueil</h1>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4 " >
                        <div className="card border-left-primary shadow h-100 py-2 overlay" id="about">
                            <div className="card-body ">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Professeurs</div>

                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{nbProf}</div>
                                    </div>

                                    <div className="col-auto">

                                        <i className="fas fa-users fa-2x text-gray-300"></i>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Elèves Actifs
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-sm-6">
                                                <p className='font-weight-bold text-gray-800'>{ nbEleves }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Ecolage payé cette semaine</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{finance} Ar</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Elèves Quittés</div>

                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{nbEleveQuitter}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-flag fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Actifs~Academique</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{ nbEleveAcad }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Actifs~Cours</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{ nbEleveCours }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Actifs~Diplomant</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{ nbEleveDipl }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2  overlay" style={{cursor:'pointer'}} onClick={() => navigation('/super-admin/suivi-ecolage')} id="about">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Impayé ce mois</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{ nbrStudentNotPaid }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of container fluid */}
                {/* ///here */}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mt-4 mb-4">
                            {/* <!-- Card Header - Dropdown --> */}
                            <div className="card-header py-3 flex-row align-items-center justify-content-between">
                                <div className="row">
                                    <div className="col-md-3 mt-2">
                                        <select id="" className="form-control" onChange={(e) => setTrimestre_now(e.target.value)}>
                                            <option value="1">Liste Trimestre 1</option>
                                            <option value="2">Liste Trimestre 2</option>
                                            <option value="3">Liste Trimestre 3</option>
                                            <option value="4">Liste Trimestre 4</option>
                                        </select>
                                    </div>
                                    <div className="col-9 text-end">
                                        <div className="row">
                                            <div className="col-md-6 mt-2">
                                                <Link to="/super-admin/inscription">
                                                    <button className="btn mr-2 btn btn-primary"><i className="fas fa-fw fa-plus"></i> Inscription</button>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <div className="input-group" id="search">
                                                    <input type="text" placeholder="Rechercher..." onChange={(e) => {
                                                        search(e.target.value)
                                                        setQ(e.target.value)
                                                    }} id="myInput" title="type nom ou prenom" className=" form-control " />
                                                    <div className="input-group-append">
                                                        <button onClick={() => search(q)} className="btn float-right btn-primary" title="" ><i className="fas fa-search "></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body col-xl-12 col-lg-12">
                                {
                                    isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                                        Chargement...
                                    </div>
                                }
                                <div className="row mt-2" >
                                    <div id="mymodal" className="modal">
                                        <form className="modal-content animate"
                                            action='' method="post" style={{
                                                marginTop: '70px'
                                            }}>
                                            <div className="modal-header">
                                                <h5 className="modal-title"><strong>Absences</strong></h5>
                                            </div>
                                            <div className="imgcontainer">
                                                <span onClick={() => fermer('mymodal')} className="close" title="Close Modal">
                                                    &times;</span>
                                            </div>
                                            <div className="modal-body">
                                                <div className="container">
                                                    <label>De</label>
                                                    <div className="row" style={{ marginTop: "5px" }}>
                                                        <div className="col-lg-6">
                                                            <input type="date" onChange={handleChange} value={absence.date_deb} className="form-control" name="date_deb" />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input type="time" onChange={handleChange} value={absence.time_deb} className="form-control" name="time_deb" />
                                                        </div>
                                                    </div>
                                                    <label style={{ marginTop: "5px" }}>A</label>
                                                    <div className="row" style={{ marginTop: "5px" }}>
                                                        <div className="col-lg-6">
                                                            <input type="date" onChange={handleChange} value={absence.date_fin} className="form-control" name="date_fin" />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input type="time" onChange={handleChange} value={absence.time_fin} className="form-control" name="time_fin" />
                                                        </div>
                                                    </div>

                                                    <label style={{ marginTop: "5px" }}>Motifs</label>
                                                    <textarea rows={4} style={{ marginTop: "5px" }} onChange={handleChange} value={absence.motifs} className="form-control" name="motifs" />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={(e) => handleAbsence(e, 'mymodal')} className="btn btn-sm btn-primary" type="submit"><i className="fas  fa-save"></i> Enregistrer</button>
                                            </div>
                                        </form>
                                    </div>
                                    {
                                        q && eleves.length <= 0 ?
                                            <div className="alert text-center" role="alert">
                                                Pas de resultat
                                            </div>
                                            :
                                            tmpTab.filter((el: any,index:any) => el.status == 1 && index <= 11)?.map((eleve: any, idx: number) =>
                                                <div className="col-lg-2 mb-2 col-md-4 col-sm-4 col-xs-6" id="" key={eleve.id_el} >
                                                    <button className="btn btn-sm float-left btn-primary" onClick={() => open('mymodal', eleve)} title="">Absences</button>
                                                    <div className="text-center card-hover" id="col">

                                                        <div className="member-card  pt-2 pb-2 media-body" >

                                                            <div style={{ alignItems: "center", textAlign: "center" }} className='pt-2'>
                                                                <img src={eleve.url_image || profil} style={{ borderRadius: '50%' }} width="100px" height="100px" alt="profile-image" />
                                                            </div>
                                                            <div id="lk" >
                                                                <p style={{ fontSize: 14, textTransform: 'uppercase', whiteSpace: 'break-spaces' }} id="anarana" className='mt-2'>{eleve.prenom}</p>
                                                                <p className="text-muted">{eleve.classe} <span><br /><br /><a href="#" className="text-pink"><span style={{ color: "#224abe" }}>Matricule:</span><br /><br /> {eleve.matricule}</a></span></p>
                                                            </div>

                                                            <CheckPaid data={look[idx]} id_el={eleve.id} />
                                                            <Link to={"/super-admin/a-propos/" + encode(eleve.id_el)} className="btn btn-sm btn-rounded btn-warning"><i className="fas  fa-book"></i> A propos</Link>

                                                        </div>
                                                    </div>
                                                </div>)}
                                </div>
                                {eleves.length >= 12 && <ul className="mt-5 pagination pagination-md d-flex justify-content-center">
                                        <li className={"page-item" + disabled(false)} style={{ cursor: index <= 1 ? 'no-drop' : 'pointer' }}><span className="page-link" onClick={prev} >Précedent</span></li>
                                        {index > 1 && <li className="page-item" style={{ cursor: 'no-drop' }}><span className="page-link" >...</span></li>}
                                        {tmpTab.length ?<li className={"page-item" + active(index, 0)} style={{ cursor: 'pointer' }}><span className="page-link" onClick={() => current(index, 0)}>{index}</span></li>: null}
                                        { tmpTab.length ? index + 1 <= Math.round(eleves.length / 12) + 1 && <li className={"page-item" + active(index, 1)} style={{ cursor: 'pointer' }} ><span className="page-link" onClick={() => current(index + 1, 1)}>{index + 1}</span></li>: null }
                                        {tmpTab.length ? Math.round(eleves.length / 12) > 2 && index < Math.round(eleves.length / 12)  && <li className="page-item" style={{ cursor: 'no-drop' }}><span className="page-link" >...</span></li>: null}
                                        <li className={"page-item" + disabled(true)} style={{ cursor: index >= Math.round(eleves.length / 12) ? 'no-drop' : 'pointer' }}><span className="page-link" onClick={next}>Suivant</span></li>
                                    </ul>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of here */}
            </div>
        </div>

    );
}

export default Dashboard;