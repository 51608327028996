import React, { useEffect, useState } from "react";
import MonthSelector from "./MonthSelector/MonthSelector";
import TableWraper from "./TableWraper/TableWraper";
import https from "../../helpers/https";
import { getHeader } from "../../helpers/getHeader";
import { generateDate } from "../../services/date/date";

const FollowPayment = () => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [center, setCenter] = useState("tout");
  const [authcenter, setAuthCenter] = useState("tout");

  const getAuthCenterName = async () => {
    setLoading(true);
    await https
      .get(
        `/centres/${localStorage.getItem("EGMUserId")}/${localStorage.getItem(
          "EGMUserType"
        )}/${localStorage.getItem("EGMUserCentre")}`,
        {
          headers: getHeader(localStorage.getItem("EGMUserToken"), "json"),
        }
      )
      .then((response) => {
        setAuthCenter(response.data[0].nom_centre);
        fetchData(response.data[0].nom_centre);
      })
      .catch((e) => console.log(e));
  };

  const fetchData = async (my_center: string) => {
    setLoading(true);
    const year = new Date().getFullYear();
    const URL =
      localStorage.getItem("EGMUserType") == "super_admin"
        ? `/ecolages/follow-payment/${month}/${year}/${center}/${localStorage.getItem(
            "EGMUserId"
          )}/${localStorage.getItem("EGMUserType")}`
        : `/ecolages/follow-payment/${month}/${year}/${my_center}/${localStorage.getItem(
            "EGMUserId"
          )}/${localStorage.getItem("EGMUserType")}`;

    await https
      .get(URL, {
        headers: getHeader(localStorage.getItem("EGMUserToken"), "json"),
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getAuthCenterName();
  }, []);

  useEffect(() => {
    fetchData(authcenter);
  }, [month]);

  useEffect(() => {
    fetchData(authcenter);
  }, [center]);

  return (
    <div
      className="bodybar mt-0 pt-4"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <div className="container-fluid">
        <MonthSelector
          month={month}
          setMonth={setMonth}
          setCenter={setCenter}
        />
        {/* Begin */}
        <div className="row">
          <TableWraper>
            {loading && (
              <tr>
                <td className="text-center" colSpan={9}>
                  Chargement...
                </td>
              </tr>
            )}
            {!loading && data.length == 0 ? (
              <tr>
                <td className="text-center" colSpan={9}>
                  Pas de résultat
                </td>
              </tr>
            ) : (
              data.map((student: any, idx: number) => (
                <tr key={idx}>
                  <td>{student.numero}</td>
                  <td>{student.prenom}</td>
                  <td>{student.matricule}</td>
                  <td>{student.nom_centre}</td>
                  <td>{student.telephone}</td>
                  <td>{student.trimestre}</td>
                  <td>{student.ecolage_payer}</td>
                  <td>{student.total_ecolage}</td>
                  <td>{student.total_ecolage - student.ecolage_payer}</td>
                  {/* <td>{generateDate(student.first_cours)}</td>
                      <td>{generateDate(student.compare_date)}</td> */}
                </tr>
              ))
            )}
          </TableWraper>
        </div>
      </div>
    </div>
  );
};
export default FollowPayment;
