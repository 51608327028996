import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import https from '../../helpers/https';
import { encode } from '../../services/slugs';
import { getCookie } from '../../services/cookies/dataCookies';
import ChooseCenter from './chooseCenter';
import { getHeader } from '../../helpers/getHeader';

const MarkAverage = () => {
    const [allStudents, setAllStudents] = useState([] as any);
    const [centerFilter, setCenterFilter] = useState('*');
    const [auth_type, setAuthType] = useState("");
    const [allAvg, setAllAvg] = useState([] as any)
    const [auth_centre, setAuthCentre] = useState<any>()
    const [tmpTab, setTmpTab] = useState([] as any);
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [limit, setLimit] = useState(10)
    const [isOk, setIsOk] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [trimestre_now, setTrimestre_now] = useState("trimestre 1")

    const fetchCentre = async ()=>{
        https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((res :any) => {
            for (const el of res.data) {
                if(el.id_centre == localStorage.getItem('EGMUserCentre')) {
                    setAuthCentre(el.nom_centre)
                    setAuthType(localStorage.getItem('EGMUserType')!)
                    setIsOk(true)
                }
            }
        })
        .catch((e) =>console.log(e))
    }

    const fetchStudents = async (filter : string, auth_centre: string, auth_type: string)=>{
        setIsLoading(true)
        try{
            if(auth_type == "super_admin"){
                if (filter == '*'){
                    const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(res && res.data) {
                        setAllStudents(res.data)
                        setIsLoading(false)
                    }
                }else{
                    const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${encodeURI(filter)}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(res && res.data) {
                        setAllStudents(res.data)
                        setIsLoading(false)
                    }
                }
            }else{
                const res = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${encodeURI(auth_centre)}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(res && res.data) {
                    setAllStudents(res.data)
                    setIsLoading(false)
                }
            }
        }
        catch(e){console.log(e)}
    }

    useEffect(()=>{
        if(!isOk){
            fetchCentre()
                .catch(() => setIsOk(true))
        }
    },[isOk])

    
    useEffect(()=>{
        if(isOk){
            fetchStudents(centerFilter,auth_centre, auth_type)
                .catch(error => console.log(error));
        }
    },[centerFilter,isOk])

    const generateMoyenne = async(tmp: any) =>{
        if(tmp.length){
            let tab = []
            for (const el of tmp) {
                try {
                    let res = await https.get(`/notes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get/moyenne/${el.id_el}/${trimestre_now}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(res && res.data.length){
                        tab.push(res.data[0]?.moyenne)
                    }else{
                        tab.push(0)
                    }
                } catch (error) {
                    tab.push(0)
                }
            }
            if(tab.length === tmp.length){
                setAllAvg([...tab])
            }
        }
    }

    const prev  = () =>{
        if(index > 2){
            setIndex(index - 1)
            setIndex2((index - 1)*10)
            setLimit(index * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }
    const next = () =>{
        if( index <= Math.round(allStudents.length/10)){
            setIndex(index + 1)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }


    const current = (index: any,value: any) =>{
        if(parseInt(String(allStudents.length/10).split('.')[1][0]) < 5){
            setIndex(index)
            setIndex2((index-value) * 10)
            setLimit((index) * 10)
        }else{
            setIndex(index)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }
    }


    const disabled = (status: boolean) =>{
        if(status){
            if(index > Math.round(allStudents.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }
    const print = () => {
        window.print();
        
      };
    const dynamiqueTab = (index2:any,limit:any,tab:any) =>{
        let tmp = []
        for (let i = index2; i < limit; i++) {
            if(i >= 0 && i < tab.length && parseInt(tab[i].status)){
                const element = tab[i];
                tmp.push(element)
            }
        }
        return tmp
    }

    useEffect(()=>{
        if(limit){
            let result = dynamiqueTab(index2,limit,allStudents)
            setTmpTab([...result])
            generateMoyenne(result)
            .catch()
           
        }
    },[limit,index,index2,allStudents])

    useEffect(()=>{
        if(limit){
            let result = dynamiqueTab(index2,limit,allStudents)
            setTmpTab([...result])
            generateMoyenne(result)
            .catch()
           
        }
    },[trimestre_now])
    

    return(
        <div  className='container-fluid bodybar mt-0 pt-4'  style={{
            height:window.innerHeight-70
        }}>

            <div className="d-sm-flex align-items-center justify-content-between mb-2">
                <div className="d-flex">
                    <h5 className="mb-0 text-gray-800" style={{textTransform:"capitalize"}}>Trimestre : {trimestre_now}</h5>
                </div>
                <div>
                    <select className="form-control" onChange={(e) => setTrimestre_now(e.target.value)}>
                        <option value="trimestre 1">Trimestre 1</option>
                        <option value="trimestre 2">Trimestre 2</option>
                        <option value="trimestre 3">Trimestre 3</option>
                        <option value="trimestre 4">Trimestre 4</option>
                    </select>
                </div>
            </div>
            { auth_type == "super_admin" ? <ChooseCenter setFilter = { setCenterFilter }/> : "" }
            {/* Begin */}
            <div className="row">
                <div className="col-lg-12 mb-5">
                    <div className="card shadow mt-1">
                        <div className="card-header">
                            <div className="row pt-2">
                                <div className="col-lg-6">
                                    <h6 className="font-weight-bold text-primary">Listes des notes et moyennes</h6>
                                </div>
                                <div className="col-lg-6"  style={{ textAlign: 'end'}}>
                                    <a href='#' id='tg2' onClick={print}>
                                        <b>Imprimer</b>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="widget-box">
                            <div className="widget-content nopadding">
                                <table className="table table-bordered table-responsive-xl table-striped" id='note' width="100%">

                                    <thead>
                                        <tr>
                                            <th>Numéro</th>
                                            <th>Nom</th>
                                            <th>Prénom</th>
                                            <th>Matricule</th>
                                            <th>Centre</th>

                                            <th>Moyenne Generale</th>
                                            <th id='ts'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td className='text-center' colSpan={7}>
                                                    Chargement...
                                                </td>
                                            </tr>
                                        }
                                        { tmpTab.map((el:any, idx : number)=>{
                                            return(
                                                <tr key={el.id_el} className="pb-0">
                                                    <td>{el.numero}</td>
                                                    <td>{el.nom}</td>
                                                    <td>{el.prenom}</td>
                                                    <td>{el.matricule}</td>
                                                    <td>{el.nom_centre}</td>
                                                    <td>{allAvg[idx]}</td>
                                                    <td className="pt-0" id='ts'>

                                                        <Link to={ auth_type == "super_admin" ? "/super-admin/note-eleve/"+encode(el.id_el) :  "/admin/note-eleve/"+encode(el.id_el)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-eye"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                            
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                            {allStudents.length > 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                        <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                        { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index,0)}>{index}</span></li>
                                        {index + 1 <= Math.round(allStudents.length/10) + 1 && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1,1)}>{index + 1}</span></li>}
                                        {Math.round(allStudents.length/10) > 2 && index < Math.round(allStudents.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(allStudents.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                                    </ul>}
                        </div>
                    </div>
                </div>
        </div>
        {/* end */}
    </div>
    );
}
export default MarkAverage;