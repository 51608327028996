import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import profil from './views/img/undraw_profile.svg';
import Router from './routes/Router';
import './index.css'
import { UserProvider } from './views/services/UserContext';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Router />
			</UserProvider> 
    </BrowserRouter>
  );
}

export default App;