import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import https from '../helpers/https'
import { encode } from '../services/slugs'
import './styleProfil.css'
import { getHeader } from '../helpers/getHeader'
interface props{
    id: any,
    handleClose(e : any): void
}
const Profil = ({id,handleClose}:props) =>{
    const [responsable,setResponsable] = useState({} as any)
    const [auth_type, setAuthType] = useState(localStorage.getItem('EGMUserType'));

    const fetchData = async() =>{
        try {
            const professor = await https.get(`/responsables/info/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(professor){
                setResponsable(professor.data[0])
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        fetchData()
        .catch(e => console.log(e))
    })
    return(
        <div className="container pr-1 pl-3">
        <div className="row">
        <div className="col col-lg-12">
            <div className="card">
            <div className="row  ml-0">
                <div className="col-md-4 gradient-custom text-center text-white">
                    <img src={responsable?.url_image}
                        alt="Avatar" className="img-fluid my-4" style={{height:'100px' ,width: '100px', borderRadius:'50%'}} />
                    <h5>{responsable?.nom_resp}</h5>
                    <p>{responsable?.prenom_resp}</p>
                    {
                        auth_type == "super_admin" ? <Link to={'/super-admin/modify-admin/'+ encode(responsable?.id_resp) }><i className="far fa-edit mb-5 text-white"></i></Link> : ""
                    }
                </div>
                <div className="col-md-8">
                <div className="card-body p-4">
                    <h6>Information</h6>
                    <hr className="mt-0 mb-4"/>
                    <div className="row pt-1">
                    <div className="col-6 mb-3">
                        <h6>Email</h6>
                        <p className="text-muted">{responsable?.email_resp||'Aucun'}</p>
                    </div>
                    <div className="col-6 mb-3">
                        <h6>Phone</h6>
                        <p className="text-muted">+261{responsable?.telephone_resp?.slice(1)}</p>
                    </div>
                    </div>
                    <h6>Poste</h6>
                    <hr className="mt-0 mb-4"/>
                    <div className="row pt-1">
                    <div className="col-6 mb-3">
                        <h6>Type</h6>
                        <p className="text-muted" style={{ textTransform:'capitalize'}}>{responsable?.type_resp ||'Aucun'}</p>
                    </div>
                    <div className="col-6 mb-3">
                        <h6>Centre</h6>
                        <p className="text-muted">{responsable?.nom_centre || 'Aucun'}</p>
                    </div>
                    </div>
                    <div className="d-flex justify-content-start">
                        <a className="btn btn-danger btn-md text-white"  onClick={(e) =>{ 
                            e.preventDefault()
                            handleClose(e)
                        }}>Fermer</a>
                        {
                            auth_type == "super_admin" ? <Link to={'/super-admin/modify-admin/'+ encode(responsable?.id_resp) } className="btn btn-primary btn-md ml-4">Modifier</Link> : ""
                        }
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    )
}

export default Profil