import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImg from "./img/undraw_remotely_2j6y.svg";
import { postData } from './services/query/dataQuery';
import ViewError from './services/ViewError';
import UserContext from '../views/services/UserContext';
const Login = () => {
    require('../styles/login.css');
    const nav = useNavigate();
    const [currentUser, setCurrentUser ] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false)
    const [ number, setNumber ] = useState<any>();
    const [ password, setPassword ] = useState<String>();
    const [error, setError ] = useState<String>();
    const [isActive,setIsActive] = useState(false)
    const [path,setPath] = useState('')
    useEffect(()=>{
        if(localStorage.getItem('EGMUserId') && localStorage.getItem('EGMUserType') == 'super_admin'){
            setIsActive(true)
            setCurrentUser("super_admin")
            setPath('/super-admin')
        }else if(localStorage.getItem('EGMUserId') && localStorage.getItem('EGMUserType') == 'admin'){
            setIsActive(true)
            setCurrentUser("admin")
            setPath('/admin')
        }
        if(path !== '' && isActive){
            nav(path)
        }else{
            return;
        }
    },[path,isActive])

    const handleLogin = async (e:any) => {
        e.preventDefault()
        setIsLoading(true)
        const userData = await postData('https://api-egm.conservatoire-admin.com/api/responsables/login', {"telephone_resp":number,"mdp_resp":password});
        
        if(userData.status == 200){
            
            if(userData.data.type_resp == "super_admin"){
                localStorage.setItem('EGMUserIsLoggedIn', "true");
                localStorage.setItem('EGMUserEmail',userData.data.email_resp);
                localStorage.setItem('EGMUserCentre',userData.data.id_centre_centres);
                localStorage.setItem('EGMUserId',userData.data.id_resp);
                localStorage.setItem('EGMUserToken',userData.data.token);
                localStorage.setItem('EGMUserName',userData.data.nom_resp);
                localStorage.setItem('EGMUserLastName',userData.data.prenom_resp);
                localStorage.setItem('EGMUserPhone',userData.data.telephone_resp);
                localStorage.setItem('EGMUserType',userData.data.type_resp);
                
                if(localStorage.getItem("EGMUserId") && localStorage.getItem("EGMUserType")){
                    nav("/super-admin");
                }
                
            }
            else if (userData.data.type_resp == "admin"){
                localStorage.setItem('EGMUserIsLoggedIn', "true");
                localStorage.setItem('EGMUserEmail',userData.data.email_resp);
                localStorage.setItem('EGMUserCentre',userData.data.id_centre_centres);
                localStorage.setItem('EGMUserId',userData.data.id_resp);
                localStorage.setItem('EGMUserToken',userData.data.token);
                localStorage.setItem('EGMUserName',userData.data.nom_resp);
                localStorage.setItem('EGMUserLastName',userData.data.prenom_resp);
                localStorage.setItem('EGMUserPhone',userData.data.telephone_resp);
                localStorage.setItem('EGMUserType',userData.data.type_resp);
                
                if(localStorage.getItem("EGMUserId") && localStorage.getItem("EGMUserType")){
                    nav("/admin");
                }
            }
        } else {
            setIsLoading(false)
            setError(userData.data)
        }
    }
    
    return(
        <div  className='bodybar' style={{
            height:window.innerHeight,
            backgroundColor:'white'
        }}>
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                        <img src={loginImg} alt="Image" className="img-fluid h-100"/>
                    </div>
                    <div className="col-md-6 col-lg-6 contents">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="mt-5">
                                    <h3 className="text-gray-700">EGM Administration</h3>
                                </div>
                                <ViewError error={error}/>
                                <form onSubmit={ handleLogin }>
                                    <div className="form-group first">
                                        <input type="number" placeholder="Numero" className="form-control" id="username" onChange={(e)=>setNumber(e.target.value)}/>
                                    </div>
                                    <br></br>
                                    <div className="form-group last mb-4">
                                        <input type="password"placeholder="Mot de passe" className="form-control" id="password" onChange={(e)=>setPassword(e.target.value)}/>
                                    </div>
                                    {
                                        !isLoading ?
                                            <input type="submit" className="btn btn-block btn-primary mt-5" value="Se connecter" />
                                        :
                                            <button disabled className="btn btn-block btn-primary mt-5">Chargement...</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Login;