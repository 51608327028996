import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import https from '../../helpers/https';
import { decode } from '../../services/slugs';
import { generateDate, fromatDate } from "../../services/date/date";
import { getHeader } from '../../helpers/getHeader';


const AllRecipe = () => {

    const [allRecipe, setAllRecipe] = useState([] as any);
    const [centre, setCentre] = useState({} as any)
    const id_centre_centres = useParams<string>();
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [selectRec, setSelectRec] = useState<any>()
    const date = useRef<any>()
    const jour = useRef<any>()
    const design = useRef<any>()
    const recet = useRef<any>()
    const dep = useRef<any>()
    const obs = useRef<any>()
    const freq = useRef<any>()
    const initialState = [{
        "jour" : "",
        "date_rec" : "dd/mm/yyyy",
        "designation": "",
        "recettes": 0,
        "depenses": 0,
        "observation": "",
        "frequentation": 0
    }]
    const [oneRecipe, setOneRecipe] = useState<any>(initialState)
    const [search, setSearch] = useState<any>("");
    const [isQ, setIsQ] = useState<boolean>(false);

    const fetchOneRecipe = async (reportId : number)=>{
        const rep = await https.get(`/recettes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${reportId}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(rep){
            setOneRecipe(rep.data);
        }
    }

    const fetchRecipes = async() =>{
        try {
            const recipes = await https.get(`/recettes/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${decode(id_centre_centres.idRecipe)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(recipes){
                setAllRecipe([...recipes.data])
            }
        } catch (error) {
            console.log(error)
        }
        
    }
    const fetchCentre = async() =>{
        try {
            const centres = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${decode(id_centre_centres.idRecipe)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(centres){
                setCentre(centres.data[0])
            }
        } catch (error) {
            console.log(error)
        }
    }
    const searchRecipe = async () => {
            if(search != ""){
                try{
                    let queryData = await https.get(`/recettes/date_centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${encodeURI(search)}/${decode(id_centre_centres.idRecipe)}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(queryData){
                        setAllRecipe(queryData.data);
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
            else {
                fetchRecipes()
                .catch(error => console.log(error));
            }
    }

    useEffect(()=>{
        if(isQ){
            searchRecipe()
        }
    },[search])

    useEffect(()=>{
        fetchRecipes()
            .catch(error => console.log(error));
        fetchCentre()
            .catch(error => console.log(error));
    },[])

    const open = (id:string,slect:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setSelectRec(slect)
        fetchOneRecipe(slect)
        .catch(e => console.log(e))
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    useEffect(()=>{
        jour.current.value = oneRecipe[0].jour
        date.current.value = fromatDate(oneRecipe[0].date_rec)
        design.current.value = oneRecipe[0].designation
        recet.current.value = oneRecipe[0].recettes
        dep.current.value = oneRecipe[0].depenses
        obs.current.value = oneRecipe[0].observation
        freq.current.value = oneRecipe[0].frequentation
    },[oneRecipe])

    const deleteRecipe = (e:any,id:string) =>{
        e.preventDefault()
        https.delete(`/recettes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${selectRec}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        .then((res) =>{
            if(res.status === 200){
                fermer(id)
                fetchRecipes()
                .catch(error => console.log(error));
            }
        })
        .catch((e)=>{
            console.log(e)
        })
    }

    const modifyRec = (e:any,id:string) => {
        e.preventDefault()
        let data = {
            "jour" :  jour.current.value,
            "date_rec" : date.current.value,
            "designation": design.current.value,
            "recettes": recet.current.value,
            "depenses": dep.current.value,
            "observation": obs.current.value,
            "frequentation": freq.current.value,
            "id_centre_centres": oneRecipe[0].id_centre_centres,
            "id_rec" : selectRec
        }
        
        try{
            https.put(`recettes/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, data,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then(()=>{
                fetchRecipes()
                .catch(error => console.log(error));
                fermer(id)
            })
            .catch((e)=> console.log(e))
        }
        catch(e){
            console.log(e)
        }
    }

    const prev  = () =>{
        if(index > 2){
            setIndex2(index -10)
            setIndex(index - 1)
        }else{
            setIndex(1)
            setIndex2(0)
        }
        
    }
    const next = () =>{
        if( index < Math.round(allRecipe.length/10)){
            setIndex(index + 1)
            setIndex2(index  + 10)
        }else{
            setIndex(1)
            setIndex2(0)
        }
    }


    const current = (index: any) =>{
        setIndex(index)
        setIndex2(index  + 10)
    }

    const disabled = (status: boolean) =>{
        if(status){
            if(index > Math.round(allRecipe.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }
    return (
        <div className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Recettes</h1>
                        <Link to="/super-admin/recette" className="btn btn-sm btn-success "><i className="fas fa-fw fa-reply"></i> Retour</Link>
                </div>
            {/* Begin */}
            <div className="row">
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header d-flex justify-content-between py-3">
                                <div className="mt-2">
                                    Centres : {centre?.nom_centre}
                                </div>
                                <div>
                                    <input type="date"  onChange={(e:any)=> {setSearch(e.target.value); setIsQ(true)}} className="form-control"/>
                                </div>
                            </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                     <table className="table table-bordered table-responsive-xl table-striped" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Jour</th>
                                                <th>Designation</th>
                                                <th>Recettes</th>
                                                <th>Depenses</th>
                                                <th>OBS</th>
                                                <th>Fréquentation</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allRecipe.filter((value:any,i:any) => {
                                                    return value && i >= index2 && i < (index * 10)
                                                }).map((value:any,index:any)=>(
                                                    <tr key={index.toString()}>
                                                        <td>{value.date_rec && value.date_rec!=='null' ?  generateDate(value.date_rec) :  "Aucun"} </td>
                                                        <td>{value.jour && value.jour!=='null' ?  value.jour :  "Aucun"}</td>
                                                        <td>{value.designation && value.designation!=='null' ?  value.designation :  "Aucun"}</td>
                                                        <td>{value.recettes && value.recettes!=='null' ?  value.recettes :  "Aucun"}</td>
                                                        <td>{value.depenses && value.depenses!=='null' ?  value.depenses :  "Aucun"}</td>
                                                        <td>{value.observation && value.observation!=='null' ?  value.observation :  "Aucun"}</td>
                                                        <td>{value.frequentation && value.frequentation!==0 ?  value.frequentation :  "Aucun"}</td>
                                                        <td>
                                                            <button onClick={() =>open('mymodal',value.id_rec)} className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></button>
                                                           <button onClick={() =>open('logoutModal',value.id_rec)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-trash-alt"></i></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            
                                        </tbody>
                                    </table>
                                    {allRecipe.length > 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                        <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                        { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index)}>{index}</span></li>
                                        {index + 1 <= Math.round(allRecipe.length/10) && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1)}>{index + 1}</span></li>}
                                        { index + 2 <= Math.round(allRecipe.length/10) && <li className={"page-item"+active(index,2)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index+2)}>{index + 2}</span></li>}
                                        {Math.round(allRecipe.length/10) > 2 && index < Math.round(allRecipe.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(allRecipe.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                                    </ul>}
                                </div>
                            </div>
                        </div>
                        {/* Modals */}
                        <div className="modal" id="logoutModal">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content" style={{
                                    width: '100%',
                                    marginTop:'150px',
                                }} >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Attention! </h5>
                                        
                                    </div>
                                    <div className="modal-body" style={{
                                        padding:'20px'
                                    }}>Voulez vous vraiment supprimer cette recette ?</div>
                                    <div className="modal-footer">
                                        <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                        <a className="btn btn-danger btn-sm" href="" onClick={(e) => deleteRecipe(e,"logoutModal")}>Supprimer</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="mymodal" className="modal">
                            <form className="modal-content animate"
                                action='' method="post" style={{
                                    marginTop: '70px'
                                }}>
                                <div className="modal-header">
                                    <h5 className="modal-title"><strong>Modifier</strong></h5>
                                </div>
                                <div className="imgcontainer">
                                    <span onClick={() => fermer('mymodal')} className="close" title="Close Modal">
                                        &times;</span>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <div className="row" style={{ marginTop: "5px" }}>
                                            <div className="col-lg-6">
                                                <label>Date</label>
                                                <input type="date"  ref={date} className="form-control" name="date_deb" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Jour</label>
                                                <select  ref={jour} className="form-control">
                                                    <option value="Lundi">Lundi</option>
                                                    <option value="Mardi">Mardi</option>
                                                    <option value="Mercredi">Mercredi</option>
                                                    <option value="Jeudi">Jeudi</option>
                                                    <option value="Vendredi">Vendredi</option>
                                                    <option value="Samedi">Samedi</option>
                                                    <option value="Dimanche">Dimanche</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "5px" }}>
                                            <div className="col-lg-6">
                                                <label style={{ marginTop: "5px" }}>Designation</label>
                                                <input type="text"  ref={design} className="form-control" name="date_fin" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label style={{ marginTop: "5px" }}>Recettes</label>
                                                <input type="number"  ref={recet} className="form-control" name="time_fin" />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "5px" }}>
                                            <div className="col-lg-6">
                                                <label style={{ marginTop: "5px" }}>Dépenses</label>
                                                <input type="number"  ref={dep} className="form-control" name="date_fin" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label style={{ marginTop: "5px" }}>OBS</label>
                                                <input type="text"  ref={obs} className="form-control" name="time_fin" />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "5px" }}>
                                            <div className="col-lg-12">
                                                <label style={{ marginTop: "5px" }}>Fréquentation</label>
                                                <input type="text"  ref={freq} className="form-control" name="time_fin" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={(e) => modifyRec(e, 'mymodal')} className="btn btn-sm btn-primary" type="submit"><i className="fas  fa-save"></i> Enregistrer</button>
                                </div>
                            </form>
                        </div>
                    </div>
            </div>
            {/* end */}
        </div>
    );
}
export default AllRecipe;