import React, { useState, useEffect, useRef } from 'react';
import https from '../helpers/https';
import { useParams } from "react-router-dom";
import ViewError from '../services/ViewError';
import { getHeader } from '../helpers/getHeader';
import httpsFiles from '../helpers/httpsFiles';
import { generateDate } from '../services/date/date';

const Inventaires = () => {
    const designation = useRef<any>()	
    const [allinventaires,setallinventaires] = useState([] as any);
    const numero = useRef<any>()	
    const etat = useRef<any>()
    const nombres = useRef<any>()
    const prix = useRef<any>()
    const aquis_date = useRef<any>()
    const caractor = useRef<any>()
    const [Inventaires, setInventaires] = useState([] as any)
    const centreId = useParams();
    const [error, setError ] = useState<String>();
    const [isSend, setIsSend] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)

    const [url_image,setUrl_image] = useState(null as any)
    const [url_image2,setUrl_image2] = useState(null as any)
   

    const fetchInventaire = async () => {
        await https.get(`/inventaires/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${localStorage.getItem("EGMUserCentre")}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then((res)=>{
                    setIsLoading(false)
                    setallinventaires(res.data)
                })
                .catch(e => console.log(e))
    }
    
    const sendInventaire = async (e:any) => {
        e.preventDefault()
        if((designation.current.value == "")||(numero.current.value == "")||(!etat.current.value)||(!nombres.current.value)||(!prix.current.value)||(aquis_date.current.value == "")){
            setError("Veuillez remplir les champs suivants: désignation, numéro, etat, nombres, prix, date")
        }
        else{
            setIsSending(true)
            setIsLoading(true)
            let data: { [key: string]: any }  = {
                "designation": designation.current.value,
                "numero": numero.current.value,
                "etat": etat.current.value,	
                "nombres": parseInt(nombres.current.value),
                "prix": parseInt(prix.current.value),		
                "id_centre_centres": parseInt(localStorage.getItem("EGMUserCentre")!),
                "date_aquis": new Date(aquis_date.current.value).toISOString().slice(0, 19).replace('T', ' '),
                "caracteristique": caractor.current.value,
            }

            let formData = new FormData();

            for(let i in data){
              formData.append(`${i}`,data[i])
            }
            if(url_image){
                formData.append(`inv_image`, url_image)
            }

            await httpsFiles.post(`/inventaires/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, formData,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                .then(()=>{
                    setIsSend(true)
                    setIsSending(false)
                    designation.current.value = ""
                    numero.current.value = ""
                    etat.current.value = ""	
                    nombres.current.value = null
                    prix.current.value = null	
                    caractor.current.value = ""
                    aquis_date.current.value = ""
                    setUrl_image(null)
                    setUrl_image2(null)
                    fetchInventaire()
                    .catch((e)=> console.log(e))
                })
                .catch(e => {return e})
        }
    }

    useEffect(()=>{
        fetchInventaire()
            .catch((e)=> console.log(e))
    },[])

 
  

    return (
        <div  className='container-fluid bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
                {/* Beginn */}
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h4 className="m-0 font-weight-bold text-primary">Ajouter un inventaire</h4>
                                </div>
                                <div className="card-body">
                                <h6 className="m-0 font-weight-bold text-primary">Numéro de série*</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="text" className="form-control" name='numero' ref={numero}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Désignation*</h6>
                                 <br/>
                                <div className="input-group">
                                <input type="text" className="form-control" name='designation' ref={designation} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Date d'acquisition*</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="date" className="form-control" name='numero' ref={aquis_date}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Caractéristique</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name='etat' ref={caractor}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                {isSend ? '' : <ViewError error={error}/> }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4"> 
                        <div className="card-body">                
                        <h6 className="m-0 font-weight-bold text-primary">Image</h6>
                        <br />
                                                    <div className="input-group">
                                                        <input type="file"  accept="image/*"  
                                                         lang="fr-BE"
                                                         
                                                         onChange={(e:any)=>{
                                                            setUrl_image(e.target.files[0])
                                                            setUrl_image2(URL.createObjectURL(e.target.files[0]))
                                                            var fileName = e.target.value.split("\\").pop();
                                                            let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                            element?.classList.add("selected")
                                                            element.innerHTML = String(fileName).slice(0,15) + '...'
                                                        }}
                                                        className="custom-file-input form-control" id="customFile"/>
                                                        <label className="custom-file-label" htmlFor="#customFile">Choisir une image</label>
                                                    </div>
                                                    
                                                    {url_image2 && (
                                                        <div>
                                                            <img className='mt-1' width={150} height={150} src={url_image2} alt=""  />
                                                            <div className="" style={{
                                                                position:'absolute',
                                                                top:'2%',
                                                                right:'0',

                                                            }}>
                                                                <span style={{ color:'red'}} onClick={() =>{
                                                                    setUrl_image2(null)
                                                                    setUrl_image(null)
                                                                    let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                                    element?.classList.remove("selected")
                                                                    element.innerHTML = "Choisir une image"

                                                                }}      className="close" title="Supprimer">
                                                                    &times;</span>
                                                            </div>
                                                        </div>
                                                    )}
                        <br />
                        <h6 className="m-0 font-weight-bold text-primary">Nombres*</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" className="form-control" name="designation" ref={nombres} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                
                                <h6 className="m-0 font-weight-bold text-primary">Prix*</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="number" className="form-control" name="prix" ref={prix} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br/>
                                <h6 className="m-0 font-weight-bold text-primary">Etat*</h6>
                                <br/>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name='etat' ref={etat}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-th-large"></i></span>
                                    </div>
                                </div>
                                <br />
                                <a className="btn btn-sm btn-success float-right" href="" onClick={(e:any) => sendInventaire(e)}><i className="fas fa-fw fa-save"></i> Envoyer</a>
                                <br/>
                                {
                                    isSending && <div className="alert text-center alert-loading mb-4 mt-4" role="alert">
                                        Chargement...
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                 {/* modals */}
        {/* Begin */}
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="card shadow mt-0">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Listes des inventaires
                </h6>
              </div>
              <div className="widget-box">
                <div className="widget-content nopadding">
                  <table
                    className="table table-bordered table-responsive-xl full-width  table-striped"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Désignation</th>
                        <th>Numéro de série</th>
                        <th>Etat</th>
                        <th>Date d'acquisition</th>
                        <th>Caractéristique</th>
                        <th>nombres</th>
                        <th>Prix</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading && <tr>
                            <td className='text-center' colSpan={9}>
                                Chargement...
                            </td>
                        </tr>
                    }
                    {allinventaires.map((inv: any) => (
                        <tr key={inv.id_inv}>
                          <td>{inv.designation}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.numero}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.etat}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {generateDate(inv.date_aquis)}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.caracteristique}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {inv.nombres}
                          </td>
                          <td>{inv.prix}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
      </div>
    );
}
export default Inventaires;