import React from "react";

const TableWraper = ({ children }: any) => {
  return (
    <div className="col-lg-12 mb-5">
      <div className="card shadow mt-0">
        <div className="card-header py-3">
          <div className="row">
            <div className="col-lg-6 pt-2">
              <h6 className="font-weight-bold text-primary">
                Suivis des Ecolages Academique
              </h6>
            </div>
          </div>
        </div>
        <div className="widget-box">
          <div className="widget-content nopadding">
            <table
              className="table table-bordered table-responsive-xl  table-striped"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Numéro</th>
                  <th>Prénom</th>
                  <th>Matricule</th>
                  <th>Centres</th>
                  <th>Téléphone</th>
                  <th>Trimestre</th>
                  <th>Payé</th>
                  <th>Ecolage</th>
                  <th>Reste</th>
                  {/* <th>Premier</th>
                  <th>Compare</th> */}
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableWraper;
