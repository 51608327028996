import React, { useState, useEffect, createContext } from 'react';
import { getCookie } from './cookies/dataCookies';

const UserContext = createContext([] as any);

export const UserProvider = ({ children }:any) => {
	const [ currentUser, setCurrentUser ] = useState('');
	useEffect(() => {
		const checkLoggedIn = async () => {
            let user = ''
			if(localStorage.getItem('EGMUserId') && localStorage.getItem('EGMUserType') == 'super_admin'){
                user = 'super_admin'
            }else if(localStorage.getItem('EGMUserId') && localStorage.getItem('EGMUserType') == 'admin'){
                user = 'admin'
            }
			setCurrentUser(user);
		};

		checkLoggedIn();
	}, []);

	return (
		<UserContext.Provider value={[currentUser, setCurrentUser]}>
			{children}
		</UserContext.Provider>
	);
};


export default UserContext;