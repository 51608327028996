import React,{ useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import https from '../helpers/https';
import ChooseCenter from './studentsComponents/chooseCenter';
import { encode } from '../services/slugs';
import { getHeader } from '../helpers/getHeader';
import ChooseCoursType from './studentsComponents/chooseCoursType';
const Students = () => {
    const [allCentre, setallCentre] = useState([] as any);
    const [allStudents, setAllStudents] = useState([] as any);
    const [tmpTab, setTmpTab] = useState([] as any);
    const [centerFilter, setCenterFilter] = useState('*');
    const [eleve,setEleve] = React.useState({} as any)
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState<any>("");
    const [isQ, setIsQ] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)
    const [coursFilter, setCoursFilter] = useState("tout")
    const motif_quitter = useRef<any>();
    const date_quitter = useRef<any>();
    const nav = useNavigate()

    const split = (s: string) =>{
        if(s.length > 8){
            return s.slice(0,8) + "..."
        }else{
            return s
        }
    }
    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data);
        }
    }
    const fetchStudents = async (filter: string)=>{
        setIsLoading(true)
        if(filter == '*'){
            const s = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(s){
                if(coursFilter == "tout") setAllStudents(s.data);
                else {
                    let newData = s.data.filter((eleve:any) => {
                        return parseInt(eleve.status) && eleve.type_cycle == coursFilter && eleve
                    })
                    setAllStudents(newData)
                }
                setIsLoading(false)
            }
        }
        else{
            const s = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${encodeURI(filter)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            if(s){
                if(coursFilter == "tout") setAllStudents(s.data);
                else {
                    let newData = s.data.filter((eleve:any) => {
                        return parseInt(eleve.status) && eleve.type_cycle == coursFilter && eleve
                    })
                    setAllStudents(newData)
                }
                setIsLoading(false)
            }
        }
       
    }

    const fetchSearch = async () => {
        setIsLoading(true)
        if(search != ""){
            try{
                let queryData = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/search/${encodeURI(search)}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(queryData)
                    setAllStudents(queryData.data);
                    setIsLoading(false)
            }
            catch(e){
                console.log(e)
            }
        }
        else {
            fetchStudents(centerFilter)
            .catch(error => console.log(error));
        }
    }


    useEffect(()=>{
        if(isQ){
            fetchSearch()
        }
    },[search])
    
    const prev  = () =>{
        if(index > 2){
            setIndex(index - 1)
            setIndex2((index - 1)*10)
            setLimit(index * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }
    const next = () =>{
        if( index <= Math.round(allStudents.length/10)){
            setIndex(index + 1)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }


    const current = (index: any,value: any) =>{
        if(parseInt(String(allStudents.length/10).split('.')[1][0]) < 5){
            setIndex(index)
            setIndex2((index-value) * 10)
            setLimit((index) * 10)
        }else{
            setIndex(index)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }
    }

    const disabled = (status: boolean) =>{
        if(status){
            if(index >= Math.round(allStudents.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }
    useEffect(() => {
        fetchStudents(centerFilter);
    },[centerFilter, coursFilter])

    useEffect(()=> {
        fetchCentre()
            .catch(error => console.log(error));
        fetchStudents('*')
            .catch(error => console.log(error));
    },[])

    const open = (id:string,eleve:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setEleve(eleve)
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }
    const deleteStudent = (e:any,id:string) =>{
        e.preventDefault()
        if (eleve && eleve.id_el) {
            https.delete(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${eleve.id_el}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then((res) =>{
                if(res.status === 200){
                    fetchStudents('*')
                        .then(() =>{fermer(id)})
                        .catch((e:any)=>console.log(e))
                }
            })
            .catch((e)=>{
                console.log(e)
            })
        }
    }
    const dynamiqueTab = (index2:any,limit:any,tab:any) =>{
        let tmp = []
        for (let i = index2; i <= limit; i++) {
            if(i >= 0 && i < tab.length){
                const element = tab[i];
                tmp.push(element)
            }
        }
        return tmp
    }

    useEffect(()=>{
        if(limit){
            let result = dynamiqueTab(index2,limit,allStudents)
            setTmpTab([...result])
        }
    },[limit,index,index2,allStudents])

    const registerQuitter = (e: any, id: string) => {
        e.preventDefault();
    
        try {
          https
            .post(`/eleves/quitter/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
              id_el: eleve.id_el,
              motifs: motif_quitter.current.value,
              date_abondant: new Date(date_quitter.current.value).toISOString().slice(0, 19).replace('T', ' ')
            },{
              headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then((res) => {
              if (res.status === 200) {
                if (localStorage.getItem("EGMUserType") === "super_admin") {
                  nav(`/super-admin/liste-des-eleves-quitter`);
                } else if (localStorage.getItem("EGMUserType") === "admin") {
                  nav(`/admin/liste-des-eleves-quitter`);
                }
                fermer(id);
              }
            });
        } catch (e) {
          console.log(e);
        }
      };

    return (
    <div className='bodybar mt-0 pt-4' style={{
        height:window.innerHeight-70
    }}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <ChooseCenter setFilter = {setCenterFilter}/>
                </div>
                <div className="col-md-6">
                    <ChooseCoursType setCoursFilter={setCoursFilter} />
                </div>
            </div>
             {/* Begin */}
             <div className="row">
                    <div className="col-lg-12 mb-5">
                        <div className="card shadow mt-0">
                            <div className="card-header d-flex justify-content-between py-3">
                                <div className="row">
                                    <div className="col-lg-6 pt-2">
                                        <h6 className="font-weight-bold text-primary">Listes des El&egrave;ves</h6>
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" placeholder="Rechercher..."  onChange={(e:any)=> {setSearch(e.target.value); setIsQ(true)}} className="form-control"/>
                                    </div>
                                </div>
                                <div className='pt-1'>
                                    <Link to={"/super-admin/inscription"} className="btn btn-sm btn-primary "><i className="fas fa-fw fa-plus"></i></Link>
                                </div>
                            </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                    <table className="table table-bordered table-responsive-xl  table-striped" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Numéro</th>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Nationalité</th>
                                                <th>Matricule</th>
                                                <th>Centres</th>
                                                <th>Téléphone</th>
                                                <th>Cours</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Chargement...
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                !isLoading && tmpTab.length == 0 && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Aucun élève
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                tmpTab.filter((value:any,i:any) => {
                                                    return parseInt(value.status) && value
                                                }).map((el:any)=>(
                                                    <tr key={el.id_el}>
                                                        <td >{el.numero}</td>
                                                        <td style={{ textTransform:'capitalize'}}>{split(el.nom)}</td>
                                                        <td style={{ textTransform:'capitalize'}}>{split(el.prenom)}</td>
                                                        <td style={{ textTransform:'capitalize'}}>{el.nationalite && el.nationalite!=='null' ?  el.nationalite :  "Aucun"}</td>
                                                        <td>{el.matricule && el.matricule!=='null' ?  el.matricule :  "Aucun"}</td>
                                                        <td>{el.nom_centre && el.nom_centre!=='null' ?  split(el.nom_centre) :  "Aucun"}</td>
                                                        <td>{el.telephone && el.telephone!=='null' ?  el.telephone :  "Aucun"}</td>
                                                        <td>{el.type_cycle.slice(0,15)}</td>
                                                        <td className='mt-0 pt-0 pb-0 d-flex'>
                                                            <Link to={"/super-admin/a-propos/"+encode(el.id_el)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                                                            {localStorage.getItem('EGMUserType') === 'super_admin' &&<Link to={"/modifier/eleves/"+ encode(el.id_el)} className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></Link>}
                                                            <button onClick={() =>open('quitterModal',el)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-arrow-right"></i></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {allStudents.length >= 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index,0)}>{index}</span></li>
                                {index + 1 <= Math.round(allStudents.length/10) + 1 && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1,1)}>{index + 1}</span></li>}
                                {Math.round(allStudents.length/10) > 2 && index < Math.round(allStudents.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(allStudents.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                            </ul>}
                           
                        </div>
                       
                    </div>
                    
            </div>
            {/* end */}
            {/* Modals */}
            <div id="quitterModal" className="modal">
                <form
                    className="modal-content animate"
                    action=""
                    method="post"
                    style={{
                    marginTop: "70px",
                    }}
                >
                    <div className="modal-header">
                    <h5 className="modal-title">
                        <strong>Cet élève a quitté le centre ?</strong>
                    </h5>
                    </div>
                    <div className="imgcontainer">
                    <span
                        onClick={() => fermer("quitterModal")}
                        className="close"
                        title="Close Modal"
                    >
                        &times;
                    </span>
                    </div>
                    <div className="modal-body">
                    <div className="container">
                        <label>Date</label>
                        <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-lg-12">
                            <input
                            type="date"
                            style={{ marginTop: "5px" }}
                            ref={date_quitter}
                            className="form-control"
                            name="motifs"
                            />
                        </div>
                        </div>
                        <label className="mt-3">Motifs</label>
                        <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-lg-12">
                            <textarea
                            rows={4}
                            style={{ marginTop: "5px" }}
                            ref={motif_quitter}
                            className="form-control"
                            name="motifs"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <button
                        onClick={(e) => registerQuitter(e, "quitterModal")}
                        className="btn btn-sm btn-danger"
                        type="submit"
                    >
                        <i className="fas  fa-save"></i> Valider
                    </button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}
export default Students;