import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import https from "../helpers/https";
import profile from "../img/Group126@2x.png";
import profil from "../img/r.png";
import "../../styles/fromTop.css";
import { encode } from "../services/slugs";
import { getHeader } from "../helpers/getHeader";

const Inventaires = () => {
  const [allCentre, setallCentre] = useState([] as any);
  const [list, setList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false)

  const fetchCentre = async () => {
    try {
      setIsLoading(true)
      const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
    });
      if (centers) {
        const data = [] as any;
        setList(data);
        setallCentre(centers.data);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
      fetchCentre().catch((error) => console.log(error));
  }, []);
  return (
    <div
      className="container-fluid bodybar mt-0 pt-0"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <div className="col-sm-12 col-xl-12 stretch-card grid-margin">
        <div className="card color-card-wrapper">
          <div className="card-body">
           {/* <div className="d-flex justify-content-center">
              <img className="img-fluid card-top-img" src={profil} alt="" />
    </div>*/}
            {
                isLoading && <div className="alert text-center alert-loading mb-4" role="alert">
                    Chargement...
                </div>
            }
            <div className="d-flex flex-wrap mt-3 justify-content-around color-card-outer">
              <div className="row mt-2">
                {allCentre.map((element: any, index: any) => (
                  <Link
                    key={index.toString()}
                    to={`/super-admin/inventaire-centre/${encode(element.id_centre)}`}
                    className="col-xl-3 col-md-6 mb-4"
                  >
                    <div
                      className="card border-bottom-primary  shadow h-100 py-2 overlay"
                      id="about"
                    >
                      <div className="card-body  ">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-2x font-weight-bold text-primary text-uppercase mb-1">
                              {list[index]} inventaires
                            </div>
                            <div className="h6 mb-0 font-weight-bold text-gray-800">
                              {element.nom_centre}
                            </div>
                          </div>

                          <div className="col-auto">
                            <i className="fas fa-book-open fa-2x text-gray-400"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end of container fluid */}
    </div>
  );
};
export default Inventaires;
