import React, { useRef, useState, useEffect } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom';
import https from '../helpers/https';
import httpsFiles from '../helpers/httpsFiles';
import { decode } from '../services/slugs';
import ViewError from '../services/ViewError';
import { isEmailValid } from '../services/regex';
import { getHeader } from '../helpers/getHeader';

const DisciplinesOptions = ({el}:any)=> {
    return( <option value={el.id_dis} style={{textTransform: "capitalize"}}>{el.nom_dis}</option> );
}

const Inscription = () => {
    const inputAnne = useRef<any>();
    const inputImg = useRef<any>();
    const inputName = useRef<any>();
    const inputLastN = useRef<any>();
    const inputLdn = useRef<any>();
    const inputDdn = useRef<any>();
    const inputCycle = useRef<any>();
    const inputPhone = useRef<any>();
    const inputEmail = useRef<any>();
    const inputCin = useRef<any>();
    const inputNat = useRef<any>();
    const inputAdr = useRef<any>();
    const inputProf = useRef<any>();
    const inputEo = useRef<any>();
    const inputParent = useRef<any>();
    const inputAdrP = useRef<any>();
    const inputPhoneP = useRef<any>();
    const inputDis = useRef<any>();
    const inputNiv = useRef<any>();
    const inputClass = useRef<any>();
    const inputTotal = useRef<any>();
    const eco_t2 = useRef<any>();
    const eco_t3 = useRef<any>();
    const eco_t4 = useRef<any>();

    const autre_pay = useRef<any>();
    const inputPhone2 = useRef<any>();
    const inputPhoneP2 = useRef<any>();
    const date_cours = useRef<any>();

    const [url_image,setUrl_image] = useState(null as any)
    const [url_image2,setUrl_image2] = useState(null as any)
    const [allCentre, setallCentre] = useState([] as any);
    const [allDiscipline, setallDiscipline] = useState([] as any);
    const [eleve, setEleve] = useState([] as any);
    const [error, setError ] = useState<String>();
    const selected_centre = useRef<any>();

    const navigate = useNavigate()
    const eleve_id = useParams<string>()
    const [isLoading, setIsLoading] = useState(false)

    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data);
            if(!eleve_id.id){
                selected_centre.current.value = centers.data[0].nom_centre
            }
        }
    }
    const fetdisciplines = async ()=>{
        const disciplines = await  https.get(`/disciplines/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(disciplines){
            setallDiscipline(disciplines.data);
        }
    }

    const fetchEleve = async (id: any)=>{
        const eleves = await  https.get(`/eleves/info_global/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(eleves){
            setEleve(eleves.data);
        }
    }


    useEffect(()=>{
        if(localStorage.getItem('EGMUserType') !== 'super_admin' && decode(eleve_id?.id)){
            navigate("/admin/liste-des-eleves")
        }
        fetchCentre()
            .catch(error => console.log(error));
        fetdisciplines()
            .catch(error => console.log(error));
        if(decode(eleve_id?.id)){
            fetchEleve(decode(eleve_id.id))
                .catch(error => console.log(error));
        }
    },[])

    useEffect(()=>{
        if(eleve.length){
            date_cours.current.value = fromatDate(eleve[0].date_cours)
            autre_pay.current.value = eleve[0].autre_paiement
            inputPhone2.current.value = eleve[0].telephone2
            inputPhoneP2.current.value = eleve[0].telephone_parents2
            inputTotal.current.value = parseInt(eleve[0].ecolage_total) 
            eco_t2.current.value = parseInt(eleve[0].ecolage_t2) 
            eco_t3.current.value = parseInt(eleve[0].ecolage_t3) 
            eco_t4.current.value = parseInt(eleve[0].ecolage_t4) 
            inputAnne.current.value= eleve[0].annee_scolaire 
            inputDdn.current.value =fromatDate(Date.parse(eleve[0].date_nais))
            inputName.current.value = eleve[0].nom 
            inputLastN.current.value= eleve[0].prenom 
            inputEo.current.value = eleve[0].etablissement 
            inputLdn.current.value = eleve[0].lieu_nais 
            inputNat.current.value= eleve[0].nationalite 
            inputCin.current.value= eleve[0].cin 
            inputProf.current.value= eleve[0].profession 
            inputClass.current.value= eleve[0].classe 
            inputAdr.current.value= eleve[0].adresse 
            inputPhone.current.value= eleve[0].telephone 
            inputEmail.current.value= eleve[0].email 
            inputParent.current.value= eleve[0].parents 
            inputAdrP.current.value= eleve[0].adresse_parents 
            inputPhoneP.current.value= eleve[0].telephone_parents 
            inputNiv.current.value = parseInt(eleve[0].id_niv_niveau)
            inputDis.current.value = parseInt(eleve[0].id_dis_disciplines) 
            inputCycle.current.value = eleve[0].type_cycle 
            selected_centre.current.value = eleve[0].nom_centre

            if(eleve[0]?.url_image){
                setUrl_image2(eleve[0]?.url_image)
                let element = document.querySelector('.custom-file-label') as HTMLElement;
                element?.classList.add("selected")
                element.innerHTML = String(eleve[0]?.url_image?.split('/images/')[1])
            }
            if(!eleve[0]?.id_el){
                if(localStorage.getItem('EGMUserType') === 'super_admin'){
                    navigate("/super-admin/liste-des-eleves")
                }else if(localStorage.getItem('EGMUserType') === 'admin'){
                    navigate("/admin/liste-des-eleves")
                }
                
            }  
        }
    },[eleve])
    
    const verification = (nationalite : string) => {
        if(inputTotal.current.value){
            if(nationalite != 'malagasy')
                inputTotal.current.value = inputTotal.current.value * 2
            else
                inputTotal.current.value = inputTotal.current.value / 2
        }
    }

    const fromatDate =(date: any)=>{
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;
  
        return [year, month, day].join('-');
    }
    const id_matricule = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    const register = async (e:any) => {
        e.preventDefault()
        let centre = "???";
        if(localStorage.getItem('EGMUserType') === 'super_admin') {
            centre = selected_centre.current.value
        }
        else {
            let auth_centre = parseInt(localStorage.getItem("EGMUserCentre")!);
            allCentre.forEach((el : any) => {
                if(el.id_centre == auth_centre)
                    centre = el.nom_centre
            })
        }
       
        if(( inputName.current.value == "")||(inputLastN.current.value == "")||(inputTotal.current.value == "")||(eco_t2.current.value=="")||(eco_t3.current.value=="")||(eco_t4.current.value=="")||(parseInt(inputTotal.current.value) <= 0)||( inputAdr.current.value == "")||(date_cours.current.value == "")) {
            setError("Veuillez remplir respectivement les champs suivants: nom, prénom, date d'entré, adresse, écolages ")
        }
        else {
            setIsLoading(true)
            const data: { [key: string]: any } = {
                status : 1,
                motifs : "",
                ecolage_total :parseInt(inputTotal.current.value) ,
                ecolage_t2: parseInt(eco_t2.current.value),
                ecolage_t3: parseInt(eco_t3.current.value),
                ecolage_t4: parseInt(eco_t4.current.value),
                annee_scolaire: inputAnne.current.value ,
                date_nais: inputDdn.current.value ?  new Date(inputDdn.current.value): '',
                nom: inputName.current.value ,
                prenom: inputLastN.current.value ,
                etablissement: inputEo.current.value ,
                lieu_nais: inputLdn.current.value ,
                nationalite:inputNat.current.value ,
                cin: inputCin.current.value ,
                profession: inputProf.current.value ,
                classe: inputClass.current.value ,
                adresse: inputAdr.current.value ,
                telephone: inputPhone.current.value ,
                email: inputEmail.current.value ,
                parents: inputParent.current.value ,
                adresse_parents: inputAdrP.current.value ,
                telephone_parents: inputPhoneP.current.value ,
                id_niv_niveau: parseInt(inputNiv.current.value) ,
                id_dis_disciplines: parseInt(inputDis.current.value) ,
                type_cycle: inputCycle.current.value ,
                nom_centre: centre,
                date_cours:date_cours.current.value,
                autre_paiement : autre_pay.current.value || 0,
                telephone2 : inputPhone2.current.value,
                telephone_parents2 : inputPhoneP2.current.value,

            }
            let formData = new FormData();

            for(let i in data){
                formData.append(`${i}`,data[i])
            }
            if(url_image){
                formData.append(`url_image`,url_image)
            }
        
            let response: any ;
            
            if(eleve.length && decode(eleve_id.id)){
                if(localStorage.getItem('EGMUserType') === 'super_admin'){
                    formData.append('id_el',decode(eleve_id.id))
                    formData.append('id_eleve_discipline',eleve[0].id_eleve_discipline)
                    response = await httpsFiles.put(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, formData,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'file'),
                    }).catch(e => {return e})
                }else{
                    setIsLoading(false)
                    navigate("/admin/liste-des-eleves")
                }
            }else{
                response = await httpsFiles.post(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, formData,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'file'),
                }).catch(e => {return e})
            }
            if(response.status == 200){
                if(localStorage.getItem('EGMUserType') === 'super_admin'){
                    setIsLoading(false)
                    navigate(`/super-admin/liste-des-eleves`)
                }else if(localStorage.getItem('EGMUserType') === 'admin'){
                    setIsLoading(false)
                    navigate(`/admin/liste-des-eleves`)
                }
                
            }
        }
    }

    return(
    <div className='bodybar mt-0 pt-4' style={{
        height:window.innerHeight-70
    }}>
        {/* <!-- Begin Page Content --> */}
        <div className="container-fluid">
            {/* <!-- Page Heading --> */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Inscription</h1>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="card shadow mb-4">                 
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h4 className="m-0 font-weight-bold text-primary">Information Personnelle</h4>
                            </div>
                            <div className="card-body">
                                    <div className="row">
                                        <ViewError error={error}/>
                                        <div className="col-md-6">
                                            <h6 className="m-0 font-weight-bold text-primary">Année Scolaire</h6>
                                                    <select ref={inputAnne} className="form-control" >
                                                        {/* <option value={`${new Date(Date.now()).getFullYear()}-${new Date(Date.now()).getFullYear()+1}`}>{`${new Date(Date.now()).getFullYear()}-${new Date(Date.now()).getFullYear()+1}`}</option> */}
                                                        <option value="2022-2023">2022-2023</option>
                                                        <option value="2023-2024">2023-2024</option>
                                                        <option value="2024-2025">2024-2025</option>
                                                        <option value="2025-2026">2025-2026</option>
                                                        <option value="2026-2027">2026-2027</option>
                                                        <option value="2027-2028">2027-2028</option>
                                                    </select>
                                        </div>
                                        <div className="col-md-6">
                                        <h6 className="m-0 font-weight-bold text-primary">Image</h6>
                                                    <div className="input-group">
                                                        <input type="file"  accept="image/*"  
                                                         lang="fr-BE"
                                                         
                                                         onChange={(e:any)=>{
                                                            setUrl_image(e.target.files[0])
                                                            setUrl_image2(URL.createObjectURL(e.target.files[0]))
                                                            var fileName = e.target.value.split("\\").pop();
                                                            let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                            element?.classList.add("selected")
                                                            element.innerHTML = String(fileName).slice(0,15) + '...'
                                                        }}
                                                        className="custom-file-input form-control" id="customFile"/>
                                                        <label className="custom-file-label" htmlFor="#customFile">Choisir une image</label>
                                                    </div>
                                                    
                                                    {url_image2 && (
                                                        <div>
                                                            <img className='mt-1' width={150} height={150} src={url_image2} alt=""  />
                                                            <div className="" style={{
                                                                position:'absolute',
                                                                top:'50px',
                                                                right:'65px',

                                                            }}>
                                                                <span style={{ color:'red'}} onClick={() =>{
                                                                    setUrl_image2(null)
                                                                    setUrl_image(null)
                                                                    let element = document.querySelector('.custom-file-label') as HTMLElement;
                                                                    element?.classList.remove("selected")
                                                                    element.innerHTML = "Choisir une image"

                                                                }}      className="close" title="Supprimer">
                                                                    &times;</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                   
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6 className="m-0 font-weight-bold text-primary">Nom *</h6>
                                                    <input type="text" ref={inputName} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                        <h6 className="m-0 font-weight-bold text-primary">Prénom *
                                        </h6>
                                                    <div className="input-group">
                                                        <input type="text" ref={inputLastN} className="form-control"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                     <br/>
                                     <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Lieu de naissance</h6>
                                                    <input type="text" ref={inputLdn} className="form-control"/>
                                            
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Date de naissance</h6>
                                                    <div className="input-group">
                                                        <input type="date" ref={inputDdn} className="form-control"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-12">
                                                    <h6 className="m-0 font-weight-bold text-primary">Téléphone</h6>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <input maxLength={10} type="number" ref={inputPhone} className="form-control"/>
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text"><i className="fa fa-phone"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <input maxLength={10} type="number" ref={inputPhone2} className="form-control"/>
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text"><i className="fa fa-phone"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Email</h6>
                                                    <div className="input-group">
                                                        <input type="email" ref={inputEmail} className="form-control"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary" >Date du premier cours *</h6>
                                                    <input type="date" ref={date_cours} className="form-control"/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">CIN</h6>
                                                    <input type="number" ref={inputCin} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Nationalit&eacute;</h6>
                                                    <div className="input-group">
                                                        <select ref={inputNat} onChange={(e :any) => verification(e.target.value)} className="form-control">
                                                            <option value="malagasy">Malagasy</option>
                                                            <option value="etranger">Etranger</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                            <div className="col-md-6">
                                                        <h6 className="m-0 font-weight-bold text-primary" >Adresse *</h6>
                                                        <input type="text" ref={inputAdr} className="form-control"/>
                                            </div>
                                            <div className="col-md-6">
                                                        <h6 className="m-0 font-weight-bold text-primary">Profession</h6>
                                                        <div className="input-group">
                                                            <input type="text" ref={inputProf} className="form-control"/>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                            </div>
                                                        </div>
                                            </div>
                                    </div>
                                    {
                                    localStorage.getItem('EGMUserType') === 'super_admin' &&
                                        <>
                                            <br/>
                                            <div className="row">
                                                    <div className="col-md-12">
                                                                <h6 className="m-0 font-weight-bold text-primary" >Centre</h6>
                                                                <select className="form-control"  ref={selected_centre}>
                                                                    {allCentre.map((element:any,index:any) => {
                                                                        return(
                                                                            <option  key={index.toString()} className={element.nom_centre} value={element.nom_centre}>{element.nom_centre}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                    </div>
                                            </div>
                                        </>
                                    }
                            </div>
                    </div>
                </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card shadow mb-4">                 
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h4 className="m-0 font-weight-bold text-primary">Plus</h4>
                                </div>
                                <div className="card-body">
                                <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary text-capitalize">école d'origine</h6>
                                                    <input type="text" ref={inputEo} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Classe</h6>
                                                    <div className="input-group">
                                                        <input type="text" ref={inputClass} className="form-control"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Parents</h6>
                                                    <input type="text" ref={inputParent} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Adresse des parents</h6>
                                                    <div className="input-group">
                                                        <input type="text" ref={inputAdrP} className="form-control"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-12">
                                                    <h6 className="m-0 font-weight-bold text-primary">Téléphone de Parents</h6>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <input type="number" maxLength={10} ref={inputPhoneP} className="form-control"/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input type="number" maxLength={10} ref={inputPhoneP2} className="form-control"/>
                                                        </div>
                                                    </div>  
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-12">
                                                    <h6 className="m-0 font-weight-bold text-primary">Disciplines</h6>
                                                    <select ref={inputDis} className="form-control">
                                                    { allDiscipline.map((element:any,index:any)=><DisciplinesOptions key={index.toString()} el={element}/>) }
                                                    </select>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Cycle</h6>
                                                    <select ref={inputCycle} className="form-control">
                                                        <option value="academique">Academique</option>
                                                        <option value="cours">Cours Accélérer</option>
                                                        <option value="diplomant">Diplomant</option>
                                                    </select>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Niveau</h6>
                                                    <div className="input-group">
                                                        <select ref={inputNiv}className="form-control">
                                                            <option value="10">Initiation</option>
                                                            <option defaultChecked value="1">Cycle 1, Annee 1</option>
                                                            <option value="2">Cycle 1, Annee 2</option>
                                                            <option value="3">Cycle 1, Annee 3</option>
                                                            <option value="4">Cycle 2, Annee 1</option>
                                                            <option value="5">Cycle 2, Annee 2</option>
                                                            <option value="6">Cycle 2, Annee 3</option>
                                                            <option value="7">Cycle 3, Annee 1</option>
                                                            <option value="8">Cycle 3, Annee 2</option>
                                                            <option value="9">Cycle 3, Annee 3</option>
                                                            <option value="11">Autres</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="fa fa-language"></i></span>
                                                        </div>
                                                    </div>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Ecolage Trimestre 1 *</h6>
                                                    <input type="number"  ref={inputTotal} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Ecolage Trimestre 2 *</h6>
                                                    <input type="number"  ref={eco_t2} className="form-control"/>
                                        </div>
                                </div>
                                <div className="row mt-3">
                                        <div className="col-md-6">
                                                    <h6 className="m-0 font-weight-bold text-primary">Ecolage Trimestre 3 *</h6>
                                                    <input type="number"  ref={eco_t3} className="form-control"/>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="m-0 font-weight-bold text-primary">Ecolage Trimestre 4 *</h6>
                                            <input type="number"  ref={eco_t4} className="form-control"/>
                                        </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                                <h6 className="m-0 font-weight-bold text-primary">Autre paiement</h6>
                                                <input type="number"  ref={autre_pay} className="form-control"/>
                                    </div>
                                </div>
                                <br/>
                                <table>   
                                    <tbody>
                                        <tr>
                                            <td className="col-lg-2" align="center"><Link to="/super-admin" className="btn btn-sm btn-success "><i className="fas fa-fw fa-reply"></i> Retour</Link></td>
                                            <td className="col-lg-2" align="center"><button className="btn btn-sm btn-primary" onClick={register}><i className="fas fa-fw fa-save"></i> {decode(eleve_id.id) && localStorage.getItem('EGMUserType') === 'super_admin'  ? "Modifier" : "Enregistrer"}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                                    {
                                        isLoading && <div className="alert text-center mt-3 alert-loading mb-4" role="alert">
                                            Envoi en cours...
                                        </div>
                                    }
                                 </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    );
}
export default Inscription;