import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHeader } from '../helpers/getHeader';
import https from '../helpers/https';
import ReportList from './reportComponents/reportList'

const Reports = () => {
    const [longCours, setlongCours] = useState([] as any);
    const [academicCours, setAcademicCours] = useState([] as any);
    const [diplomantCours, setDiplomantCours] = useState([] as any);
    const [longPaidStd, setLongPaidStd] = useState<number>(0)
    const [academicPaidStd, setAcademicPaidStd] = useState<number>(0)
    const [diplomantPaidStd, setDiplomantPaidStd] = useState<number>(0)
    const [recipeCount, setRecipeCount] = useState<number>(0)
    const longPourcent = React.useRef<any>()
    const longFreq = React.useRef<any>()
    const acdPourcent = React.useRef<any>()
    const acdFreq = React.useRef<any>()
    const diplPourcent = React.useRef<any>()
    const diplFreq = React.useRef<any>()
    const type_rap = React.useRef<any>()
    const [filtreByDate, setFiltreByDate] = useState('2000-01-01')
    const [trimestre_now, setTrimestre_now] = useState("1")

    const [reportList, setReportList] = useState([] as any);

    const fetchCentre = async (dateFilter: string) => {
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (centers) {
            let centre = "";
            let auth_centre = parseInt(localStorage.getItem("EGMUserCentre")!);

            centers.data.forEach((el: any) => {
                if (el.id_centre == auth_centre)
                    centre = el.nom_centre
            });
            if (centre) {
                fetchAcademicCours(centre, dateFilter).catch((e: any) => console.log(e))
                fetchCoursCours(centre, dateFilter).catch((e: any) => console.log(e))
                fetchDiplomantCours(centre, dateFilter).catch((e: any) => console.log(e))
            }
        }
    }

    const fetchAcademicCours = async (centre: string, dateFilter: string) => {
        const report = await https.get(`/rapports/generate_reports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/academique/${centre}/${dateFilter}/${localStorage.getItem('EGMUserCentre')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (report) {
            setlongCours(report.data)
            setRecipeCount(report.data[0].frequentation)
        }
        setCounter('academique', setLongPaidStd, centre)
    }

    const fetchCoursCours = async (centre: string, dateFilter: string) => {
        const report = await https.get(`/rapports/generate_reports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/cours/${centre}/${dateFilter}/${localStorage.getItem('EGMUserCentre')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (report) {
            setAcademicCours(report.data)
        }
        setCounter('cours', setAcademicPaidStd, centre)
    }
    const fetchDiplomantCours = async (centre: string, dateFilter: string) => {
        const report = await https.get(`/rapports/generate_reports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/diplomant/${centre}/${dateFilter}/${localStorage.getItem('EGMUserCentre')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (report) {
            setDiplomantCours(report.data)
        }
        setCounter('diplomant', setDiplomantPaidStd, centre)
    }

    const setCounter = async (cours: string, stateSeter: any, centre: any) => {
        let response = await https.get(`/rapports/all_eleve_report/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${cours}/${centre}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        });
        if (response && response.data) {
            // console.log(response.data)
            let counter = 1;
            response.data.map(async (el: any) => {
                await https.get(`/ecolages/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/eleve/${el.id_el}/${trimestre_now}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                    .then((res) => {
                        let total = 0;
                        res.data.forEach((ecolage: any) => {
                            total += ecolage.somme_paye
                        })
                        switch(trimestre_now) {
                            case '1' : 
                                if ((el.ecolage_total - total) <= 0) {
                                    stateSeter(counter++)
                                }
                                break;
                            case '2' : 
                                if ((el.ecolage_t2 - total) <= 0) {
                                    stateSeter(counter++)
                                }
                                break;
                            case '3' : 
                                if ((el.ecolage_t3 - total) <= 0) {
                                    stateSeter(counter++)
                                }
                                break;
                            case '4' : 
                                if ((el.ecolage_t4 - total) <= 0) {
                                    stateSeter(counter++)
                                }
                                break;
                        }
                    })
            })
        }
    }

    const fetchRapport = async () => {
        const report = await https.get(`/rapports/centres_limit/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${parseInt(localStorage.getItem("EGMUserCentre")!)}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if (report) {
            setReportList(report.data)
        }
    }

    useEffect(() => {
        fetchCentre(filtreByDate).catch((e: any) => console.log(e))
        fetchRapport().catch((e: any) => console.log(e))
    }, [])

    useEffect(() => {
        fetchCentre(filtreByDate).catch((e: any) => console.log(e))
    }, [filtreByDate])

    useEffect(() => {
        fetchCentre(filtreByDate).catch((e: any) => console.log(e))
    }, [trimestre_now])

    const handleRegister = async (e: any, id: string) => {
        e.preventDefault()
        let dataAcd = {
            "type_cours": "academique",
            "type_rapport": type_rap.current.value,
            "nouv_inscrit": longCours[0].newInscription[0]['COUNT(*)'],
            "total_inscrit": longCours[0].allStudents[0]['COUNT(*)'],
            "eleve_actif": longCours[0].allActifStudents[0]['COUNT(*)'],
            "eleve_en_regle": longPaidStd,
            "pourcentage_recouv": longPourcent.current.value ? parseInt(longPourcent.current.value) : 0,
            "frequentation": recipeCount,
            "trimestre": trimestre_now,
            "quitter": longCours[0].allInactifStudents[0]['COUNT(*)'],
            "id_centre_centres": parseInt(localStorage.getItem("EGMUserCentre")!),
        }
        let dataCours = {
            "type_cours": "cours",
            "type_rapport": type_rap.current.value,
            "nouv_inscrit": academicCours[0].newInscription[0]['COUNT(*)'],
            "total_inscrit": academicCours[0].allStudents[0]['COUNT(*)'],
            "eleve_actif": academicCours[0].allActifStudents[0]['COUNT(*)'],
            "eleve_en_regle": academicPaidStd,
            "pourcentage_recouv": acdPourcent.current.value ? parseInt(acdPourcent.current.value) : 0,
            "frequentation": recipeCount,
            "trimestre": trimestre_now,
            "quitter": academicCours[0].allInactifStudents[0]['COUNT(*)'],
            "id_centre_centres": parseInt(localStorage.getItem("EGMUserCentre")!),
        }
        let dataDpl = {
            "type_cours": "diplomant",
            "type_rapport": type_rap.current.value,
            "nouv_inscrit": diplomantCours[0].newInscription[0]['COUNT(*)'],
            "total_inscrit": diplomantCours[0].allStudents[0]['COUNT(*)'],
            "eleve_actif": diplomantCours[0].allActifStudents[0]['COUNT(*)'],
            "eleve_en_regle": diplomantPaidStd,
            "pourcentage_recouv": diplPourcent.current.value ? parseInt(diplPourcent.current.value) : 0,
            "frequentation": recipeCount,
            "trimestre": trimestre_now,
            "quitter": diplomantCours[0].allInactifStudents[0]['COUNT(*)'],
            "id_centre_centres": parseInt(localStorage.getItem("EGMUserCentre")!),
        }
        let responseLong = await https.post(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, dataAcd,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => { console.log(e) });
        let responseAcd = await https.post(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, dataCours,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => { console.log(e) });
        let responseDipl = await https.post(`/rapports/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, dataDpl,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        }).catch(e => { console.log(e) });
        if ((responseLong) && (responseAcd) && (responseDipl)) {
            fermer(id)
            fetchRapport().catch((e: any) => console.log(e))
        }
    }

    const open = (e: any, ID: string) => {
        e.preventDefault()
        let modal = document.querySelector('#' + ID) as Element;
        modal ?.classList.add("open");
    }

    const fermer = (id: string) => {
        let modal = document.querySelector('#' + id) as Element;
        modal ?.classList.remove("open")
    }


    return (
        <div className='container-fluid bodybar mt-0 pt-4' style={{
            height: window.innerHeight - 70
        }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div className="d-flex">
                    <div className="row">
                        <div className="col-lg-6 pt-2">
                            <h5 className="mb-0 text-gray-900">Type de rapport:</h5>
                        </div>
                        <div className="col-lg-6">
                        <select className="form-control" ref={type_rap}>
                            <option value="Hebdomadaire">Hebdomadaire</option>
                            <option value="Mensuel">Mensuel</option>
                        </select>
                        </div>
                    </div>
                </div>
                <button className="btn btn-sm btn-primary " onClick={e => open(e, "logoutModal")}><i className="fas fa-fw fa-save"></i> Envoyer</button>
            </div>
            {/* Beginn */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-md-6 mt-2 d-flex">
                                    <p className='mt-2'> Date depart </p>
                                    <input type="date" style={{width:"50%"}} onChange={(e: any) => setFiltreByDate(e.target.value)} className="form-control ml-2" />
                                </div>
                                {/* <div className="col-md-6 mt-2 d-flex">
                                    <p className='mt-2'>Trimestre</p>
                                    <select style={{width:"50%"}} className="form-control ml-2" onChange={(e) => setTrimestre_now(e.target.value)}>
                                        <option value="1">Trimestre 1</option>
                                        <option value="2">Trimestre 2</option>
                                        <option value="3">Trimestre 3</option>
                                        <option value="4">Trimestre 4</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        <div className="widget-box">
                            <div className="widget-content nopadding">
                                <table className="table table-bordered table-responsive" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Cours</th>
                                            <th>Nouvelles Inscriptions</th>
                                            <th>Total des inscrits</th>
                                            <th>Nombre d'élèves quittés</th>
                                            <th>Nombre d'élèves actifs</th>
                                            <th>Elèves en règle sur les frais</th>
                                            <th>Elèves ayant des restes de frais</th>
                                            <th>Pourcentage de recouvrement</th>
                                            <th>Fr&eacute;quentation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            longCours.map((el: any, idx: number) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>Académique</td>
                                                        <td>{el.newInscription[0]['COUNT(*)']}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allInactifStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allActifStudents[0]['COUNT(*)']}</td>
                                                        <td>{longPaidStd}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)'] - longPaidStd!}</td>
                                                        <td>
                                                            <input type="number" ref={longPourcent} className="form-control" />
                                                        </td>
                                                        <td rowSpan={3}>
                                                            {recipeCount}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            academicCours.map((el: any, idx: number) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>Cours</td>
                                                        <td>{el.newInscription[0]['COUNT(*)']}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allInactifStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allActifStudents[0]['COUNT(*)']}</td>
                                                        <td>{academicPaidStd}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)'] - academicPaidStd}</td>
                                                        <td>
                                                            <input type="number" ref={acdPourcent} className="form-control" />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            diplomantCours.map((el: any, idx: number) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>Diplomants</td>
                                                        <td>{el.newInscription[0]['COUNT(*)']}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allInactifStudents[0]['COUNT(*)']}</td>
                                                        <td>{el.allActifStudents[0]['COUNT(*)']}</td>
                                                        <td>{diplomantPaidStd}</td>
                                                        <td>{el.allStudents[0]['COUNT(*)'] - diplomantPaidStd}</td>
                                                        <td>
                                                            <input type="number" ref={diplPourcent} className="form-control" />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End */}

            <ReportList reportData={reportList} />

            {/* modals */}
            <div className="modal" id="logoutModal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{
                        width: '100%',
                        marginTop: '150px',
                    }} >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Envoyer </h5>

                        </div>
                        <div className="modal-body" style={{
                            padding: '20px'
                        }}>Voulez vous envoyer le rapport ?</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')} data-dismiss="modal">Anuller</button>
                            <a className="btn btn-success btn-sm" href="" onClick={(e: any) => handleRegister(e, 'logoutModal')}>Envoyer</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Reports;