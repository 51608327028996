import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHeader } from '../helpers/getHeader';
import https from '../helpers/https';
import { encode } from '../services/slugs';
import ChooseCoursType from '../superAdmin/studentsComponents/chooseCoursType';

const Students = () => {
    const [eleves,setEleves] = React.useState([] as any)
    const [allCentre, setAllCentre] = useState([] as any);
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [limit, setLimit] = useState(10)
    const [tmpTab, setTmpTab] = useState([] as any);
    const [search, setSearch] = useState<any>("");
    const [isQ, setIsQ] = useState<boolean>(false);
    const [authCentre, setauthCentre] = useState("")
    const [eleve,setEleve] = React.useState({} as any)
    const [isLoading, setIsLoading] = useState(false)
    const [coursFilter, setCoursFilter] = useState("tout")
    const motif_quitter = useRef<any>()
    const nav = useNavigate()

    const split = (s: string) =>{
        if(s.length > 8){
            return s.slice(0,8) + "..."
        }else{
            return s
        }
    }
    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setAllCentre(centers.data);
            fetchData(centers.data)
                .catch((e)=>{ console.log(e)})
        }
    }

    const fetchData = async(centres:any) =>{
        setIsLoading(true)
        let centre = "";
        let auth_centre = parseInt(localStorage.getItem("EGMUserCentre")!);
        if(centres.length){
            for await (const el of centres) {
                if(el.id_centre == auth_centre){
                    centre = el.nom_centre
                    setauthCentre(el.nom_centre)
                }
            }
        }
        if(centre){
            let response = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/${encodeURI(centre)}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => {console.log(e)});
            if(response && response.data){
                setIsLoading(false)
                if(coursFilter == "tout") setEleves(response.data)
                else {
                    let newData = response.data.filter((eleve:any) => {
                        return parseInt(eleve.status) && eleve.type_cycle == coursFilter && eleve
                    })
                    setEleves(newData)
                }
            }
            
        }
    }
    const fetchSearch = async () => {
        setIsLoading(true)
        if(search != ""){
            try{
                let queryData = await https.get(`/eleves/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/searchAdmin/${encodeURI(search)}/${encodeURI(authCentre)}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(queryData){
                    setIsLoading(false)
                    setEleves(queryData.data)
                }
            }
            catch(e){
                console.log(e)
            }
        }
        else {
            fetchCentre()
            .catch(error => console.log(error));
        }
    }

    useEffect(()=>{
        if(isQ){
            fetchSearch()
        }
    },[search])

    useEffect(()=>{
        fetchCentre()
            .catch(error => console.log(error));            
    },[coursFilter])

    const open = (id:string,eleve:any) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.add("open")
        setEleve(eleve)
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    const prev  = () =>{
        if(index > 2){
            setIndex(index - 1)
            setIndex2((index - 1)*10)
            setLimit(index * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }
    const next = () =>{
        if( index <= Math.round(eleves.length/10)){
            setIndex(index + 1)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }


    const current = (index: any,value: any) =>{
        if(parseInt(String(eleves.length/10).split('.')[1][0]) < 5){
            setIndex(index)
            setIndex2((index-value) * 10)
            setLimit((index) * 10)
        }else{
            setIndex(index)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }
    }
    const dynamiqueTab = (index2:any,limit:any,tab:any) =>{
        let tmp = []
        for (let i = index2; i <= limit; i++) {
            if(i >= 0 && i < tab.length){
                const element = tab[i];
                tmp.push(element)
            }
        }
        return tmp
    }

    

    const disabled = (status: boolean) =>{
        if(status){
            if(index >= Math.round(eleves.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }

    useEffect(()=>{
        if(limit){
            let result = dynamiqueTab(index2,limit,eleves)
            setTmpTab([...result])
        }
    },[limit,index,index2,eleves])

    const registerQuitter = (e: any, id: string) => {
        e.preventDefault();
    
        try {
          https
            .post(`/eleves/quitter/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`, {
              id_el: eleve.id_el,
              motifs: motif_quitter.current.value,
            },{
              headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            })
            .then((res) => {
              if (res.status === 200) {
                if (localStorage.getItem("EGMUserType") === "super_admin") {
                  nav(`/super-admin/liste-des-eleves-quitter`);
                } else if (localStorage.getItem("EGMUserType") === "admin") {
                  nav(`/admin/liste-des-eleves-quitter`);
                }
                fermer(id);
              }
            });
        } catch (e) {
          console.log(e);
        }
      };

    return (
        <div  className='bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
        <div className="container-fluid">
            <ChooseCoursType setCoursFilter={setCoursFilter} />
             {/* Begin */}
             <div className="row">
                    <div className="col-lg-12 mb-5">
                        <div className="card shadow mt-1">
                            <div className="card-header d-flex justify-content-between py-3">
                                <div className="row">
                                    <div className="col-lg-6 pt-2">
                                        <h6 className="font-weight-bold text-primary">Listes des &eacute;l&egrave;ves</h6>
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" placeholder="Rechercher..."  onChange={(e:any)=> {setSearch(e.target.value); setIsQ(true)}} className="form-control"/>
                                    </div>
                                </div>
                                <div className='pt-1'>
                                    <Link to={"/admin/inscription"} className="btn btn-sm btn-primary "><i className="fas fa-fw fa-plus"></i></Link>
                                </div>
                            </div>
                            <div className="widget-box">
                                <div className="widget-content nopadding">
                                    <table className="table table-bordered table-responsive-xl table-striped" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Numéro</th>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Nationalité</th>
                                                <th>Matricule</th>
                                                {/* <th>Centres</th> */}
                                                <th>Téléphone</th>
                                                <th>Cours</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Chargement...
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                !isLoading && tmpTab.length == 0 && <tr>
                                                    <td className='text-center' colSpan={9}>
                                                        Aucun résultat
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                tmpTab.filter((value:any,i:any) => {
                                                    return parseInt(value.status) && value
                                                }).map((el:any)=>(
                                                    <tr key={el.id_el} className='pb-0'>
                                                        <td>{el.numero}</td>
                                                        <td>{split(el.nom)}</td>
                                                        <td>{split(el.prenom)}</td>
                                                        <td style={{ textTransform:'capitalize'}}>{el.nationalite.slice(0,8)}</td>
                                                        <td>{el.matricule}</td>
                                                        {/* <td>{el.nom_centre}</td> */}
                                                        <td>{el.telephone}</td>
                                                        <td>{el.type_cycle}</td>
                                                        <td className='mt-0 pt-0 pb-0 d-flex'>
                                                            <Link to={"/admin/a-propos/"+encode(el.id_el)} className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                                                            <button onClick={() =>open('quitterModal',el)} className="btn btn-sm btn-danger m-2"><i className="fas fa-fw fa-arrow-right"></i></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {eleves.length >= 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index,0)}>{index}</span></li>
                                {index + 1 <= Math.round(eleves.length/10) + 1 && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1,1)}>{index + 1}</span></li>}
                                {Math.round(eleves.length/10) > 2 && index < Math.round(eleves.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(eleves.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                            </ul>}
                        </div>
                    </div>
            </div>
            {/* end */}
            {/* Modals */}
            {/* Modals */}
            <div id="quitterModal" className="modal">
                <form
                    className="modal-content animate"
                    action=""
                    method="post"
                    style={{
                    marginTop: "70px",
                    }}
                >
                    <div className="modal-header">
                    <h5 className="modal-title">
                        <strong>Cet élève a quitté le centre ?</strong>
                    </h5>
                    </div>
                    <div className="imgcontainer">
                    <span
                        onClick={() => fermer("quitterModal")}
                        className="close"
                        title="Close Modal"
                    >
                        &times;
                    </span>
                    </div>
                    <div className="modal-body">
                    <div className="container">
                        <label>Motifs</label>
                        <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-lg-12">
                            <textarea
                            rows={4}
                            style={{ marginTop: "5px" }}
                            ref={motif_quitter}
                            className="form-control"
                            name="motifs"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <button
                        onClick={(e) => registerQuitter(e, "quitterModal")}
                        className="btn btn-sm btn-danger"
                        type="submit"
                    >
                        <i className="fas  fa-save"></i> Valider
                    </button>
                    </div>
                </form>
            </div>
            {/* End */}
        </div>
        </div>
    );
}
export default Students;