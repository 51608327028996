import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import https from '../helpers/https';
import { encode } from '../services/slugs';
import Profil from './Profil';
import ChooseAdminCenter from './studentsComponents/chooseAdminCenter';
import { getHeader } from '../helpers/getHeader';
import { generateDate } from '../services/date/date';

interface props {
    el : any,
    center: any,
    handleOpen(e : any) : void
    auth_type : string | null
}
const TableData = ({el, center, handleOpen, auth_type} : props) => {
    let center_name;
    const nav = useNavigate();
    center.forEach((element : any) => {
        if (element.id_centre == el.id_centre_centres)
            center_name = element.nom_centre;
    });

    const split = (text:string | number) =>{
        if(text){
            let t  = String(text)
            if(t.length > 9){
                return t.slice(0,9) + '...'
            }else{
                return t
            }
        }
    }
  
    return(
        <tr style={{ textTransform:'capitalize'}}>
            <td>{el.nom_resp && el.nom_resp!=='null' ?  split(el.nom_resp) :  "Aucun"}</td>
            <td>{el.prenom_resp && el.prenom_resp!=='null' ?  split(el.prenom_resp) :  "Aucun"}</td>
            <td>{el.telephone_resp && el.telephone_resp!=='null' ?  el.telephone_resp :  "Aucun"}</td>
            <td>{el.adresse_resp && el.adresse_resp!=='null' ?  split(el.adresse_resp) :  "Aucun"}</td>
            <td>{el.email_resp && el.email_resp!=='null' ?  split(el.email_resp) :  "Aucun"}</td>
            <td>{center_name && center_name !=='null' ?  split(center_name) :  "Aucun"}</td>
            <td>{el.resp_createdAt!=='null' ?  generateDate(el.resp_createdAt) :  "Aucun"}</td>
            
            { auth_type == "super_admin" ?
                <td className='pt-0 pb-0 d-flex'>
                    <Link to={'/super-admin/voir-prof/'+ encode(el.id_resp) } className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                    <Link to={'/super-admin/modify-prof/'+ encode(el.id_resp) } className="btn btn-sm btn-success m-2"><i className="fas fa-fw fa-pencil-alt"></i></Link>
                    <button onClick={e => handleOpen(e)} className="btn btn-sm m-2 btn-danger"><i className="fas fa-fw fa-trash-alt"></i></button>
                </td>
            :    
                <td className='pt-0 pb-0 d-flex'>
                    <Link to={'/admin/voir-prof/'+ encode(el.id_resp) } className="btn btn-sm btn-warning m-2"><i className="fas fa-fw fa-user-alt"></i></Link>
                </td>
            }
        </tr>
    );
}

const Professors = () => {
    const [profs, setProfs] = useState([] as any);
    const [allCentre, setallCentre] = useState([] as any);
    const [centerFilter, setCenterFilter] = useState('*');
    const [id_resp,setId_resp] = React.useState(0)
    const [auth_type, setAuthType] = useState(localStorage.getItem('EGMUserType'));
    const [tmpTab, setTmpTab] = useState([] as any);
    const [index, setIndex] = useState(1)
    const [index2, setIndex2] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState<any>("");
    const [isQ, setIsQ] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)

    const fetchCentre = async ()=>{
        const centers = await https.get(`/centres/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}`,{
            headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
        })
        if(centers){
            setallCentre(centers.data);
        }
    }

    async function deleteProf(e: any,ID: string,id : number)   {
        e.preventDefault()
        if(id){
            let resp = await https.delete(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${id}`,{
                headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
            }).catch(e => {return e});
            if(resp.status == 200){
                fetchProf('*')
                    .then(() =>{
                        fermer(ID)
                    })
                    .catch((e) => console.log(e))
            }
        }
    }

    const fetchProf = async (filter: String)=>{
        setIsLoading(true)
        if(localStorage.getItem('EGMUserType') == "super_admin"){
            if(filter == '*'){
                const professor = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/professeur`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(professor){
                    setIsLoading(false)
                    setProfs(professor.data);
                }
            }
            else{
                const professor = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/professeur/${encodeURI(String(filter))}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(professor){
                    setIsLoading(false)
                    setProfs(professor.data);
                }

            }
        }
        else{
            try{
                const professor = await https.get(`/responsables/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/get_by_centre/professeur/${encodeURI(localStorage.getItem('EGMUserCentre')!)}`,{
                    headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                })
                if(professor){
                    setIsLoading(false)
                    setProfs(professor.data);
                }
            }
            catch(e) { console.log(e) }
        }
    }

    const searchProf = async () => {
        setIsLoading(true)
        if(localStorage.getItem('EGMUserType') == "super_admin"){
            if(search != ""){
                try{
                    let queryData = await https.get(`/responsables/search/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${encodeURI(search)}/all`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(queryData){
                        setIsLoading(false)
                        setProfs(queryData.data);
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
            else {
                fetchProf(centerFilter)
                .catch(error => console.log(error));
            }
        }
        else {
            if(search != ""){
                try{
                    let queryData = await https.get(`/responsables/search/${localStorage.getItem('EGMUserId')}/${localStorage.getItem('EGMUserType')}/${encodeURI(search)}/${encodeURI(localStorage.getItem('EGMUserCentre')!)}`,{
                        headers: getHeader(localStorage.getItem("EGMUserToken"), 'json'),
                    })
                    if(queryData){
                        setIsLoading(false)
                        setProfs(queryData.data);
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
            else {
                fetchProf(centerFilter)
                .catch(error => console.log(error));
            }
        }
    }

    useEffect(()=>{
        if(isQ){
            searchProf()
        }
    },[search])

    useEffect(() => {
        fetchProf(centerFilter)
            .catch((e : any)=> console.log(e));
    },[centerFilter])

    useEffect(()=>{
        fetchProf('*')
            .catch(error => console.log(error));
        fetchCentre()
            .catch(error => console.log(error));
    },[])

    const open = (e:any,ID:string,id:number) => {
        e.preventDefault()
        let modal = document.querySelector('#'+ID)as Element;
        modal?.classList.add("open")
        setId_resp(id)
    }

    const fermer = (id:string) => {
        let modal = document.querySelector('#'+id)as Element;
        modal?.classList.remove("open")
    }

    const prev  = () =>{
        if(index > 2){
            setIndex(index - 1)
            setIndex2((index - 1)*10)
            setLimit(index * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }
    const next = () =>{
        if( index <= Math.round(profs.length/10)){
            setIndex(index + 1)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }else{
            setIndex(1)
            setIndex2(0)
            setLimit(10)
        }
    }


    const current = (index: any,value: any) =>{
        if(parseInt(String(profs.length/10).split('.')[1][0]) < 5){
            setIndex(index)
            setIndex2((index-value) * 10)
            setLimit((index) * 10)
        }else{
            setIndex(index)
            setIndex2(index * 10)
            setLimit((index + 1) * 10)
        }
    }

    const disabled = (status: boolean) =>{
        if(status){
            if(index > Math.round(profs.length/10))
                return " disabled"
        }else{
            if(index <= 1)
                return " disabled"
        }
        return ""
    }
    
    
    const active = (indexnew: any,i:any) =>{
        const active = (index-i) === indexnew ? " active" : ""
        return active
    }

    const dynamiqueTab = (index2:any,limit:any,tab:any) =>{
        let tmp = []
        for (let i = index2; i < limit; i++) {
            if(i >= 0 && i < tab.length){
                const element = tab[i];
                tmp.push(element)
            }
        }
        return tmp
    }

    useEffect(()=>{
        if(limit){
            let result = dynamiqueTab(index2,limit,profs)
            setTmpTab([...result])
        }
    },[limit,index,index2,profs])
    return (
        <div className='bodybar mt-0 pt-4' style={{
            height:window.innerHeight-70
        }}>
        <div className="container-fluid">
            {auth_type == "super_admin" ? <ChooseAdminCenter setFilter = { setCenterFilter }/> : ""}
            {/* Begin */}
            <div className="row">
                <div className="col-lg-12 mb-5"> 
                    <div className="card shadow mt-0">
                        <div className="card-header d-flex justify-content-between py-3">
                            <div className="row">
                                <div className="col-lg-6 pt-2">
                                    <h6 className="font-weight-bold text-primary">Listes des Prof&eacute;sseurs</h6>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" placeholder="Rechercher..."  onChange={(e:any)=> {setSearch(e.target.value); setIsQ(true)}} className="form-control"/>
                                </div>
                            </div>
                            <div className='pt-1'>
                                <Link to={auth_type == "super_admin" ? "/super-admin/ajout-prof" : "/admin/ajout-prof"} className="btn btn-sm btn-primary "><i className="fas fa-fw fa-plus"></i></Link>
                            </div>
                        </div>
                        <div className="widget-box">
                            <div className="widget-content nopadding">
                                <table className="table table-bordered table-responsive-xl table-striped" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Prénom</th>
                                            <th>Téléphone</th>
                                            <th>Adresse</th>
                                            <th>E-mail</th>
                                            <th>Centre</th>
                                            <th>Date d'entré</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td className='text-center' colSpan={9}>
                                                    Chargement...
                                                </td>
                                            </tr>
                                        }
                                        {
                                            !isLoading && tmpTab.length == 0 && <tr>
                                                <td className='text-center' colSpan={9}>
                                                    Aucun résultat
                                                </td>
                                            </tr>
                                        }
                                        { tmpTab.map((element:any,index:any)=><TableData key={index.toString()} el={element} center={allCentre} auth_type={auth_type} handleOpen={(e: any) => open(e,"logoutModal",element.id_resp)} />)}
                                    </tbody>
                                    
                                </table>
                            </div>
                            <div className="modal" id="logoutModal">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content" style={{
                                                width: '100%',
                                                marginTop:'150px',
                                            }} >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Attention!</h5>
                                                    
                                                </div>
                                                <div className="modal-body" style={{
                                                    padding:'20px'
                                                }}>Voulez vous vraiment supprimer ce Professeur ?</div>
                                                <div className="modal-footer">
                                                    <button className="btn btn-secondary btn-sm" type="button" onClick={() => fermer('logoutModal')}   data-dismiss="modal">Anuller</button>
                                                    <a className="btn btn-danger btn-sm" href="" onClick={(e) => deleteProf(e,"logoutModal",id_resp)}>Supprimer</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {profs.length >= 10 && <ul className="pagination pagination-md d-flex justify-content-center">
                                        <li className={"page-item"+disabled(false)} style={{ cursor: index <= 1 ?'no-drop' :'pointer'}}><span  className="page-link" onClick={prev} >Précedent</span></li>
                                        { index > 1 && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+active(index,0)} style={{ cursor:'pointer'}}><span className="page-link" onClick={()=>current(index,0)}>{index}</span></li>
                                        {index + 1 <= Math.round(profs.length/10) + 1  && <li className={"page-item"+active(index,1)} style={{ cursor:'pointer'}} ><span className="page-link" onClick={()=>current(index+1,1)}>{index + 1}</span></li>}
                                        {Math.round(profs.length/10) > 2 && index < Math.round(profs.length/10) && <li className="page-item" style={{ cursor:'no-drop'}}><span className="page-link" >...</span></li>}
                                        <li className={"page-item"+disabled(true)} style={{ cursor: index >= Math.round(profs.length/10) ? 'no-drop' :'pointer'}}><span className="page-link"  onClick={next}>Suivant</span></li>
                                    </ul>}
                                    <div className="modal" id="profil">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content p-0" style={{width:'100vh',background:'transparent'}}>
                                            <Profil id={id_resp} handleClose={() =>fermer('profil')}/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                </div>
        </div>
        {/* end */}
    </div>
    </div>
    );
}
export default Professors;